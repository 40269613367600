import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { PermissionsContext } from "../../context/PermissionsContext";
import "./EntityTable.css";
// Importing icons
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import editIcon from "../../assets/edit-icon.png"; // Assuming you've saved the edit icon as "edit-icon.png"
import { MessageContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { Col, Form, OverlayTrigger, Popover, Row, Modal, Stack, Tooltip } from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import the skeleton styles

const EntityTable = ({ stateChanger, rowId }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const searchInputRef = useRef(null); // Create a ref for the search bar
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [selectedColumns, setSelectedColumns] = useState({
    id: false,
    namespace: true,
    entityName: true,
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNumber: true,
    country: false,
    state: false,
    district: false,
    pincode: false,
    expiryDate: false, 
    deviceCount: false,
    deviceUsed: false,
    deviceRemaining: false,
    gstin: false,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [loading, setLoading] = useState(true); // Add this line
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [update, setUpdate] = useState(false);
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });

   // 1. Access the Permissions context
   const { permissions } = useContext(PermissionsContext);

   // 2. Define a utility function to check permission
   const hasPermission = (moduleName, widgetName) => {
     if (!permissions || !permissions.modules) return false;
     const moduleArray = permissions.modules[moduleName];
     if (!moduleArray) return false;
     const widgetObj = moduleArray.find((w) => w.widget_name === widgetName);
     return widgetObj && widgetObj.permission === "yes";
   };

  const iconTooltips = {
    updownIcon: "Sort columns",
    filterIcon: "Filter results",
    settingIcon: "Settings",
    searchIcon: "Search",
    editIcon: "Edit this record",
  };

  const entitySearchRef = useRef(null);
  useEffect(() => {
    if (entitySearchRef.current && selectedFilter !== "") {
      entitySearchRef.current.focus();
    }
  }, [selectedFilter])

  const [disableBox, setDisablekBox] = useState(false);
  const onMessageChange = useContext(MessageContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedEntity, setSelectedEntity] = useState(null); // Selected entity for confirmation
  const [enable, setEnable] = useState(false);

  const navigate = useNavigate();

  // Retrieve userData from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userEmail = userData?.email || "";
  const token = userData?.token || "";
  // console.log("User email from session storage:", userEmail); // Log to check email from session
  // console.log("Session Storage:", userData)
  // Fetch data from the API
  const [data, setData] = useState([]);

  useEffect(() => {
    // Retrieve userData and authToken from session storage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const entityId = userData?.entityId || "";
    const authToken = sessionStorage.getItem("authToken");
  
    if (!token) {
      console.error("Auth token is missing from session storage.");
      return; // Exit if no auth token is available
    }
  
    // Set API URL based on disableBox flag
    let api = `https://solar-api.antsai.in/api/entity/fetchAllRecords?entityid=${entityId}`;
    if (disableBox) {
      api = `https://solar-api.antsai.in/api/entity/fetchDeletedEntities?entityid=${entityId}`;
    }

   // Start loading
   setLoading(true);
  
  // Fetch data with the token in the header
  fetch(api, {
    method: "GET",
    headers: { Authorization: `Bearer ${authToken}` },
  })

    .then((response) => {
      // console.log("Response Status:", response.status);
      // console.log("Response Headers:", response.headers);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      // Process and format the data
        if (Array.isArray(data)) {
          const formattedData = data.map((item) => ({
            id: item.id,
            entityName: item["Entity Name"],
            deviceCount: item["Device Count"],
            expiryDate: item["Expiry Date"]
              ? new Date(item["Expiry Date"]).toLocaleDateString("en-GB") // Format as dd-mm-yyyy
              : "", // Handle null or undefined dates
            firstName: item["First Name"],
            lastName: item["Last Name"],
            emailId: item["Email Id"],
            mobileNumber: item["Mobile Number"],
            namespace: item["Namespace"],
            country: item["Country"],
            state: item["State"],
            district: item["District"],
            pincode: item["Pincode"],
            gstin: item["GSTIN"],
          }));
          // console.log("Formatted Data for Table:", formattedData);
          setData(formattedData); // Update the state with formatted data
        } else {
          console.error("Unexpected data format:", data);
          setData([]); // Handle unexpected data format
        }
        setLoading(false); // Data fetched successfully
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]); // Set to empty array on error
        setLoading(false); // Stop loading on error
      });
  }, [disableBox]);
  
  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true;
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase());
    });
  }, [sortedData, selectedFilter, searchQuery]);

  const navigateToEditPage = (e, rowData) => {
    e.stopPropagation();
    navigate("/admin/entity/edit", { state: { entityId: rowData.id } });
  };

  const tablePropsChange = (updatedProps) => {
    setTableProps(updatedProps);
  };

  const onRowChoose = (e, row) => {
    stateChanger(3);
    rowId(row);
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes by doubling them
        return `"${escaped}"`; // Wrap each value in quotes
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "entities.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Pagination logic
  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(
        (tableProps.currentPage - 1) * tableProps.rowsPerPage,
        (tableProps.currentPage - 1) * tableProps.rowsPerPage +
        tableProps.rowsPerPage
      );
    }
    return [];
  }, [filteredData, tableProps]);

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const disableRow = (e, entity) => {
    e.stopPropagation();
    setEnable(false)
    setSelectedEntity(entity); // Store selected entity for confirmation
    setShowConfirmModal(true); // Show confirmation modal
  };

  const enableRow = (e, entity) => {
    e.stopPropagation();
    setEnable(true);
    setSelectedEntity(entity);
    setShowConfirmModal(true);

  }

  const confirmDisable = async () => {
    try {
       // Create the request body
    const requestBody = { entityid: selectedEntity.id };

    // Log the request body to verify its contents
    console.log("Request Body:", requestBody);
      const response = await fetch("https://solar-api.antsai.in/api/entity/disable",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json" // ensure the server parses JSON
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        onMessageChange({ type: "Success", info: "Entity disabled successfully." });
        setShowConfirmModal(false); // Close the modal on success
        // Add a delay before reloading to allow the success message to show
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        console.error("Failed to disable entity:", errorData);
        onMessageChange({ type: 'Danger', info: `Failed to disable entity: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false); // Close the modal in case of error
      }
    } catch (error) {
      console.error("Error disabling entity:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while disabling the entity. Please try again later." });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  const confirmEnable = async () => {
    try {
          // Create the request body
    const requestBody = { entityid: selectedEntity.id };

    // Log the request body to verify its contents
    console.log("Request Body:", requestBody);
      const response = await fetch("https://solar-api.antsai.in/api/entity/restoreEntity",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json" // ensure the server parses JSON
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        onMessageChange({ type: "Success", info: `Entity ${selectedEntity.id} restored successfully.` });
        setShowConfirmModal(false); // Close the modal on success
        // Add a delay before reloading to allow the success message to show
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        console.error("Failed to enable entity:", errorData);
        onMessageChange({ type: 'Danger', info: `Failed to enable entity: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false); // Close the modal in case of error
      }
      setEnable(false);
    } catch (error) {
      setEnable(false)
      console.error("Error enabling entity:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while enabling the entity. Please try again later." });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  // Handle filter selection
  const handleFilterSelect = (column) => {
    setSelectedFilter(column); // Set the selected filter
    setIsDropdownOpen(false); // Close the dropdown
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus(); // Focus on the search bar
      }
    }, 0); // Ensure UI updates before focusing
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">
          <Col></Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >
            <span className="downloadLink" onClick={downloadCSV}>
              Download
            </span>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              show={isDropdownOpen} // Control dropdown visibility
              onToggle={(isOpen) => setIsDropdownOpen(isOpen)} // Sync state with dropdown
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li
                            key={column}
                            onClick={() => handleFilterSelect(column)} // Call filter select handler
                          >
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-filter">{iconTooltips.filterIcon}</Tooltip>}
                >
                  <img src={filterIcon}
                    alt="filter"
                    className="filter-icon"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  />
                </OverlayTrigger>
              </div>
            </OverlayTrigger>

            <div style={{ position: 'relative' }} className=" w-auto">
              <input
                //  ref={searchInputRef}
                ref={entitySearchRef}
                id="entity_search"
                type="text"
                style={{
                  width: '341px',
                  height: '39px',
                  background: '#BBCCD9 0% 0% no-repeat padding-box',
                  borderRadius: '5px',
                  opacity: ' 0.51'
                }}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
          </Col>
        </Row>
        <div className="border rounded border-light-subtle">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column === "id"
                          ? "Entity ID"
                          : column.replace(/([A-Z])/g, " $1").replace(/^\w/, (c) => c.toUpperCase())}
                        <img
                          src={updownIcon}
                          alt="sort"
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ))}
                  <th>
                    <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="auto-start"
                        overlay={
                          <Popover>
                            <Popover.Body className="overlay-body">
                              <div>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns.id}
                                    onChange={() => handleColumnSelect("id")} // Add onChange handler
                                    // disabled
                                  />{" "}
                                  Entity ID
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns.entityName}
                                    disabled
                                  />{" "}
                                  Entity Name
                                </label>
                                {Object.keys(selectedColumns).map(
                                  (column) =>
                                    column !== "id" &&
                                    column !== "entityName" && (
                                      <label key={column}>
                                        <input
                                          type="checkbox"
                                          checked={selectedColumns[column]}
                                          onChange={() =>
                                            handleColumnSelect(column)
                                          }
                                        />{" "}
                                        {column.charAt(0).toUpperCase() +
                                          column.slice(1)}
                                      </label>
                                    )
                                )}
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={disableBox}
                                    onChange={() => setDisablekBox(!disableBox)}
                                  />{" "}
                                  Disabled
                                </label>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-settings">{iconTooltips.settingIcon}</Tooltip>}
                          >
                            <img
                              src={settingIcon}
                              alt="settings"
                              className="setting-icon"
                            />
                          </OverlayTrigger>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
    // Render skeleton rows
    Array.from({ length: tableProps.rowsPerPage }).map((_, index) => (
      <tr key={index}>
        {Object.keys(selectedColumns)
          .filter((key) => selectedColumns[key])
          .map((column) => (
            <td key={`${index}-${column}`}>
              <Skeleton height={20} />
            </td>
          ))}
        <td>
          <Skeleton height={20} width={50} />
        </td>
      </tr>
    ))
  ) :
                currentRows.length > 0 ? (
                  currentRows.map((row) => (
                    <tr key={row.id} className={row.emailId === userEmail ? "highlight-row" : ""}>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <td key={`${row.id}-${column}`}>
                            {row[column]}
                          </td>
                        ))}
                      <td className="delete-column">
                        <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                          {/* <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-edit">{iconTooltips.editIcon}</Tooltip>}
                          >
                            <img
                              src={editIcon}
                              alt="edit"
                              className="edit-icon"
                              onClick={(e) => onRowChoose(e, row.id)}
                            />
                          </OverlayTrigger> */}
                          {hasPermission("Admin", "Edit Entity") && (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="tooltip-edit">{iconTooltips.editIcon}</Tooltip>}
                            >
                              <img
                                src={editIcon}
                                alt="edit"
                                className="edit-icon"
                                onClick={(e) => onRowChoose(e, row.id)}
                              />
                            </OverlayTrigger>
                          )}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {row.emailId === userEmail
                                  ? "You cannot disable your own entity"
                                  : disableBox
                                    ? "Click to enable this entity"
                                    : "Click to disable this entity"}
                              </Tooltip>
                            }
                          >
                            <div
                              className={`disable-button-container ${row.emailId === userEmail ? "disabled" : ""
                                }`}
                            >
                              <NotInterestedOutlinedIcon
                                onClick={
                                  row.emailId !== userEmail
                                    ? (e) =>
                                      disableBox
                                        ? enableRow(e, row)
                                        : disableRow(e, row)
                                    : null
                                }
                                style={{
                                  cursor: row.emailId === userEmail ? "not-allowed" : "pointer",
                                  color: row.emailId === userEmail ? "gray" : "",
                                }}
                                className={`${disableBox ? "enable-icon" : "disable-icon"
                                  } ${row.emailId === userEmail ? "disabled-icon" : ""}`}
                              />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={Object.keys(selectedColumns).length + 1}
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        color: "#666",
                        fontSize: "16px",
                      }}
                    >
                      {disableBox
                        ? "No disabled records found."
                        : "No active records found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {filteredData.length > 0 ? (
              <Paginationcontrol
                rowsCount={filteredData.length}
                tableProps={tableProps}
                tablePropsChange={tablePropsChange}
                alwaysShown={true}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        {/* Confirmation Modal */}
        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{`${enable ? 'Enable' : 'Disable'} Entity ?`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {`Are you sure you want to ${enable ? 'Enable' : 'Disable'} the entity ${selectedEntity?.entityName} ?`}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-button"
              onClick={() => setShowConfirmModal(false)}
              autoFocus // "No" as default focus
            >
              No
            </button>
            <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default EntityTable;