import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "react-modal";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import editBtn from "../../assets/editBtn.svg";
import "./Settings.css";
import { PermissionsContext } from "../../context/PermissionsContext";
import { TailSpin } from "react-loader-spinner";

// --- Success Modal ---
const SuccessModal = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Success Modal"
    overlayClassName="settings-modal-overlay"
    className="settings-modal-content"
  >
    <div className="settings-modal-header">Success!</div>
    <div className="settings-modal-body">Data has been saved successfully.</div>
    <button className="settings-modal-close-button" onClick={onClose}>
      Close
    </button>
  </Modal>
);

// --- Warning Modal ---
const WarningModal = ({ isOpen, onConfirm, onCancel, plantId, make }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onCancel}
    contentLabel="Warning Modal"
    overlayClassName="settings-modal-overlay"
    className="settings-modal-content"
  >
    <div className="settings-modal-header">Warning</div>
    <div className="settings-modal-body">
      A combination for plant <strong>{plantId}</strong> with make{" "}
      <strong>{make}</strong> already exists. Do you really want to change the make for this plant?
    </div>
    <div className="settings-modal-buttons">
      <button className="settings-modal-confirm-button" onClick={onConfirm}>
        Yes
      </button>
      <button className="settings-modal-cancel-button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </Modal>
);

const SettingsForm = () => {
  // New states for warning modal and pending data
  // const [showWarningModal, setShowWarningModal] = useState(false);
  // const [pendingPostData, setPendingPostData] = useState(null);
  // A new state to hold the original (fetched) make value
  const [originalMake, setOriginalMake] = useState("");

  // --------------------- Get auth/session data once ---------------------
  const userData = JSON.parse(sessionStorage.getItem("userData")) || {};
  const entityId = userData.entityId || "";
  const authToken = sessionStorage.getItem("authToken") || "";

  // Access Permissions context
  const { permissions } = useContext(PermissionsContext);

  // Helper: check if user has "yes" permission for a given module/widget
  const hasPermission = (moduleName, widgetName) => {
    if (!permissions || !permissions.modules) return false;
    const moduleArr = permissions.modules[moduleName];
    if (!moduleArr) return false;
    const widgetObj = moduleArr.find((w) => w.widget_name === widgetName);
    return widgetObj && widgetObj.permission === "yes";
  };

  // Check if user can view "Settings"
  const canViewSettings = hasPermission("Admin", "Settings");
  if (!canViewSettings) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <h4>You do not have permission to view Settings.</h4>
      </div>
    );
  }

  // --------------------- States ---------------------
  // Loading states for skeletons
  const [loadingLanguage, setLoadingLanguage] = useState(true);
  const [loadingLocationTypes, setLoadingLocationTypes] = useState(true);
  const [loadingMakes, setLoadingMakes] = useState(true);
  const [loadingentityid, setLoadingentityid] = useState(true);
  const [loadingplantid, setLoadingplantid] = useState(true);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingapikey, setLoadingapikey] = useState(true);
  const [loadingurl, setLoadingurl] = useState(true);
  const [loadingtoken, setLoadingtoken] = useState(true);
  const [loadingusername, setLoadingusername] = useState(true);
  const [loadingpassword, setLoadingpassword] = useState(true);


  // Language
  const [language, setLanguage] = useState("");
  const [languageOptions, setLanguageOptions] = useState([]);

  // Location types (will be fetched from API)
  const [locationTypes, setLocationTypes] = useState([]);

  // Error Code Settings
  const [make, setMake] = useState("");
  const [makeData, setMakeData] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null); // To store uploaded file's download link

   // ------------------- Entity & Plant states for API Keys section -------------------
   const [currentEntity, setCurrentEntity] = useState(null);     // from the GET response "currentEntity"
   const [entityList, setEntityList] = useState([]);             // array of possible entities
   const [plantList, setPlantList] = useState([]);               // array of plants for the selected entity 

  // API Key Settings
// Use something like this:
const [apiKeyData, setApiKeyData] = useState({
  entityId: "",
  plantId: "",
  make: "",
  country: "",
  apiKey: "",
  url: "",
  token: "",
  username: "",
  password: "",
});

  // General
  const [isSaving, setIsSaving] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [pendingPostData, setPendingPostData] = useState(null);
  const [errors, setErrors] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState({
    username: false,
    password: false,
  });
  const [isEditMode, setIsEditMode] = useState({
    language: false,
    locationTypes: false,
    errorCodeSettings: false,
    apiKeys: false,
  });

  // --------------------- Effects ---------------------
  // Fetch list of makes on mount
  useEffect(() => {
    const fetchMakes = async () => {
      try {
        const response = await fetch("https://solar-api.antsai.in/api/plant/fetchMakes", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const respData = await response.json();
          const formattedMakes = respData.map((item) => ({
            make_id: item.make_id,
            make_name: item.make_name,
          }));
          setMakeData(formattedMakes);
        } else {
          console.error("Failed to fetch make data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching makes:", error);
      } finally {
        setLoadingMakes(false);
      }
    };
    fetchMakes();
  }, [authToken]);

  // Fetch default language from API
  useEffect(() => {
    const fetchDefaultLanguages = async () => {
      try {
        if (!authToken || !entityId) {
          console.error("Missing authToken or entityId");
          return;
        }
        const res = await axios.post(
          `https://solar-api.antsai.in/api/getlanguages/${entityId}`,
          {},
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
        if (res.data && res.data.languages) {
          setLanguageOptions([res.data.languages]);
          setLanguage(res.data.languages);
        }
      } catch (error) {
        console.error("Error fetching default languages:", error);
      } finally {
        setLoadingLanguage(false);
      }
    };
    fetchDefaultLanguages();
  }, [entityId, authToken]);

  // Fetch all available languages (additional languages)
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const res = await axios.post(
          "https://solar-api.antsai.in/api/languages",
          {},
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
        const languages = res.data || [];
        setLanguageOptions(languages.sort());
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, [authToken]);

  // Function to fetch Location Types from API
  const fetchLocationTypes = async () => {
    if (!entityId || !authToken) {
      console.error("Entity ID or auth token missing");
      return;
    }
    try {
      const res = await axios.post(
        `https://solar-api.antsai.in/api/getlocations/${entityId}`,
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (res.data && res.data.country_locations) {
        setLocationTypes(res.data.country_locations);
      } else {
        console.error("No country_locations data received", res.data);
      }
    } catch (error) {
      console.error("Error fetching location types:", error);
    } finally {
      setLoadingLocationTypes(false);
    }
  };

  // Fetch Location Types as soon as the Settings page loads
  useEffect(() => {
    fetchLocationTypes();
  }, [entityId, authToken]);

  // Fetch countries for API Keys dropdown
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://solar-api.antsai.in/api/countries", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });
        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error(
            `Failed to fetch countries: ${response.status} ${response.statusText}`
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoadingCountries(false);
      }
    };
    fetchCountries();
  }, []);

    // 6) NEW: Fetch Entities + Current Entity from your GET endpoint
    useEffect(() => {
      const fetchEntities = async () => {
        if (!entityId) return;
        try {
          const res = await axios.get(
            `https://solar-api.antsai.in/api/fetchEntityNamesSettings?entityid=${entityId}`,
            {
              headers: { Authorization: `Bearer ${authToken}` },
            }
          );
          if (res.data) {
            // The response typically has { currentEntity, entities }
            setCurrentEntity(res.data.currentEntity);
            setEntityList(res.data.entities || []);
             // Set the dropdown's default selection to the current entity
    setApiKeyData((prev) => ({
      ...prev,
      entityName: res.data.currentEntity.entityid,
    }));
          }
        } catch (error) {
          console.error("Error fetching entity list:", error.response?.data || error.message);
        } finally {
          setLoadingentityid(false);
        }
      };
      fetchEntities();
    }, [entityId, authToken]);

  // *** NEW EFFECT: Automatically fetch the uploaded file on page load (like language) ***  
  // This effect will run whenever entityId, make, or authToken changes.
  useEffect(() => {
    const fetchFile = async () => {
      // Only attempt if entityId and a valid make are available.
      if (!entityId || !make) return;
      try {
        const res = await axios.get("https://solar-api.antsai.in/api/fetch-file", {
          params: { entity_id: entityId, make: make },
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (res.data && res.data.download_link) {
          setUploadedFile(res.data.download_link);
        } else {
          console.error("Download link not received", res.data);
        }
      } catch (error) {
        console.error("Error fetching file:", error.response?.data || error.message);
      }
    };
    fetchFile();
  }, [entityId, make, authToken]);

  // --------------------- NEW: Fetch API Key Data (GET) whenever make, entityId, or plantId changes ---------------------
  useEffect(() => {
    const { make, entityId, plantId } = apiKeyData;
    // Fetch only if make & entityId are not empty (plantId is optional)
    if (make && entityId) {
      fetchApiKeySettings(make, entityId, plantId);
    }

  }, [apiKeyData.make, apiKeyData.entityId, apiKeyData.plantId]);

  // const fetchApiKeySettings = async (make, entityId, plantId) => {
  //   try {
  //     let url = `https://solar-api.antsai.in/api/get-api-settings?make=${make}&entityId=${entityId}`;
  //     if (plantId) {
  //       url += `&plantId=${plantId}`;
  //     }
  //     const res = await axios.get(url, {
  //       headers: { Authorization: `Bearer ${authToken}` },
  //     });

  //     if (res.data && res.data.data) {
  //       const d = res.data.data;
  //       // Populate our API Key form fields (ignore body, header, trans_id)
  //       setApiKeyData((prev) => ({
  //         ...prev,
  //         country: d.country || "",
  //         apiKey: d.api_key || "",
  //         url: d.url || "",
  //         token: d.token || "",
  //         username: d.user_name || "",
  //         password: d.password || "",
  //       }));
  //     }
  //   } catch (err) {
  //     console.error(
  //       "Error fetching API key settings:",
  //       err.response?.data || err.message
  //     );
  //   }
  // };

  // const fetchApiKeySettings = async (make, entityId, plantId) => {
  //   try {
  //     let url = `https://solar-api.antsai.in/api/get-api-settings?make=${make}&entityId=${entityId}`;
  //     if (plantId) {
  //       url += `&plantId=${plantId}`;
  //     }
  //     const res = await axios.get(url, {
  //       headers: { Authorization: `Bearer ${authToken}` },
  //     });
  
  //     if (res.data && res.data.data) {
  //       const d = res.data.data;
  //       // Populate our API Key form fields and update the original make
  //       setApiKeyData((prev) => ({
  //         ...prev,
  //         country: d.country || "",
  //         apiKey: d.api_key || "",
  //         url: d.url || "",
  //         token: d.token || "",
  //         username: d.user_name || "",
  //         password: d.password || "",
  //         make: d.make || prev.make, // update make if available
  //       }));
  //       // IMPORTANT: Update originalMake if a make is fetched
  //       if (d.make) {
  //         setOriginalMake(d.make);
  //       }
  //     }
  //   } catch (err) {
  //     console.error(
  //       "Error fetching API key settings:",
  //       err.response?.data || err.message
  //     );
  //   }
  // };

  const fetchApiKeySettings = async (make, entityId, plantId) => {
    try {
      let url = `https://solar-api.antsai.in/api/get-api-settings?make=${make}&entityId=${entityId}`;
      if (plantId) {
        url += `&plantId=${plantId}`;
      }
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
  
      if (res.data && res.data.data) {
        const d = res.data.data;
        // Populate our API Key form fields and update the original make
        setApiKeyData((prev) => ({
          ...prev,
          country: d.country || "",
          apiKey: d.api_key || "",
          url: d.url || "",
          token: d.token || "",
          username: d.user_name || "",
          password: d.password || "",
          make: d.make || prev.make, // update make if available
        }));
        // Update originalMake if a make is fetched
        if (d.make) {
          setOriginalMake(d.make);
        }
      } else {
        // Reset API key fields if no data is fetched
        setApiKeyData((prev) => ({
          ...prev,
          country: "",
          apiKey: "",
          url: "",
          token: "",
          username: "",
          password: "",
        }));
        setOriginalMake("");
      }
    } catch (err) {
      console.error(
        "Error fetching API key settings:",
        err.response?.data || err.message
      );
      // Also reset fields on error
      setApiKeyData((prev) => ({
        ...prev,
        country: "",
        apiKey: "",
        url: "",
        token: "",
        username: "",
        password: "",
      }));
      setOriginalMake("");
    }
  };
  
  // --------------------- Handlers ---------------------
  const toggleEditMode = (section) => {
    setIsEditMode((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const showSuccessModal = () => setSuccessModal(true);

  // Helper: simple field check
  const validateField = (value, fieldName) =>
    value ? "" : `${fieldName} is required`;

  // --- Language ---
  const handleSaveLanguage = async () => {
    if (!entityId) {
      console.error("Entity ID is missing in sessionStorage.");
      setErrors({ entityId: "Entity ID is required to save language settings." });
      return;
    }
    try {
      await axios.post(
        "https://solar-api.antsai.in/api/savelanguages",
        { entityId, languages: language },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      showSuccessModal();
      setIsEditMode((prev) => ({ ...prev, language: false }));
    } catch (err) {
      console.error("Error saving language:", err.response?.data || err.message);
    }
  };

  // --- Location Types ---
  const toggleLocationSelection = (index) => {
    setLocationTypes((prev) =>
      prev.map((loc, i) =>
        i === index ? { ...loc, selected: !loc.selected } : loc
      )
    );
  };

  const handleSaveLocationTypes = async () => {
    const selected = locationTypes.filter((loc) => loc.selected);
    if (selected.length === 0) {
      setErrors({ locationTypes: "At least one location must be selected." });
      return;
    }
    try {
      await axios.post(
        "https://solar-api.antsai.in/api/savelocation",
        { entityId, country_locations: locationTypes },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      showSuccessModal();
      setIsEditMode((prev) => ({ ...prev, locationTypes: false }));
      fetchLocationTypes();
    } catch (error) {
      console.error("Error saving location types:", error);
    }
  };

  // --- Error Code Settings ---
  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleSaveErrorCodeSettings = async () => {
    const errorMake = validateField(make, "Make");
    const errorFile = file ? "" : "File is required";
    if (errorMake || errorFile) {
      setErrors({ make: errorMake, file: errorFile });
      return;
    }
    const formData = new FormData();
    formData.append("entity_id", entityId);
    formData.append("make", make);
    formData.append("file", file);
    try {
      const response = await axios.post("https://solar-api.antsai.in/api/upload-log", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });
      showSuccessModal();
      setIsEditMode((prev) => ({ ...prev, errorCodeSettings: false }));
      // Save the uploaded file path returned from the API
      setUploadedFile(response.data.file_path);
    } catch (error) {
      console.error("Error uploading error code settings:", error.response?.data || error.message);
    }
  };

  // --- API Keys ---
  // 1) When the user changes the "entity" from the dropdown,
  //    fetch the plant list for that entity's user_id
  const handleEntityChange = async (e) => {
    const selectedEntityId = e.target.value;
    // Store the selected entity id in your apiKeyData
    setApiKeyData((prev) => ({
      ...prev,
      entityId: selectedEntityId,
      plantId: "", // reset plantId if a different entity is chosen
    }));
    setPlantList([]);  // Reset plant list
  
    // Fetch the plants using the entity id in the query parameter
    try {
      const res = await axios.post(
        `https://solar-api.antsai.in/api/fetchPlantListSt?entityId=${selectedEntityId}`,
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (res.data && res.data.data) {
        setPlantList(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching plants:", error.response?.data || error.message);
    }
  };

  const handleApiKeyInputChange = (e) => {
    const { name, value } = e.target;
    setApiKeyData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // Helper function for URL validation
const validateURLFormat = (url) => {
  // Simple regex pattern for URL validation (allows http/https + domain + optional path)
  // You can customize or simplify as per your requirements
  const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/;
  
  if (!url) {
    return "URL is required";
  } else if (!urlPattern.test(url)) {
    return "Invalid URL";
  }
  return "";
};

  // Function that saves the data to the backend
  const saveApiKeySettings = async (postData) => {
    try {
      setIsSaving(true);
      const response = await axios.post(
        "https://solar-api.antsai.in/api/plant-api-settings",
        postData,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      // Handle success (for example, show a success message)
      console.log("Data saved successfully:", response.data);
      showSuccessModal();
      // Optionally, exit edit mode for API Keys
      setIsEditMode((prev) => ({ ...prev, apiKeys: false }));
       // Refresh the API keys section by re-fetching the API key settings
      await fetchApiKeySettings(postData.make, postData.entityId, postData.plantId);
    } catch (error) {
      console.error("Error saving API keys:", error.response?.data || error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveApiKeySettings = async () => {
    const {
      entityId,
      plantId,
      make,
      country,
      apiKey,
      url,
      token,
      username,
      password,
    } = apiKeyData;
    const errorEntityId = validateField(entityId, "Entity ID");
    // const errorPlantId = validateField(plantId, "Plant ID");
    const errorMake = validateField(make, "Make");
    const errorCountry = validateField(country, "Country");
    const errorApiKey = validateField(apiKey, "API Key");
    const errorURL = validateURLFormat(url);  // <-- updated to use URL validation
    const errorToken = validateField(token, "Token");
    const errorUsername = validateField(username, "Username");
    const errorPassword = validateField(password, "Password");

    if (
      errorEntityId ||
      // errorPlantId  ||
      errorMake ||
      errorCountry ||
      errorApiKey ||
      errorURL ||
      errorToken ||
      errorUsername ||
      errorPassword
    ) {
      setErrors({
        entityId: errorEntityId,
        // plantId: errorPlantId,
        make: errorMake,
        country: errorCountry,
        apiKey: errorApiKey,
        url: errorURL,
        token: errorToken,
        username: errorUsername,
        password: errorPassword,
      });
      return;
    }

    // Build the POST body in the exact format backend expects
    const postData = {
      entityId: apiKeyData.entityId,
      plantId: apiKeyData.plantId ? apiKeyData.plantId : null,
      make: apiKeyData.make,
      country: apiKeyData.country,
      apiKey: apiKeyData.apiKey,
      url: apiKeyData.url,
      token: apiKeyData.token,
      username: apiKeyData.username,
      password: apiKeyData.password,
    };
  
    console.log("Final data to post:", postData);
    try {
      setIsSaving(true);
      // First, call the check-plant-make-combination API
      const res = await axios.post(
        "https://solar-api.antsai.in/api/check-plant-make-combination",
        { plantId: postData.plantId, make: postData.make },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (res.data && res.data.message === "Show Warning") {
        // If a warning is needed, store the pending post data and display the modal
        setPendingPostData(postData);
        setShowWarningModal(true);
      } else {
        // No warning needed so directly save the data
        await saveApiKeySettings(postData);
      }
    } catch (error) {
      console.error("Error during check:", error.response?.data || error.message);
    } finally {
      setIsSaving(false);
    }
  };

  // Handler when user confirms the warning modal
  const handleConfirmMakeChange = async () => {
    if (pendingPostData) {
      await saveApiKeySettings(pendingPostData);
      setPendingPostData(null);
    }
    setShowWarningModal(false);
  };

  // Handler when user cancels the warning modal
  const handleCancelMakeChange = () => {
    setPendingPostData(null);
    setShowWarningModal(false);
  };

        // // If a plant is selected, check the plant-make combination first
        // if (postData.plantId) {
        //   try {
        //     const res = await axios.post(
        //       "https://solar-api.antsai.in/api/check-plant-make-combination",
        //       { plantId: postData.plantId, make: postData.make },
        //       { headers: { Authorization: `Bearer ${authToken}` } }
        //     );
        //     if (res.data && res.data.message === "Show Warning") {
        //       // Show warning modal and store pending post data
        //       setPendingPostData(postData);
        //       setShowWarningModal(true);
        //       return; // Do not proceed with saving yet until confirmation
        //     }
        //   } catch (error) {
        //     console.error("Error checking plant-make combination:", error.response?.data || error.message);
        //     // Depending on your requirements, you might want to block saving if the check fails.
        //   }
        // }
    
        // Proceed with saving if no warning (or if no plantId)
      //   await saveApiKeySettings(postData);
      // };
    
      // // Function to save API key settings to backend
      // const saveApiKeySettings = async (postData) => {
      //   console.log("Final data to post:", postData);
      //   try {
      //     const response = await axios.post(
      //       "https://solar-api.antsai.in/api/plant-api-settings",
      //       postData,
      //       { headers: { Authorization: `Bearer ${authToken}` } }
      //     );
      //     console.log("API response:", response.data);
      //     // Show success modal and exit edit mode
      //     showSuccessModal();
      //     setIsEditMode((prev) => ({ ...prev, apiKeys: false }));
      //   } catch (error) {
      //     console.error("Error saving API keys:", error.response?.data || error.message);
      //   }
      // };
    
      // // Handler for when the user confirms the make change from the warning modal
      // const handleConfirmMakeChange = async () => {
      //   if (pendingPostData) {
      //     await saveApiKeySettings(pendingPostData);
      //     setPendingPostData(null);
      //   }
      //   setShowWarningModal(false);
      // };
    
      // // Handler for canceling the make change from the warning modal
      // const handleCancelMakeChange = () => {
      //   setPendingPostData(null);
      //   setShowWarningModal(false);
      // };
    
  // --------------------- Render ---------------------
  return (
    <div className="settings-container">
      <h2>Settings</h2>

      {/* --------------------- Language Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          Language Settings :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("language")}
          />
        </h3>
        <div className="settings-form-content-box">
          <div className="settings-form-content-blocks">
            <label>
              Language <span className="settings-required">*</span>:
            </label>
            {loadingLanguage ? (
              <Skeleton height={30} width={200} />
            ) : (
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                disabled={!isEditMode.language}
              >
                <option value="">Select Language</option>
                {languageOptions.map((lang, idx) => (
                  <option key={idx} value={lang}>
                    {lang}
                  </option>
                ))}
              </select>
            )}
            {errors.language && (
              <span className="settings-error">{errors.language}</span>
            )}
          </div>
        </div>
        <div className="settings-button-container">
          <button
            className="settings-button-save"
            onClick={handleSaveLanguage}
            disabled={!isEditMode.language}
          >
            Save
          </button>
        </div>
      </div>

      {/* --------------------- Location Type Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          Location Type Settings :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("locationTypes")}
          />
        </h3>
        <div className="settings-form-content-box" style={{ padding: 0 }}>
          <table className="settings-location-type-table">
            <thead>
              <tr>
                <th>Location Type</th>
                <th>Country</th>
                <th style={{ textAlign: "center" }}>Selected</th>
              </tr>
            </thead>
            <tbody>
              {loadingLocationTypes ? (
                [0, 1, 2].map((i) => (
                  <tr key={i}>
                    <td>
                      <Skeleton width={150} />
                    </td>
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Skeleton width={50} />
                    </td>
                  </tr>
                ))
              ) : (
                locationTypes.map((location, index) => (
                  <tr key={index}>
                    <td>{location.label}</td>
                    <td>{location.country}</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        className="settings-checkboxes"
                        type="checkbox"
                        checked={location.selected}
                        onChange={() => toggleLocationSelection(index)}
                        disabled={!isEditMode.locationTypes}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {errors.locationTypes && (
            <span className="settings-error">{errors.locationTypes}</span>
          )}
        </div>
        <div className="settings-button-container">
          <button
            className="settings-button-save"
            onClick={handleSaveLocationTypes}
            disabled={!isEditMode.locationTypes}
          >
            Save
          </button>
        </div>
      </div>

      {/* --------------------- Error Code Settings Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          Error Code Settings :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("errorCodeSettings")}
          />
        </h3>
        <div className="settings-form-content-box">
          <div className="settings-form-content-blocks">
            <label>
              Make <span className="settings-required">*</span>:
            </label>
            {loadingMakes ? (
              <Skeleton height={30} width={150} />
            ) : (
              <select
                value={make}
                onChange={(e) => setMake(e.target.value)}
                disabled={!isEditMode.errorCodeSettings}
              >
                <option value="">Select Make</option>
                {makeData.map((item) => (
                  <option key={item.make_id} value={item.make_name}>
                    {item.make_name}
                  </option>
                ))}
              </select>
            )}
            {errors.make && (
              <span className="settings-error">{errors.make}</span>
            )}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              Upload File <span className="settings-required">*</span>:
            </label>
            <div className="settings-input-bottom-info">
              <input
                type="file"
                onChange={handleFileChange}
                accept=".xls,.csv"
                className="settings-file-upload-input"
                disabled={!isEditMode.errorCodeSettings}
              />
              {errors.file && (
                <span className="settings-error">{errors.file}</span>
              )}
              <small className="settings-file-info">
                (Supported file types: .xls, .csv)
              </small>
            </div>
            {/* If an uploaded file exists, show it as a clickable link */}
            {uploadedFile && (
              <div className="uploaded-file-info">
                Uploaded File:{" "}
                <a
                  href={uploadedFile}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {uploadedFile.split("/").pop()}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="settings-button-container">
          <button
            className="settings-button-save"
            onClick={handleSaveErrorCodeSettings}
            disabled={!isEditMode.errorCodeSettings}
          >
            Save
          </button>
        </div>
      </div>

      {/* --------------------- API Keys Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          API Keys :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("apiKeys")}
          />
        </h3>
        <div className="settings-form-content-box">
        {/* <div className="settings-form-content-blocks">
            <label>
              Make <span className="settings-required">*</span>:
            </label>
            {loadingMakes ? (
              <Skeleton height={30} width={150} />
            ) : (
              <select
                name="make"
                value={apiKeyData.make}
                onChange={handleApiKeyInputChange}
                disabled={!isEditMode.apiKeys}
              >
                <option value="">Select Make</option>
                {makeData.map((item) => (
                  <option key={item.make_id} value={item.make_name}>
                    {item.make_name}
                  </option>
                ))}
              </select>
            )}
            {errors.make && (
              <span className="settings-error">{errors.make}</span>
            )}
          </div> */}

<div className="settings-form-content-blocks">
  <label>
    Make <span className="settings-required">*</span>:
  </label>
  {loadingMakes ? (
    <Skeleton height={30} width={150} />
  ) : (
    <select
      name="make"
      value={apiKeyData.make}
      onChange={handleApiKeyInputChange}
      disabled={!isEditMode.apiKeys}
    >
      <option value="">Select Make</option>
      {makeData.map((item) => (
        <option key={item.make_id} value={item.make_name}>
          {item.make_name}
        </option>
      ))}
    </select>
  )}
  {errors.make && (
    <span className="settings-error">{errors.make}</span>
  )}
</div>

            {/* Entity Name */}
            <div className="settings-form-content-blocks">
            <label>
              Entity Name <span className="settings-required">*</span>:
            </label>
            {loadingentityid ? (
              <Skeleton height={30} width={150} />
            ) : (
            <select
              name="entityId"
              value={apiKeyData.entityId}
              onChange={handleEntityChange}
              disabled={!isEditMode.apiKeys}
            >
              <option value="">Select Entity</option>
              {currentEntity && (
                <option value={currentEntity.entityid}>
                  {currentEntity.entityname} (Current)
                </option>
              )}
              {entityList.map((entity) => (
                <option key={entity.entityid} value={entity.entityid}>
                  {entity.entityname}
                </option>
              ))}
            </select>
            )}
            {errors.entityName && (
              <span className="settings-error">{errors.entityName}</span>
            )}
          </div>

            {/* Plant Name (Optional) */}
            {/* <div className="settings-form-content-blocks">
                      <label>Plant Name :</label>
                      <select
            name="plantId"
            value={apiKeyData.plantId}
            onChange={handleApiKeyInputChange}
            disabled={!isEditMode.apiKeys || !apiKeyData.entityId}
          >
            <option value="">Select Plant</option>
            {plantList.map((plant) => (
              <option key={plant.plant_id} value={plant.plant_id}>
                {plant.plant_name}
              </option>
            ))}
          </select>
          </div> */}

          {/* Plant Name (Optional) */}
<div className="settings-form-content-blocks">
  <label>Plant Name :</label>
  <select
    name="plantId"
    value={apiKeyData.plantId}
    onChange={handleApiKeyInputChange}
    disabled={!isEditMode.apiKeys || !apiKeyData.entityId}
  >
    <option value="">Select Plant</option>
    {plantList.map((plant) => (
      <option key={plant.plant_id} value={plant.plant_id}>
        {plant.plant_name}
      </option>
    ))}
  </select>
</div>

    {/* Country */}
    <div className="settings-form-content-blocks">
            <label>
              Country <span className="settings-required">*</span>:
            </label>
            {loadingCountries ? (
              <Skeleton height={30} width={150} />
            ) : (
              <select
                name="country"
                value={apiKeyData.country}
                onChange={handleApiKeyInputChange}
                disabled={!isEditMode.apiKeys}
              >
                <option value="">Select Country</option>
                {countryList.map((countryObj) => (
                  <option
                    key={countryObj.country_id}
                    value={countryObj.country_name}
                  >
                    {countryObj.country_name}
                  </option>
                ))}
              </select>
            )}
            {errors.country && (
              <span className="settings-error">{errors.country}</span>
            )}
          </div>

          {/* API Key */}
          <div className="settings-form-content-blocks">
            <label>
              API Key <span className="settings-required">*</span>:
            </label>
            <input
              type="text"
              name="apiKey"
              value={apiKeyData.apiKey}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            />
            {errors.apiKey && (
              <span className="settings-error">{errors.apiKey}</span>
            )}
          </div>

          {/* URL */}
          <div className="settings-form-content-blocks">
            <label>
              URL <span className="settings-required">*</span>:
            </label>
            <input
              type="text"
              name="url"
              value={apiKeyData.url}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            />
            
            {errors.url && <span className="settings-error">{errors.url}</span>}
          </div>

          {/* Token */}
          <div className="settings-form-content-blocks">
            <label>
              Token <span className="settings-required">*</span>:
            </label>
            <input
              type="text"
              name="token"
              value={apiKeyData.token}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            />
            {errors.token && (
              <span className="settings-error">{errors.token}</span>
            )}
          </div>

          {/* Username */}
          <div className="settings-form-content-blocks">
            <label>
              Username <span className="settings-required">*</span>:
            </label>
            <div className="settings-password-container">
              <input
                type={showPassword.username ? "text" : "password"}
                name="username"
                value={apiKeyData.username}
                onChange={handleApiKeyInputChange}
                disabled={!isEditMode.apiKeys}
              />
              <button
                type="button"
                className="settings-toggle-visibility"
                onClick={() => togglePasswordVisibility("username")}
              >
                {showPassword.username ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {errors.username && (
              <span className="settings-error">{errors.username}</span>
            )}
          </div>

          {/* Password */}
          <div className="settings-form-content-blocks">
            <label>
              Password <span className="settings-required">*</span>:
            </label>
            <div className="settings-password-container">
              <input
                type={showPassword.password ? "text" : "password"}
                name="password"
                value={apiKeyData.password}
                onChange={handleApiKeyInputChange}
                disabled={!isEditMode.apiKeys}
              />
              <button
                type="button"
                className="settings-toggle-visibility"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword.password ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {errors.password && (
              <span className="settings-error">{errors.password}</span>
            )}
          </div>
        </div>

        <div className="settings-button-container">
          {/* <button
            className="settings-button-save"
            onClick={handleSaveApiKeySettings}
            disabled={!isEditMode.apiKeys}
          >
            Save
          </button> */}
            {/* Save button with TailSpin loader */}
            <button
              className="settings-button-save"
              onClick={handleSaveApiKeySettings}
              disabled={!isEditMode.apiKeys || isSaving}
            >
              {isSaving ? (
                <TailSpin height="20" width="20" color="#fff" ariaLabel="loading" />
              ) : (
                "Save"
              )}
            </button>
        </div>
      </div>

      <SuccessModal isOpen={successModal} onClose={() => setSuccessModal(false)} />
        {/* Warning Modal */}
        <Modal
        isOpen={showWarningModal}
        onRequestClose={handleCancelMakeChange}
        contentLabel="Warning Modal"
        overlayClassName="settings-modal-overlay"
        className="settings-modal-content"
        style={{
          content: {
            width: "450px",      // Set a fixed width (or use a percentage, e.g., "80%")
            maxWidth: "90%",     // Ensure it doesn't overflow on smaller screens
            margin: "auto",      // Center the modal horizontally and vertically
          },
        }}
      >
        <h2>Warning</h2>
        <p>
          A combination for plant <strong>{pendingPostData?.plantId}</strong> with make{" "}
          <strong>{pendingPostData?.make}</strong> already exists.
          <br />
          Do you really want to change the make for this plant?
        </p>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button className="settings-button-save" onClick={handleConfirmMakeChange}>Yes</button>
          <button className="settings-button-save" onClick={handleCancelMakeChange}>No</button>
        </div>
      </Modal>
    </div>
  );
};

export default SettingsForm;