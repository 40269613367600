import React, { useState, useEffect } from "react";
import "./ProductionOverview.css";
import GaugeChart from "./GaugeChart"; // Use your custom GaugeChart
import dragHandleDots from "../../assets/drag_handle_dots.svg";
import expandAltIcon from "../../assets/expand_alt_icon.svg";
import informationIcon from "../../assets/information_icon.svg";
import { Dialog, DialogContent } from "@mui/material";
import CloseBlue from "../../assets/closeBtnBlue.svg";

const ProductionOverview = () => {
  // Retrieve session data from sessionStorage
  const userData = JSON.parse(sessionStorage.getItem("userData") || "{}");
  const userId = userData.userId || "";
  const entityName = userData.entityName || "";
  const token = userData.token || "";

  const [selectedOption, setSelectedOption] = useState("Month");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  // State for storing API data
  const [efficiencyData, setEfficiencyData] = useState({
    gauge: {
      percent: 0,
      colors: ["#00FF00"],
      needleColor: "#FF0000",
    },
    totalProductionPower: "0", // in KW
  });

  const [detailsData, setDetailsData] = useState({
    installedCapacity: "0", // in kWp
    details: {
      daily: "0", // in kWh
      monthly: "0", // in MWh
      yearly: "0", // (to be provided later)
      total: "0", // (to be provided later)
    },
  });

  // Helper function to pad numbers with a leading zero if needed.
  const pad = (num) => num.toString().padStart(2, "0");

  // Function to get the formatted datetime string for current power production.
  const getFormattedDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());
    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    // Format: "YYYY-MM-DD HH-mm"
    return `${year}-${month}-${day} ${hours}-${minutes}`;
  };

  // Function to get today's date formatted as "YYYY-MM-DD"
  const getTodayDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());
    return `${year}-${month}-${day}`;
  };

  // Function to get the start of the month and yesterday's date for monthly production API.
  const getMonthDateRange = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    // Start date: first day of current month.
    const startDate = `${year}-${pad(month + 1)}-01`;

    // End date: yesterday's date.
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const endYear = yesterday.getFullYear();
    const endMonth = pad(yesterday.getMonth() + 1);
    const endDay = pad(yesterday.getDate());
    const endDate = `${endYear}-${endMonth}-${endDay}`;

    return { startDate, endDate };
  };

  // Function to fetch all required data from the API endpoints.
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. Get Plant IDs using the session userId, passing token in headers.
        const plantIdsResponse = await fetch(
          `https://solar-api.antsai.in/api/getPlantIds/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          }
        );
        if (!plantIdsResponse.ok) {
          throw new Error("Error fetching plant IDs");
        }
        const plantIds = await plantIdsResponse.json();
        console.log("Plant IDs:", plantIds);
        // 2. Get Installed Capacity using all plant IDs
        console.log("Sending capacity request with body:", {
          plantIds: plantIds,
        });
        const capacityResponse = await fetch(
          "https://solar-api.antsai.in/api/fetchTotalCapacity",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
              plantIds: plantIds,
            }),
          }
        );
        if (!capacityResponse.ok) {
          throw new Error("Error fetching total capacity");
        }
        const capacityData = await capacityResponse.json();
        console.log("Capacity Data:", capacityData);

        // 3. Select a plant ID that matches the session's entity name.
        // const myPlantId =
        //   plantIds.find((id) =>
        //     id.toUpperCase().includes(entityName.toUpperCase())
        //   ) || plantIds[0]; // fallback if not found
        // console.log("Selected Plant ID:", myPlantId);

        // 4. Get Current Power Production
        const datetime = getFormattedDateTime();
        console.log("Sending current power request with body:", {
          plantIds: [plantIds],
          datetime: datetime,
        });
        const powerResponse = await fetch(
          "https://solar-api.antsai.in/api/fetchTotalPower",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
              plantIds: [plantIds],
              datetime: datetime,
            }),
          }
        );
        if (!powerResponse.ok) {
          throw new Error("Error fetching current power production");
        }
        const powerData = await powerResponse.json();
        console.log("Power Data:", powerData);

        // 5. Get Daily Energy
        const today = getTodayDate();
        console.log("Sending daily energy request with body:", {
          plantIds: [plantIds],
          date: today,
        });
        const dailyResponse = await fetch(
          "https://solar-api.antsai.in/api/fetchDailyEnergy",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
              plantIds: [plantIds],
              date: today,
            }),
          }
        );
        if (!dailyResponse.ok) {
          throw new Error("Error fetching daily energy");
        }
        const dailyData = await dailyResponse.json();
        console.log("Daily Energy Data:", dailyData);

        // 6. Get Monthly Energy
        const { startDate, endDate } = getMonthDateRange();
        console.log("Sending monthly production request with body:", {
          plantIds: [plantIds],
          startDate: startDate,
          endDate: endDate,
        });
        const monthlyResponse = await fetch(
          "https://solar-api.antsai.in/api/fetchMonthlyProduction",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
              plantIds: [plantIds],
              startDate: startDate,
              endDate: endDate,
            }),
          }
        );
        if (!monthlyResponse.ok) {
          throw new Error("Error fetching monthly production");
        }
        const monthlyData = await monthlyResponse.json();
        console.log("Monthly Production Data:", monthlyData);

        // From the response, we take the monthly total (in kWh) and convert to MWh.
        const monthlyKWH =
          monthlyData && monthlyData.data && monthlyData.data[0]
            ? monthlyData.data[0].monthlyTotalForPlant
            : 0;
        // Convert kWh to MWh by dividing by 1000.
        const monthlyMWh = (monthlyKWH / 1000).toFixed(2);

        // 7. Calculate Efficiency: (current power production / installed capacity) * 100
        const currentPower = parseFloat(powerData.totalPowerKWH) || 0;
        const installedCapacity = parseFloat(capacityData.totalCapacity) || 0;
        const efficiency =
          installedCapacity > 0
            ? ((currentPower / installedCapacity) * 100).toFixed(2)
            : "0.00";

        // 8. Update the state for display:
        setEfficiencyData({
          gauge: {
            percent: parseFloat(efficiency),
            colors: ["#00FF00"],
            needleColor: "#FF0000",
          },
          totalProductionPower: currentPower.toFixed(2),
        });

        setDetailsData({
          installedCapacity: installedCapacity.toString(),
          details: {
            daily: parseFloat(dailyData.totalDailyEnergy || 0).toFixed(2),
            monthly: monthlyMWh,
            yearly: "0", // to be provided later
            total: "0",  // to be provided later
          },
        });
      } catch (error) {
        console.error("Error fetching production data:", error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, entityName, token]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="PO-production-overview-card">
      <div className="PO-card-header">
        <div className="PO-header-left">
          <img
            src={dragHandleDots}
            alt="Drag Handle"
            className="PO-drag-handle"
          />
          <h2 className="PO-card-title">Production Overview</h2>
          <img
            src={informationIcon}
            alt="Information Icon"
            className="PO-info-icon"
          />
        </div>
        <div className="PO-header-right">
          <div className="PO-icon" onClick={handleOpenModal}>
            <img
              src={expandAltIcon}
              alt="Expand Icon"
              className="PO-expand-icon"
            />
          </div>
        </div>
      </div>
      <div className="PO-card-content">
        <div className="PO-gauge-container">
          {efficiencyData && (
            <GaugeChart value={efficiencyData.gauge.percent} />
          )}
        </div>
        <div className="PO-center-section">
          <div className="PO-detail-row-left">
            <div className="PO-detail-item-left">
              <span className="PO-label-left">Current Power Production</span>
              <span className="PO-value-left">
                {efficiencyData?.totalProductionPower}
                <span className="PO-value-units"> KW</span>
              </span>
            </div>
          </div>
          <div className="PO-dotted-line-horizontal"></div>
          <div className="PO-detail-row-left">
            <div className="PO-detail-item-left">
              <span className="PO-label-left">Installed Capacity</span>
              <span className="PO-value-left">
                {detailsData?.installedCapacity}
                <span className="PO-value-units"> kWp</span>
              </span>
            </div>
          </div>
        </div>
        <div className="PO-right-section">
          <div className="PO-detail-row-with-divider">
            <div className="PO-detail-item">
              <span className="PO-label">Daily</span>
              <span className="PO-value">
                {detailsData?.details.daily}
                <span className="PO-value-units"> kWh</span>
              </span>
            </div>
            <div className="PO-detail-item">
              <span className="PO-label">Monthly</span>
              <span className="PO-value">
                {detailsData?.details.monthly}
                <span className="PO-value-units"> MWh</span>
              </span>
            </div>
          </div>
          <div className="PO-dotted-line-horizontal-small"></div>
          <div className="PO-detail-row-with-divider">
            <div className="PO-detail-item">
              <span className="PO-label">Yearly</span>
              <span className="PO-value">
                {detailsData?.details.yearly}
                <span className="PO-value-units"> MWh</span>
              </span>
            </div>
            <div className="PO-detail-item">
              <span className="PO-label">Total</span>
              <span className="PO-value">
                {detailsData?.details.total}
                <span className="PO-value-units"> MWh</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Dialog */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth={"xl"}
        PaperProps={{
          sx: {
            width: "50%",
            minHeight: "45%",
          },
        }}
        fullWidth
      >
        <DialogContent>
          <div>
            <div className="PO-card-header">
              <div className="PO-header-left">
                <img
                  src={dragHandleDots}
                  alt="Drag Handle"
                  className="PO-drag-handle"
                />
                <h2 className="PO-card-title">Production Overview</h2>
                <img
                  src={informationIcon}
                  alt="Information Icon"
                  className="PO-info-icon"
                />
              </div>
              <div className="PO-header-right">
                <div className="PO-icon">
                  <img
                    src={CloseBlue}
                    alt="Close Icon"
                    className="PO-close-icon"
                    onClick={handleCloseModal}
                  />
                </div>
              </div>
            </div>
            <div className="PO-card-content">
              <div className="PO-gauge-container">
                {efficiencyData && (
                  <GaugeChart value={efficiencyData.gauge.percent} />
                )}
              </div>
              <div className="PO-center-section">
                <div className="PO-detail-row-left">
                  <div className="PO-detail-item-left">
                    <span className="PO-label-left">
                      Current Power Production
                    </span>
                    <span className="PO-value-left">
                      {efficiencyData?.totalProductionPower}
                      <span className="PO-value-units"> KW</span>
                    </span>
                  </div>
                </div>
                <div className="PO-dotted-line-horizontal"></div>
                <div className="PO-detail-row-left">
                  <div className="PO-detail-item-left">
                    <span className="PO-label-left">Installed Capacity</span>
                    <span className="PO-value-left">
                      {detailsData?.installedCapacity}
                      <span className="PO-value-units"> kWp</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="PO-right-section">
                <div className="PO-detail-row-with-divider">
                  <div className="PO-detail-item">
                    <span className="PO-label">Daily</span>
                    <span className="PO-value">
                      {detailsData?.details.daily}
                      <span className="PO-value-units"> kWh</span>
                    </span>
                  </div>
                  <div className="PO-detail-item">
                    <span className="PO-label">Monthly</span>
                    <span className="PO-value">
                      {detailsData?.details.monthly}
                      <span className="PO-value-units"> MWh</span>
                    </span>
                  </div>
                </div>
                <div className="PO-dotted-line-horizontal-small"></div>
                <div className="PO-detail-row-with-divider">
                  <div className="PO-detail-item">
                    <span className="PO-label">Yearly</span>
                    <span className="PO-value">
                      {detailsData?.details.yearly}
                      <span className="PO-value-units"> MWh</span>
                    </span>
                  </div>
                  <div className="PO-detail-item">
                    <span className="PO-label">Total</span>
                    <span className="PO-value">
                      {detailsData?.details.total}
                      <span className="PO-value-units"> MWh</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductionOverview;
