// import React, {
//   useState,
//   useEffect,
//   useMemo,
//   useRef,
//   useContext,
// } from "react";
// // Import MessageContext if needed for feedback
// import { MessageContext } from "../../App"; // Adjust the path as necessary
// // Importing icons
// import updownIcon from "../../assets/updown_icon.png";
// import filterIcon from "../../assets/filter-icon1.svg";
// import settingIcon from "../../assets/Setting-icon.svg";
// import searchIcon from "../../assets/search-icon.svg";
// import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
// import {
//   Col,
//   Form,
//   Modal,
//   OverlayTrigger,
//   Popover,
//   Row,
//   Stack,
//   Tooltip,
// } from "react-bootstrap";
// import Paginationcontrol from "../entity/PaginationControl";

// function CatalogTable({ stateChanger }) {
//   // Inline Styles
//   function Styles() {
//     return (
//       <style>
//         {`
//           .modleHeader {
//             color: rgb(0, 0, 0);
//             font-family: "Open Sans";
//             font-weight: bold;
//             font-size: 20px;
//           }
//           .modelTitle {
//             color: #262626;
//             font-family: "Open Sans";
//             font-size: 16px;
//           }
//           .catalog-input-control {
//             width: 150px;
//           }
//           .form-check-input:checked {
//             background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path fill="%23167db0" d="M9 11.93l-3.47-3.47L4.1 10.88l5.9 5.9L20.48 6.29l-1.41-1.41L9 11.93z"/></svg>') no-repeat center;
//           }
//           .modal-button-catalog {
//             background-color:hsl(0, 0.00%, 0.00%);
//             padding: 5px 25px;
//             border-radius: 3px;
//             border: none;
//             color: white;
//             cursor: pointer;
//           }
//           .modal-button-catalog:hover {
//             background-color:hsl(180, 1.30%, 15.50%);
//           }
//           .enable-icon {
//             color: green;
//             width: 24px;
//             height: 24px;
//             cursor: pointer;
//           }
//           .disable-icon {
//             color: red;
//             width: 24px;
//             height: 24px;
//             cursor: pointer;
//           }
//           .search-input {
//             padding-left: 35px;
//           }
//           .search-icon {
//             position: absolute;
//             left: 10px;
//             top: 50%;
//             transform: translateY(-50%);
//             pointer-events: none;
//           }
//           .add-entity-button {
//             background-color:rgb(0, 0, 0);
//             color: white;
//             border: none;
//             padding: 8px 16px;
//             border-radius: 4px;
//             cursor: pointer;
//           }
//           .add-entity-button:hover {
//             background-color:rgb(0, 0, 0);
//           }
//           .highlight-row {
//             background-color: #e6f7ff;
//           }
//           .delete-column {
//             width: 100px;
//           }
//         `}
//       </style>
//     );
//   }

//   // Refs for search input etc.
//   const searchInputRef = useRef(null);
//   const entitySearchRef = useRef(null);

//   // States for managing table and modal functionality
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [selectedColumns, setSelectedColumns] = useState({
//     catalogId: true,
//     make: true,
//     deviceType: true,
//     targetFields: true,
//     sourceFields: true,
//     summaryFields: true,
//     aggregates: true,
//     deviceVersion: true,
//     deviceModel: true,
//   });
//   const [selectedFilter, setSelectedFilter] = useState("");
//   const [searchQuery, setSearchQuery] = useState("");
//   const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
//   const [tableProps, setTableProps] = useState({
//     rowsPerPage: 5,
//     currentPage: 1,
//     pageFirst: 1,
//     pageLast: 5,
//   });

//   // Modal and feature toggle states
//   const [disableBox, setDisableBox] = useState(false);
//   const [enable, setEnable] = useState(false);
//   const onMessageChange = useContext(MessageContext);
//   const [showConfirmModal, setShowConfirmModal] = useState(false);
//   const [selectedCatalog, setSelectedCatalog] = useState(null);

//   // New modal states
//   const [targetFields, setTargetFields] = useState(false);
//   const [summaryFields, setSummaryFields] = useState(false);
//   const [aggregates, setAggregates] = useState(false);
//   const [newCatalog, setNewCatalog] = useState(false);
//   // NEW: state for device version/model modal
//   const [deviceVersion, setDeviceVersion] = useState(false);

//   // State for new catalog form
//   const [newCatalogData, setNewCatalogData] = useState({
//     catalogId: "",
//     make: "",
//     deviceType: "",
//     deviceVersion: "",
//     deviceModel: "",
//   });

//   // States for target/source fields modal
//   const [targetData, setTargetData] = useState({
//     header: "",
//     field1: "",
//     field2: "",
//     field3: "",
//     field4: "",
//     field5: "",
//     field6: "",
//   });
//   const [sourceData, setSourceData] = useState({
//     header: "",
//     field1: "",
//     field2: "",
//     field3: "",
//     field4: "",
//     field5: "",
//     field6: "",
//   });
//   // State to hold header options for target header dropdown
//   const [headerOptions, setHeaderOptions] = useState([]);

//   // NEW: State for Device Version/Model modal fields
//   const [deviceData, setDeviceData] = useState({
//     deviceVersion: "",
//     deviceModel: "",
//     deprecated: false,
//   });

  

//   // NEW: States to store options for new catalog dropdowns
//   const [devices, setDevices] = useState([]);
//   const [makeData, setMakeData] = useState([]);

//   // Retrieve userData and authToken from session storage
//   const userData = JSON.parse(sessionStorage.getItem("userData"));
//   const authToken = sessionStorage.getItem("authToken");
//   const userEmail = userData?.email || "";
//   const entityId = userData?.entityId || "";

//   // State for catalog table data
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (newCatalog) {
//       const fetchCatalogId = async () => {
//         try {
//           const response = await fetch("https://solar-api.antsai.in/api/generateCatalogId", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${authToken}`,
//             },
//             // Pass the entityId if needed by your API
//             body: JSON.stringify({ entityid: entityId }),
//           });
//           if (response.ok) {
//             const data = await response.json();
//             // Ensure that the API returns a field called "catalog_id"
//             setNewCatalogData((prev) => ({
//               ...prev,
//               catalogId: data.catalog_id || "",
//             }));
//           } else {
//             console.error("Failed to generate Catalog ID, response status:", response.status);
//           }
//         } catch (error) {
//           console.error("Error generating Catalog ID:", error);
//         }
//       };
//       fetchCatalogId();
//     } else {
//       // Reset newCatalogData if modal is closed
//       setNewCatalogData({
//         catalogId: "",
//         make: "",
//         deviceType: "",
//         deviceVersion: "",
//         deviceModel: "",
//       });
//     }
//   }, [newCatalog, authToken, entityId]);

//   // Focus search input when filter changes
//   useEffect(() => {
//     if (entitySearchRef.current && selectedFilter !== "") {
//       entitySearchRef.current.focus();
//     }
//   }, [selectedFilter]);

//   // API Integration: Fetch Device Types for dropdown options
//   useEffect(() => {
//     const fetchDeviceTypes = async () => {
//       try {
//         const response = await fetch(
//           "https://solar-api.antsai.in/api/plant/getDeviceTypes",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${authToken}`,
//             },
//           }
//         );
//         if (response.ok) {
//           const respData = await response.json();
//           const formattedDevices = respData.map((item) => ({
//             device_id: item["Device Type ID"],
//             device_name: item["Device Name"],
//           }));
//           setDevices(formattedDevices);
//         } else {
//           console.error("Failed to fetch device types. Status:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching device types:", error);
//       }
//     };
//     fetchDeviceTypes();
//   }, [authToken]);

//   // API Integration: Fetch Makes for dropdown options
//   useEffect(() => {
//     const fetchMakes = async () => {
//       try {
//         const response = await fetch("https://solar-api.antsai.in/api/plant/fetchMakes", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         });
//         if (response.ok) {
//           const respData = await response.json();
//           const formattedMakes = respData.map((item) => ({
//             make_id: item.make_id,
//             make_name: item.make_name,
//           }));
//           setMakeData(formattedMakes);
//         } else {
//           console.error("Failed to fetch make data. Status:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching makes:", error);
//       }
//     };
//     fetchMakes();
//   }, [authToken]);

//   // API Integration: Fetch Catalog List (active or disabled based on disableBox)
//   useEffect(() => {
//     const fetchCatalogList = async () => {
//       try {
//         let url = "https://solar-api.antsai.in/api/fetchCatalog"; // Active catalogs
//         if (disableBox) {
//           url = "https://solar-api.antsai.in/api/fetchDisabledCatalog"; // Disabled catalogs
//         }
//         const response = await fetch(url, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//           body: JSON.stringify({ entityid: entityId }),
//         });
//         if (response.status !== 200) {
//           setData([]);
//         } else if (response.ok) {
//           const catalogData = await response.json();
//           // Map catalog data and include the hidden fields (targetHeader, sourceHeader, deprecated)
//           const filteredData = catalogData.map((item) => ({
//             catalogId: item.catalogId,
//             make: item.make,
//             deviceType: item.deviceType,
//             targetFields: item.targetFields,
//             sourceFields: item.sourceFields,
//             summaryFields: item.summaryFields,
//             aggregates: item.aggregates,
//             deviceVersion: item.deviceVersion,
//             deviceModel: item.deviceModel,
//             targetHeader: item.targetHeader,
//             sourceHeader: item.sourceHeader,
//             deprecated: item.deprecated,
//           }));
//           setData(filteredData);
//         } else {
//           console.error("Failed to fetch catalog list. Status:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching catalog list:", error);
//       }
//     };
//     fetchCatalogList();
//   }, [disableBox, authToken, entityId]);

//   // Handle sorting
//   const handleSort = (column) => {
//     let direction = "ascending";
//     if (sortConfig.key === column && sortConfig.direction === "ascending") {
//       direction = "descending";
//     }
//     setSortConfig({ key: column, direction });
//   };

//   const sortedData = useMemo(() => {
//     let sortableData = [...data];
//     if (sortConfig.key) {
//       sortableData.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sortableData;
//   }, [data, sortConfig]);

//   const filteredData = useMemo(() => {
//     return sortedData.filter((row) => {
//       if (!selectedFilter || !searchQuery) return true;
//       return row[selectedFilter]
//         .toLowerCase()
//         .startsWith(searchQuery.toLowerCase());
//     });
//   }, [sortedData, selectedFilter, searchQuery]);

//   const tablePropsChange = (updatedProps) => {
//     setTableProps(updatedProps);
//   };

//   // Function to handle row cell clicks to open modals
//   const handleCellClick = (key, row) => {
//     setSelectedCatalog(row);
//     if (key === "targetFields" || key === "sourceFields") {
//       setTargetFields(true);
//       // Pre-populate target and source modal data from the row
//       const targetFieldsArray = row.targetFields ? row.targetFields.split(",") : [];
//       const sourceFieldsArray = row.sourceFields ? row.sourceFields.split(",") : [];
//       setTargetData({
//         header: row.targetHeader || "",
//         field1: targetFieldsArray[0] || "",
//         field2: targetFieldsArray[1] || "",
//         field3: targetFieldsArray[2] || "",
//         field4: targetFieldsArray[3] || "",
//         field5: targetFieldsArray[4] || "",
//         field6: targetFieldsArray[5] || "",
//       });
//       setSourceData({
//         header: row.sourceHeader || "",
//         field1: sourceFieldsArray[0] || "",
//         field2: sourceFieldsArray[1] || "",
//         field3: sourceFieldsArray[2] || "",
//         field4: sourceFieldsArray[3] || "",
//         field5: sourceFieldsArray[4] || "",
//         field6: sourceFieldsArray[5] || "",
//       });
//     } else if (key === "deviceVersion" || key === "deviceModel") {
//       setDeviceVersion(true);
//       setDeviceData({
//         deviceVersion: row.deviceVersion || "",
//         deviceModel: row.deviceModel || "",
//         deprecated: row.deprecated === 1,
//       });
//     } else if (key === "summaryFields" || key === "aggregates") {
//       setSummaryFields(true);
//     }
//   };

//   // Handle filter selection
//   const handleFilterSelect = (column) => {
//     setSelectedFilter(column);
//     setIsDropdownOpen(false);
//     setTimeout(() => {
//       if (searchInputRef.current) {
//         searchInputRef.current.focus();
//       }
//     }, 0);
//   };

//   // Handle enabling/disabling a catalog (confirmation modal)
//   const disableRow = (e, catalog) => {
//     e.stopPropagation();
//     setEnable(false);
//     setSelectedCatalog(catalog);
//     setShowConfirmModal(true);
//   };

//   const enableRow = (e, catalog) => {
//     e.stopPropagation();
//     setEnable(true);
//     setSelectedCatalog(catalog);
//     setShowConfirmModal(true);
//   };

//   const confirmDisable = async () => {
//     try {
//       const response = await fetch("https://solar-api.antsai.in/api/disableCatalog", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
//         },
//         body: JSON.stringify({ catalogId: selectedCatalog.catalogId }),
//       });
//       if (response.ok) {
//         onMessageChange({
//           type: "Success",
//           info: "Catalog disabled successfully.",
//         });
//         setShowConfirmModal(false);
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000);
//       } else {
//         const errorData = await response.json();
//         onMessageChange({
//           type: "Danger",
//           info: `Failed to disable catalog: ${errorData.message || "Unknown error"}`,
//         });
//         setShowConfirmModal(false);
//       }
//     } catch (error) {
//       onMessageChange({
//         type: "Warning",
//         info: "An error occurred while disabling the catalog. Please try again later.",
//       });
//       setShowConfirmModal(false);
//     }
//   };

//   const confirmEnable = async () => {
//     try {
//       const response = await fetch("https://solar-api.antsai.in/api/enableCatalog", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
//         },
//         body: JSON.stringify({ catalogId: selectedCatalog.catalogId }),
//       });
//       if (response.ok) {
//         onMessageChange({
//           type: "Success",
//           info: "Catalog enabled successfully.",
//         });
//         setShowConfirmModal(false);
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000);
//       } else {
//         const errorData = await response.json();
//         onMessageChange({
//           type: "Danger",
//           info: `Failed to enable catalog: ${errorData.message || "Unknown error"}`,
//         });
//         setShowConfirmModal(false);
//       }
//     } catch (error) {
//       onMessageChange({
//         type: "Warning",
//         info: "An error occurred while enabling the catalog. Please try again later.",
//       });
//       setShowConfirmModal(false);
//     }
//   };

//   // NEW: Handle Save Device Data (Device Version/Model Modal)
//   const handleSaveDeviceData = async () => {
//     try {
//       const response = await fetch("https://solar-api.antsai.in/api/deviceData", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${authToken}`,
//         },
//         body: JSON.stringify({
//           catalogId: selectedCatalog.catalogId,
//           deviceVersion: deviceData.deviceVersion,
//           deviceModel: deviceData.deviceModel,
//           deprecated: deviceData.deprecated ? 1 : 0,
//         }),
//       });
//       if (response.ok) {
//         onMessageChange({
//           type: "Success",
//           info: "Device data saved successfully.",
//         });
//         setDeviceVersion(false);
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000);
//       } else {
//         const errorData = await response.json();
//         onMessageChange({
//           type: "Danger",
//           info: `Failed to save device data: ${errorData.message || "Unknown error."}`,
//         });
//       }
//     } catch (error) {
//       onMessageChange({
//         type: "Warning",
//         info: "An error occurred while saving device data.",
//       });
//     }
//   };

//   // API Integration: Fetch header options for target header dropdown
//   useEffect(() => {
//     const fetchHeaders = async () => {
//       try {
//         const response = await fetch("https://solar-api.antsai.in/api/fetchHeaders", {
//           method: "GET",
//         });
//         if (response.ok) {
//           const headersData = await response.json();
//           setHeaderOptions(headersData);
//         } else {
//           console.error("Failed to fetch header options.");
//         }
//       } catch (error) {
//         console.error("Error fetching header options:", error);
//       }
//     };
//     fetchHeaders();
//   }, []);

//   // API Integration: When target header is selected, fetch its fields
//   useEffect(() => {
//     if (targetData.header) {
//       const fetchFields = async () => {
//         try {
//           const response = await fetch("https://solar-api.antsai.in/api/fetchFields", {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify({ header: targetData.header }),
//           });
//           if (response.ok) {
//             const data = await response.json();
//             const fields = data.fields || [];
//             setTargetData((prev) => ({
//               ...prev,
//               field1: fields[0] || "",
//               field2: fields[1] || "",
//               field3: fields[2] || "",
//               field4: fields[3] || "",
//               field5: fields[4] || "",
//               field6: fields[5] || "",
//             }));
//           } else {
//             console.error("Failed to fetch fields for header:", targetData.header);
//           }
//         } catch (error) {
//           console.error("Error fetching fields:", error);
//         }
//       };
//       fetchFields();
//     }
//   }, [targetData.header]);

//   // Function to save both target and source fields.
//   const handleSaveFields = async () => {
//     if (!selectedCatalog) {
//       onMessageChange({
//         type: "Danger",
//         info: "No catalog selected.",
//       });
//       return;
//     }

//     const targetFieldsCSV = [
//       targetData.field1,
//       targetData.field2,
//       targetData.field3,
//       targetData.field4,
//       targetData.field5,
//       targetData.field6,
//     ]
//       .filter((field) => field)
//       .join(",");
//     const sourceFieldsCSV = [
//       sourceData.field1,
//       sourceData.field2,
//       sourceData.field3,
//       sourceData.field4,
//       sourceData.field5,
//       sourceData.field6,
//     ]
//       .filter((field) => field)
//       .join(",");

//     const payload = {
//       catalogId: selectedCatalog.catalogId,
//       targetHeader: targetData.header,
//       targetfields: targetFieldsCSV,
//       sourceHeader: sourceData.header,
//       sourceFields: sourceFieldsCSV,
//     };

//     try {
//       const response = await fetch("https://solar-api.antsai.in/api/addTargetSource", {
//         method: "POST",
//         headers: { "Content-Type": "application/json", Authorization: `Bearer ${authToken}` },
//         body: JSON.stringify(payload),
//       });
//       if (response.ok) {
//         setTargetFields(false);
//         onMessageChange({ type: "Success", info: "Fields saved successfully." });
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000);
//       } else {
//         const errorData = await response.json();
//         onMessageChange({
//           type: "Danger",
//           info: `Failed to save fields: ${errorData.message || "Unknown error."}`,
//         });
//       }
//     } catch (error) {
//       onMessageChange({ type: "Warning", info: "Error saving fields." });
//     }
//   };

//   // Pagination logic
//   const currentRows = useMemo(() => {
//     if (filteredData.length > 0) {
//       return filteredData.slice(
//         (tableProps.currentPage - 1) * tableProps.rowsPerPage,
//         (tableProps.currentPage - 1) * tableProps.rowsPerPage + tableProps.rowsPerPage
//       );
//     }
//     return [];
//   }, [filteredData, tableProps]);

//   // Handle new catalog changes
//   const handleNewCatalogChange = (key, value) => {
//     setNewCatalogData((prev) => ({
//       ...prev,
//       [key]: value,
//     }));
//   };

//   // NEW: Save New Catalog
//   const handleSaveNewCatalog = async () => {
//     try {
//       const bodyData = {
//         catalogId: newCatalogData.catalogId,
//         entityid: entityId,
//         make: newCatalogData.make,
//         deviceType: newCatalogData.deviceType,
//         deviceVersion: newCatalogData.deviceVersion,
//         deviceModel: newCatalogData.deviceModel,
//       };

//       const response = await fetch("https://solar-api.antsai.in/api/addNewCatalog", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${authToken}`,
//         },
//         body: JSON.stringify(bodyData),
//       });

//       if (response.ok) {
//         onMessageChange({
//           type: "Success",
//           info: "New Catalog added successfully.",
//         });
//         setNewCatalog(false);
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000);
//       } else {
//         const errorData = await response.json();
//         onMessageChange({
//           type: "Danger",
//           info: `Failed to add new catalog: ${errorData.message || "Unknown error"}`,
//         });
//       }
//     } catch (error) {
//       onMessageChange({
//         type: "Warning",
//         info: "An error occurred while adding the new catalog.",
//       });
//     }
//   };

//   return (
//     <div className="d-flex flex-column align-items-center justify-content-center">
//       <div className="main-container">
//         <Row className="d-flex flex-row align-items-center justify-content-between my-4">
//           <Col>
//             <span className="downloadLink">Catalog</span>
//           </Col>
//           <Col className="gap-3 d-flex flex-row align-items-center justify-content-end">
//             {/* Filter Popover */}
//             <OverlayTrigger
//               rootClose
//               trigger="click"
//               placement="left-start"
//               show={isDropdownOpen}
//               onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
//               overlay={
//                 <Popover>
//                   <Popover.Body className="overlay-body-menu">
//                     <ul>
//                       {Object.keys(selectedColumns)
//                         .filter((key) => selectedColumns[key])
//                         .map((column) => (
//                           <li key={column} onClick={() => handleFilterSelect(column)}>
//                             {column.charAt(0).toUpperCase() + column.slice(1)}
//                           </li>
//                         ))}
//                     </ul>
//                   </Popover.Body>
//                 </Popover>
//               }
//             >
//               <img
//                 src={filterIcon}
//                 alt="filter"
//                 className="filter-icon"
//                 onClick={() => setIsDropdownOpen(!isDropdownOpen)}
//               />
//             </OverlayTrigger>
//             <div style={{ position: "relative" }} className="w-auto">
//               <input
//                 ref={entitySearchRef}
//                 id="entity_search"
//                 type="text"
//                 style={{
//                   width: "341px",
//                   height: "39px",
//                   background: "#BBCCD9 0% 0% no-repeat padding-box",
//                   borderRadius: "5px",
//                   opacity: "0.51",
//                 }}
//                 className="search-input"
//                 placeholder={`Search by ${selectedFilter || "..."}`}
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 disabled={!selectedFilter}
//               />
//               <img src={searchIcon} alt="search" className="search-icon" />
//             </div>
//             <button className="add-entity-button" onClick={() => setNewCatalog(true)}>
//               Add New
//             </button>
//           </Col>
//         </Row>
//         <div className="border rounded border-light-subtle">
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   {Object.keys(selectedColumns)
//                     .filter((key) => selectedColumns[key])
//                     .map((column) => (
//                       <th key={column} onClick={() => handleSort(column)}>
//                         {column.replace(/([A-Z])/g, " $1").replace(/^\w/, (c) => c.toUpperCase())}
//                         <img src={updownIcon} alt="sort" style={{ cursor: "pointer" }} />
//                       </th>
//                     ))}
//                   <th>
//                     <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
//                       {/* Settings Popover */}
//                       <OverlayTrigger
//                         rootClose
//                         trigger="click"
//                         placement="auto-start"
//                         overlay={
//                           <Popover>
//                             <Popover.Body className="overlay-body">
//                               <div>
//                                 {Object.keys(selectedColumns).map((column) => (
//                                   <label key={column} style={{ display: "block" }}>
//                                     <input
//                                       type="checkbox"
//                                       checked={selectedColumns[column]}
//                                       onChange={() =>
//                                         setSelectedColumns((prev) => ({
//                                           ...prev,
//                                           [column]: !prev[column],
//                                         }))
//                                       }
//                                     />{" "}
//                                     {column.charAt(0).toUpperCase() + column.slice(1)}
//                                   </label>
//                                 ))}
//                                 {/* Disabled Catalog Checkbox */}
//                                 <label style={{ display: "block", marginTop: "10px" }}>
//                                   <input
//                                     type="checkbox"
//                                     checked={disableBox}
//                                     onChange={() => setDisableBox(!disableBox)}
//                                   />{" "}
//                                   Disabled Catalog
//                                 </label>
//                               </div>
//                             </Popover.Body>
//                           </Popover>
//                         }
//                       >
//                         <div>
//                           <img src={settingIcon} alt="settings" className="setting-icon" />
//                         </div>
//                       </OverlayTrigger>
//                     </div>
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//   {currentRows.length > 0 ? (
//     currentRows.map((row) => (
//       <tr key={row.catalogId}>
//         {Object.keys(selectedColumns)
//           .filter((key) => selectedColumns[key])
//           .map((column) => (
//             <td key={`${row.catalogId}-${column}`}>
//               {["targetFields", "sourceFields", "summaryFields", "aggregates"].includes(column) ? (
//                 <OverlayTrigger
//                   placement="top"
//                   overlay={
//                     <Tooltip id={`tooltip-${row.catalogId}-${column}`}>
//                       {row[column]}
//                     </Tooltip>
//                   }
//                 >
//                   <span
//                     onClick={() => handleCellClick(column, row)} // Optional: keep modal functionality if needed.
//                     style={{
//                       cursor: "pointer",
//                       textDecoration: "none",
//                       color: "#000000",
//                     }}
//                   >
//                     more...
//                   </span>
//                 </OverlayTrigger>
//               ) : (
//                 <span onClick={() => handleCellClick(column, row)}>
//                   {row[column]}
//                 </span>
//               )}
//             </td>
//           ))}
//         <td className="delete-column">
//           <div className="d-flex flex-row align-items-center justify-content-end">
//             <OverlayTrigger
//               placement="top"
//               overlay={
//                 <Tooltip id={`tooltip-${row.catalogId}`}>
//                   {disableBox
//                     ? "Click to Enable this catalog"
//                     : "Click to Disable this catalog"}
//                 </Tooltip>
//               }
//             >
//               <NotInterestedOutlinedIcon
//                 onClick={(e) =>
//                   disableBox ? enableRow(e, row) : disableRow(e, row)
//                 }
//                 style={{ cursor: "pointer" }}
//                 className={disableBox ? "enable-icon" : "disable-icon"}
//               />
//             </OverlayTrigger>
//           </div>
//         </td>
//       </tr>
//     ))
//   ) : (
//     <tr>
//       <td
//         colSpan={Object.keys(selectedColumns).length + 2}
//         style={{
//           textAlign: "center",
//           padding: "20px",
//           fontSize: "16px",
//           color: "#666",
//         }}
//       >
//         {disableBox
//           ? "No disabled data yet."
//           : "No active records found for this Catalog."}
//       </td>
//     </tr>
//   )}
// </tbody>
//             </table>
//             {filteredData.length > 0 && (
//               <Paginationcontrol
//                 rowsCount={filteredData.length}
//                 tableProps={tableProps}
//                 tablePropsChange={tablePropsChange}
//                 alwaysShown={true}
//               />
//             )}
//           </div>
//         </div>
//       </div>
//       <div>
//         <Styles />

//         {/* Confirmation Modal for Enable/Disable Catalog */}
//         <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
//           <Modal.Header closeButton>
//             <Modal.Title>{`${enable ? "Enable" : "Disable"} Catalog ?`}</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             {`Are you sure you want to ${enable ? "Enable" : "Disable"} the Catalog ${selectedCatalog?.catalogId} ?`}
//           </Modal.Body>
//           <Modal.Footer>
//             <button className="modal-button" onClick={() => setShowConfirmModal(false)} autoFocus>
//               No
//             </button>
//             <button className="modal-button" onClick={() => (enable ? confirmEnable() : confirmDisable())}>
//               Yes
//             </button>
//           </Modal.Footer>
//         </Modal>

//         {/* Target and Source Fields Modal */}
//         <Modal size="lg" show={targetFields} onHide={() => setTargetFields(false)} centered>
//           <Modal.Header closeButton>
//             <Modal.Title className="modleHeader">Target and Source fields</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <div>
//               <Row>
//                 {/* Target Fields Section */}
//                 <Col>
//                   <Stack gap={2} direction="vertical">
//                     <Row>
//                       <Col md={5}>
//                         <div className="modelTitle fw-semibold text-end pe-4">Target Fields</div>
//                       </Col>
//                       <Col></Col>
//                     </Row>
//                     {/* Target Header Dropdown */}
//                     <Form.Group controlId="targetHeader">
//                       <Row className="w-100 align-items-center d-flex">
//                         <Col md={5} className="text-end">
//                           <Form.Label className="modelTitle fw-semibold">Header :</Form.Label>
//                         </Col>
//                         <Col>
//                           <Form.Select
//                             name="targetHeader"
//                             value={targetData.header}
//                             onChange={(e) =>
//                               setTargetData((prev) => ({
//                                 ...prev,
//                                 header: e.target.value,
//                               }))
//                             }
//                           >
//                             <option value="">Select Header</option>
//                             {headerOptions.map((headerOption) => (
//                               <option key={headerOption} value={headerOption}>
//                                 {headerOption}
//                               </option>
//                             ))}
//                           </Form.Select>
//                         </Col>
//                       </Row>
//                     </Form.Group>
//                     <Form.Group>
//                       <Row className="w-100 align-items-center">
//                         <Col md={5}></Col>
//                         <Col>
//                           <div className="modelTitle fw-semibold">Reference Variable</div>
//                         </Col>
//                       </Row>
//                     </Form.Group>
//                     {/* Target Field Name Inputs */}
//                     {["field1", "field2", "field3", "field4", "field5", "field6"].map((field, index) => (
//                       <Form.Group controlId={`targetField${index + 1}`} key={field}>
//                         <Row className="w-100 align-items-center">
//                           <Col md={5} className="text-end">
//                             <Form.Label className="modelTitle fw-semibold">Field Name {index + 1}</Form.Label>
//                           </Col>
//                           <Col>
//                             <Form.Control
//                               name={`targetField${index + 1}`}
//                               placeholder="Enter Details"
//                               value={targetData[field]}
//                               onChange={(e) =>
//                                 setTargetData((prev) => ({
//                                   ...prev,
//                                   [field]: e.target.value,
//                                 }))
//                               }
//                             />
//                           </Col>
//                         </Row>
//                       </Form.Group>
//                     ))}
//                   </Stack>
//                 </Col>

//                 {/* Source Fields Section */}
//                 <Col>
//                   <Stack gap={2} direction="vertical">
//                     <Row>
//                       <Col>
//                         <div className="modelTitle fw-semibold">Source Fields</div>
//                       </Col>
//                       <Col></Col>
//                     </Row>
//                     <Form.Group controlId="sourceHeader">
//                       <Row className="w-100 align-items-center d-flex flex-row justify-content-left">
//                         <Col md={3} className="text-end">
//                           <Form.Label className="modelTitle fw-semibold">Header :</Form.Label>
//                         </Col>
//                         <Col md={6}>
//                           <Form.Control
//                             name="sourceHeader"
//                             placeholder="Enter Details"
//                             value={sourceData.header}
//                             onChange={(e) =>
//                               setSourceData((prev) => ({
//                                 ...prev,
//                                 header: e.target.value,
//                               }))
//                             }
//                           />
//                         </Col>
//                       </Row>
//                     </Form.Group>
//                     <Form.Group>
//                       <Row className="w-100">
//                         <Col>
//                           <div className="modelTitle fw-semibold">Source Field Name</div>
//                         </Col>
//                         <Col md={5}></Col>
//                       </Row>
//                     </Form.Group>
//                     {["field1", "field2", "field3", "field4", "field5", "field6"].map((field, index) => (
//                       <Form.Group controlId={`sourceField${index + 1}`} key={field}>
//                         <Row className="w-100">
//                           <Col>
//                             <Form.Control
//                               name={`sourceField${index + 1}`}
//                               placeholder="Enter Details"
//                               value={sourceData[field]}
//                               onChange={(e) =>
//                                 setSourceData((prev) => ({
//                                   ...prev,
//                                   [field]: e.target.value,
//                                 }))
//                               }
//                             />
//                           </Col>
//                           <Col md={5}></Col>
//                         </Row>
//                       </Form.Group>
//                     ))}
//                   </Stack>
//                 </Col>
//               </Row>
//             </div>
//           </Modal.Body>
//           <Modal.Footer>
//             <button className="modal-button modal-button-catalog" onClick={handleSaveFields} autoFocus>
//               SAVE
//             </button>
//           </Modal.Footer>
//         </Modal>

//                         {/* Summary and Aggregate fields Modal */}
//                        <Modal size="lg" show={summaryFields} onHide={() => setSummaryFields(false)} centered >
//                             <Modal.Header closeButton>
//                                 <Modal.Title className="modleHeader">Summary and Aggregate fields</Modal.Title>
//                             </Modal.Header>
//                             <Modal.Body>
//                                 <div>
//                                     <Row className="d-flex flex-column flex-md-row">
//                                         <Col md={4}>
//                                             <Stack gap={2} direction="vertical">
//                                                 <div><Row><Col><div className="modelTitle fw-semibold text-end pe-4">Summary</div></Col><Col></Col></Row></div>
        
//                                                 <Form.Group >
//                                                     <div className="gap-3 w-100 align-items-center d-flex ">
//                                                         <div ><div className="modelTitle fw-semibold">Field Name</div> </div>
//                                                         <div md={6}><div className="modelTitle fw-semibold">Formula</div> </div>
//                                                     </div>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <div className="gap-4 w-100 align-items-center d-flex">
//                                                         <div className="text-start text-nowrap">
//                                                             <Form.Label className="modelTitle fw-semibold">Ac Power </Form.Label>
//                                                         </div>
//                                                         <div className="ps-1">
//                                                             <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
//                                                         </div>
//                                                     </div>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <div className="gap-5 w-100 align-items-center d-flex">
//                                                         <div className="text-start text-nowrap">
//                                                             <Form.Label className="modelTitle fw-semibold ">Energy   </Form.Label>
//                                                         </div>
//                                                         <div md={6}>
//                                                             <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
//                                                         </div>
//                                                     </div>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <div className="gap-4 w-100 align-items-center d-flex">
//                                                         <div className="text-start text-nowrap">
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> DC Power   </Form.Label>
//                                                         </div>
//                                                         <div md={6}>
//                                                             <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
//                                                         </div>
//                                                     </div>
//                                                 </Form.Group>
//                                             </Stack>
//                                         </Col>
//                                         <Col >
//                                             <Stack gap={2} direction="vertical">
//                                                 <Row><Col ><div className="modelTitle fw-semibold">Aggregate</div></Col><Col></Col></Row>
        
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col > <div className="modelTitle fw-semibold">Aggregate Name</div></Col>
//                                                         <Col > <div className="modelTitle fw-semibold">Aggregate Type</div> </Col>
//                                                         <Col > <div className="modelTitle fw-semibold">Range</div> </Col >
//                                                     </Row>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col >
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Ac Power   </Form.Label>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Sum'>Sum </option>
//                                                                 <option value='Average'>Average </option>
//                                                                 <option value='Columnative Sum'>Columnative Sum </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Daily'>Daily </option>
//                                                                 <option value='Weekly'>Weekly </option>
//                                                                 <option value='Montly'>Montly </option>
//                                                                 <option value='Yearly'>Yearly </option>
//                                                                 <option value='Lifetime'>Lifetime </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                     </Row>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col >
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Energy   </Form.Label>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Sum'>Sum </option>
//                                                                 <option value='Average'>Average </option>
//                                                                 <option value='Columnative Sum'>Columnative Sum </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Daily'>Daily </option>
//                                                                 <option value='Weekly'>Weekly </option>
//                                                                 <option value='Montly'>Montly </option>
//                                                                 <option value='Yearly'>Yearly </option>
//                                                                 <option value='Lifetime'>Lifetime </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                     </Row>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col>
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Dc Power   </Form.Label>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Sum'>Sum </option>
//                                                                 <option value='Average'>Average </option>
//                                                                 <option value='Columnative Sum'>Columnative Sum </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Daily'>Daily </option>
//                                                                 <option value='Weekly'>Weekly </option>
//                                                                 <option value='Montly'>Montly </option>
//                                                                 <option value='Yearly'>Yearly </option>
//                                                                 <option value='Lifetime'>Lifetime </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                     </Row>
//                                                 </Form.Group>
//                                             </Stack>
//                                         </Col>
//                                     </Row>
//                                 </div>
//                             </Modal.Body>
//                             <Modal.Footer>
//                                 <button className="modal-button modal-button-catalog" onClick={() => { }} autoFocus >SAVE</button>
//                                 {/* <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>Yes</button> */}
//                             </Modal.Footer>
//                         </Modal>
        
//                         <Modal size="lg" show={aggregates} onHide={() => setAggregates(false)} centered >
//                             <Modal.Header closeButton>
//                                 <Modal.Title className="modleHeader">Summary and Aggregate fields</Modal.Title>
//                             </Modal.Header>
//                             <Modal.Body>
//                                 <div>
//                                     <Row className="d-flex flex-column flex-md-row">
//                                         <Col md={4}>
//                                             <Stack gap={2} direction="vertical">
//                                                 <div><Row><Col><div className="modelTitle fw-semibold text-end pe-4">Summary</div></Col><Col></Col></Row></div>
        
//                                                 <Form.Group >
//                                                     <div className="gap-3 w-100 align-items-center d-flex ">
//                                                         <div ><div className="modelTitle fw-semibold">Field Name</div> </div>
//                                                         <div md={6}><div className="modelTitle fw-semibold">Formula</div> </div>
//                                                     </div>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <div className="gap-4 w-100 align-items-center d-flex">
//                                                         <div className="text-start text-nowrap">
//                                                             <Form.Label className="modelTitle fw-semibold">Ac Power </Form.Label>
//                                                         </div>
//                                                         <div className="ps-1">
//                                                             <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
//                                                         </div>
//                                                     </div>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <div className="gap-5 w-100 align-items-center d-flex">
//                                                         <div className="text-start text-nowrap">
//                                                             <Form.Label className="modelTitle fw-semibold ">Energy   </Form.Label>
//                                                         </div>
//                                                         <div md={6}>
//                                                             <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
//                                                         </div>
//                                                     </div>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <div className="gap-4 w-100 align-items-center d-flex">
//                                                         <div className="text-start text-nowrap">
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> DC Power   </Form.Label>
//                                                         </div>
//                                                         <div md={6}>
//                                                             <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
//                                                         </div>
//                                                     </div>
//                                                 </Form.Group>
//                                             </Stack>
//                                         </Col>
//                                         <Col >
//                                             <Stack gap={2} direction="vertical">
//                                                 <Row><Col ><div className="modelTitle fw-semibold">Aggregate</div></Col><Col></Col></Row>
        
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col > <div className="modelTitle fw-semibold">Aggregate Name</div></Col>
//                                                         <Col > <div className="modelTitle fw-semibold">Aggregate Type</div> </Col>
//                                                         <Col > <div className="modelTitle fw-semibold">Range</div> </Col >
//                                                     </Row>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col >
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Ac Power   </Form.Label>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Sum'>Sum </option>
//                                                                 <option value='Average'>Average </option>
//                                                                 <option value='Columnative Sum'>Columnative Sum </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Daily'>Daily </option>
//                                                                 <option value='Weekly'>Weekly </option>
//                                                                 <option value='Montly'>Montly </option>
//                                                                 <option value='Yearly'>Yearly </option>
//                                                                 <option value='Lifetime'>Lifetime </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                     </Row>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col >
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Energy   </Form.Label>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Sum'>Sum </option>
//                                                                 <option value='Average'>Average </option>
//                                                                 <option value='Columnative Sum'>Columnative Sum </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Daily'>Daily </option>
//                                                                 <option value='Weekly'>Weekly </option>
//                                                                 <option value='Montly'>Montly </option>
//                                                                 <option value='Yearly'>Yearly </option>
//                                                                 <option value='Lifetime'>Lifetime </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                     </Row>
//                                                 </Form.Group>
//                                                 <Form.Group >
//                                                     <Row className="w-100">
//                                                         <Col>
//                                                             <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Dc Power   </Form.Label>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Sum'>Sum </option>
//                                                                 <option value='Average'>Average </option>
//                                                                 <option value='Columnative Sum'>Columnative Sum </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                         <Col >
//                                                             <Form.Select >
//                                                                 <option value='Daily'>Daily </option>
//                                                                 <option value='Weekly'>Weekly </option>
//                                                                 <option value='Montly'>Montly </option>
//                                                                 <option value='Yearly'>Yearly </option>
//                                                                 <option value='Lifetime'>Lifetime </option>
//                                                             </Form.Select>
//                                                         </Col>
//                                                     </Row>
//                                                 </Form.Group>
//                                             </Stack>
//                                         </Col>
//                                     </Row>
//                                 </div>
//                             </Modal.Body>
//                             <Modal.Footer>
//                                 <button className="modal-button modal-button-catalog" onClick={() => { }} autoFocus >SAVE</button>
//                                 {/* <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>Yes</button> */}
//                             </Modal.Footer>
//                         </Modal>

//         {/* Device Version and Model Number Modal */}
//         <Modal size="lg" show={deviceVersion} onHide={() => setDeviceVersion(false)} centered>
//           <Modal.Header closeButton>
//             <Modal.Title className="modleHeader">Device Version and Model Number</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <div>
//               <Row className="d-flex flex-column flex-md-row">
//                 <Col md={3}>
//                   <Stack gap={2} direction="vertical">
//                     <div>
//                       <Row>
//                         <Col>
//                           <div className="modelTitle fw-semibold text-end text-nowrap pe-4">Device Version</div>
//                         </Col>
//                         <Col></Col>
//                       </Row>
//                     </div>
//                     <Form.Group>
//                       <div className="gap-4 w-100 align-items-center d-flex">
//                         <div className="text-start text-nowrap">
//                           <Form.Control
//                             className="catalog-input-control"
//                             name="DeviceVersion"
//                             placeholder="Enter Details"
//                             value={deviceData.deviceVersion}
//                             onChange={(e) =>
//                               setDeviceData((prev) => ({
//                                 ...prev,
//                                 deviceVersion: e.target.value,
//                               }))
//                             }
//                           />
//                         </div>
//                       </div>
//                     </Form.Group>
//                   </Stack>
//                 </Col>
//                 <Col md={3}>
//                   <Stack gap={2} direction="vertical">
//                     <div>
//                       <Row>
//                         <Col>
//                           <div className="modelTitle fw-semibold text-end text-nowrap pe-4">Model Number</div>
//                         </Col>
//                         <Col></Col>
//                       </Row>
//                     </div>
//                     <Form.Group>
//                       <div className="gap-8 w-100 align-items-center d-flex">
//                         <div className="text-start text-nowrap">
//                           <Form.Control
//                             className="catalog-input-control"
//                             name="DeviceModel"
//                             placeholder="Enter Details"
//                             value={deviceData.deviceModel}
//                             onChange={(e) =>
//                               setDeviceData((prev) => ({
//                                 ...prev,
//                                 deviceModel: e.target.value,
//                               }))
//                             }
//                           />
//                         </div>
//                       </div>
//                     </Form.Group>
//                   </Stack>
//                 </Col>
//                 <Col md={3}>
//                   <Stack gap={2} direction="vertical">
//                     <div>
//                       <Row>
//                         <Col>
//                           <div className="modelTitle fw-semibold text-end pe-4">Deprecated</div>
//                         </Col>
//                         <Col></Col>
//                       </Row>
//                     </div>
//                     <Form.Group>
//                       <div className="gap-4 w-100 align-items-center d-flex">
//                         <div className="text-start text-nowrap">
//                           <Form.Check type="checkbox">
//                             <Form.Check.Input
//                               type="checkbox"
//                               checked={deviceData.deprecated}
//                               onChange={(e) =>
//                                 setDeviceData((prev) => ({
//                                   ...prev,
//                                   deprecated: e.target.checked,
//                                 }))
//                               }
//                             />
//                           </Form.Check>
//                         </div>
//                       </div>
//                     </Form.Group>
//                   </Stack>
//                 </Col>
//               </Row>
//             </div>
//           </Modal.Body>
//           <Modal.Footer>
//             <button className="modal-button modal-button-catalog" onClick={handleSaveDeviceData} autoFocus>
//               SAVE
//             </button>
//           </Modal.Footer>
//         </Modal>

//         {/* New Catalog Modal */}
//         <Modal size="lg" show={newCatalog} onHide={() => setNewCatalog(false)} centered>
//           <Modal.Header closeButton>
//             <Modal.Title className="modleHeader">New Catalog</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Row className="py-3"></Row>
//             <div>
//               <Stack gap={3} direction="vertical">
//                 {/* Catalog ID (read-only) */}
//                 <Form.Group>
//                   <Row className="justify-content-center">
//                     <Col md="2">
//                       <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
//                         Catalog ID :
//                       </Form.Label>
//                     </Col>
//                     <Col md="4">
//                       <Form.Control
//                         className="catalog-input-control w-100"
//                         name="CatalogId"
//                         placeholder="Loading..."
//                         value={newCatalogData.catalogId}
//                         readOnly
//                       />
//                     </Col>
//                   </Row>
//                 </Form.Group>

//                 {/* Make Dropdown */}
//                 <Form.Group>
//                   <Row className="justify-content-center">
//                     <Col md="2">
//                       <Form.Label className="modelTitle fw-semibold pe-0 me-0">
//                         Make :
//                       </Form.Label>
//                     </Col>
//                     <Col md="4">
//                       <Form.Select
//                         value={newCatalogData.make}
//                         onChange={(e) => handleNewCatalogChange("make", e.target.value)}
//                       >
//                         <option value="">Select Make</option>
//                         {makeData.map((make) => (
//                           <option key={make.make_id} value={make.make_name}>
//                             {make.make_name}
//                           </option>
//                         ))}
//                       </Form.Select>
//                     </Col>
//                   </Row>
//                 </Form.Group>

//                 {/* Device Type Dropdown */}
//                 <Form.Group>
//                   <Row className="justify-content-center">
//                     <Col md="2">
//                       <Form.Label className="modelTitle fw-semibold pe-0 me-0">
//                         Device Type :
//                       </Form.Label>
//                     </Col>
//                     <Col md="4">
//                       <Form.Select
//                         value={newCatalogData.deviceType}
//                         onChange={(e) => handleNewCatalogChange("deviceType", e.target.value)}
//                       >
//                         <option value="">Select Device Type</option>
//                         {devices.map((device) => (
//                           <option key={device.device_id} value={device.device_name}>
//                             {device.device_name}
//                           </option>
//                         ))}
//                       </Form.Select>
//                     </Col>
//                   </Row>
//                 </Form.Group>

//                 {/* Device Version */}
//                 <Form.Group>
//                   <Row className="justify-content-center">
//                     <Col md="2">
//                       <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
//                         Device Version :
//                       </Form.Label>
//                     </Col>
//                     <Col md="4">
//                       <Form.Control
//                         className="catalog-input-control w-100"
//                         name="DeviceVersion"
//                         placeholder="Version 0.0.1"
//                         value={newCatalogData.deviceVersion}
//                         onChange={(e) => handleNewCatalogChange("deviceVersion", e.target.value)}
//                       />
//                     </Col>
//                   </Row>
//                 </Form.Group>

//                 {/* Device Model */}
//                 <Form.Group>
//                   <Row className="justify-content-center">
//                     <Col md="2">
//                       <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
//                         Device Model :
//                       </Form.Label>
//                     </Col>
//                     <Col md="4">
//                       <Form.Control
//                         className="catalog-input-control w-100"
//                         name="DeviceModel"
//                         placeholder="Model Number"
//                         value={newCatalogData.deviceModel}
//                         onChange={(e) => handleNewCatalogChange("deviceModel", e.target.value)}
//                       />
//                     </Col>
//                   </Row>
//                 </Form.Group>
//                 <Row className="justify-content-center">
//                   <Col md="2"></Col>
//                   <Col md="4">
//                     <div className="d-flex justify-content-end">
//                       <button className="modal-button modal-button-catalog text-end" onClick={handleSaveNewCatalog} autoFocus>
//                         SAVE
//                       </button>
//                     </div>
//                   </Col>
//                 </Row>
//               </Stack>
//             </div>
//             <Row className="py-5"></Row>
//           </Modal.Body>
//         </Modal>
//       </div>
//     </div>
//   );
// }

// export default CatalogTable;

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useContext,
} from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MessageContext } from "../../App"; // Adjust the path as necessary
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import {
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
  Tooltip,
} from "react-bootstrap";
import Paginationcontrol from "../entity/PaginationControl";

function CatalogTable({ stateChanger }) {
  // Inline Styles
  function Styles() {
    return (
      <style>
        {`
          .modleHeader {
            color: rgb(0, 0, 0);
            font-family: "Open Sans";
            font-weight: bold;
            font-size: 20px;
          }
          .modelTitle {
            color: #262626;
            font-family: "Open Sans";
            font-size: 16px;
          }
          .catalog-input-control {
            width: 150px;
          }
          .form-check-input:checked {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path fill="%23167db0" d="M9 11.93l-3.47-3.47L4.1 10.88l5.9 5.9L20.48 6.29l-1.41-1.41L9 11.93z"/></svg>') no-repeat center;
          }
          .modal-button-catalog {
            background-color:hsl(0, 0.00%, 0.00%);
            padding: 5px 25px;
            border-radius: 3px;
            border: none;
            color: white;
            cursor: pointer;
          }
          .modal-button-catalog:hover {
            background-color:hsl(180, 1.30%, 15.50%);
          }
          .enable-icon {
            color: green;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .disable-icon {
            color: red;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .search-input {
            padding-left: 35px;
          }
          .search-icon {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
          }
          .add-entity-button {
            background-color:rgb(0, 0, 0);
            color: white;
            border: none;
            padding: 8px 16px;
            border-radius: 4px;
            cursor: pointer;
          }
          .add-entity-button:hover {
            background-color:rgb(0, 0, 0);
          }
          .highlight-row {
            background-color: #e6f7ff;
          }
          .delete-column {
            width: 100px;
          }
        `}
      </style>
    );
  }

  // Refs for search input etc.
  const searchInputRef = useRef(null);
  const entitySearchRef = useRef(null);

  // States for managing table and modal functionality
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    catalogId: true,
    make: true,
    deviceType: true,
    targetFields: true,
    sourceFields: true,
    summaryFields: true,
    aggregates: true,
    deviceVersion: true,
    deviceModel: true,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });

  // Modal and feature toggle states
  const [disableBox, setDisableBox] = useState(false);
  const [enable, setEnable] = useState(false);
  const onMessageChange = useContext(MessageContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState(null);

  // New modal states
  const [targetFields, setTargetFields] = useState(false);
  const [summaryFields, setSummaryFields] = useState(false);
  const [aggregates, setAggregates] = useState(false);
  const [newCatalog, setNewCatalog] = useState(false);
  // NEW: state for device version/model modal
  const [deviceVersion, setDeviceVersion] = useState(false);

  // State for new catalog form
  const [newCatalogData, setNewCatalogData] = useState({
    catalogId: "",
    make: "",
    deviceType: "",
    deviceVersion: "",
    deviceModel: "",
  });

  // States for target/source fields modal
  const [targetData, setTargetData] = useState({
    header: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
  });
  const [sourceData, setSourceData] = useState({
    header: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
  });
  // State to hold header options for target header dropdown
  const [headerOptions, setHeaderOptions] = useState([]);

  // NEW: State for Device Version/Model modal fields
  const [deviceData, setDeviceData] = useState({
    deviceVersion: "",
    deviceModel: "",
    deprecated: false,
  });

  // NEW: States to store options for new catalog dropdowns
  const [devices, setDevices] = useState([]);
  const [makeData, setMakeData] = useState([]);

  // Loading states for skeleton loaders
  const [loadingData, setLoadingData] = useState(true);
  const [loadingDeviceTypes, setLoadingDeviceTypes] = useState(true);
  const [loadingMakes, setLoadingMakes] = useState(true);

  // Retrieve userData and authToken from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const authToken = sessionStorage.getItem("authToken");
  const userEmail = userData?.email || "";
  const entityId = userData?.entityId || "";

  // State for catalog table data
  const [data, setData] = useState([]);

  // Generate new Catalog ID when New Catalog modal opens
  useEffect(() => {
    if (newCatalog) {
      const fetchCatalogId = async () => {
        try {
          const response = await fetch("https://solar-api.antsai.in/api/generateCatalogId", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({ entityid: entityId }),
          });
          if (response.ok) {
            const data = await response.json();
            setNewCatalogData((prev) => ({
              ...prev,
              catalogId: data.catalog_id || "",
            }));
          } else {
            console.error("Failed to generate Catalog ID, response status:", response.status);
          }
        } catch (error) {
          console.error("Error generating Catalog ID:", error);
        }
      };
      fetchCatalogId();
    } else {
      setNewCatalogData({
        catalogId: "",
        make: "",
        deviceType: "",
        deviceVersion: "",
        deviceModel: "",
      });
    }
  }, [newCatalog, authToken, entityId]);

  // Focus search input when filter changes
  useEffect(() => {
    if (entitySearchRef.current && selectedFilter !== "") {
      entitySearchRef.current.focus();
    }
  }, [selectedFilter]);

  // API Integration: Fetch Device Types for dropdown options
  useEffect(() => {
    const fetchDeviceTypes = async () => {
      setLoadingDeviceTypes(true);
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/plant/getDeviceTypes",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.ok) {
          const respData = await response.json();
          const formattedDevices = respData.map((item) => ({
            device_id: item["Device Type ID"],
            device_name: item["Device Name"],
          }));
          setDevices(formattedDevices);
        } else {
          console.error("Failed to fetch device types. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching device types:", error);
      } finally {
        setLoadingDeviceTypes(false);
      }
    };
    fetchDeviceTypes();
  }, [authToken]);

  // API Integration: Fetch Makes for dropdown options
  useEffect(() => {
    const fetchMakes = async () => {
      setLoadingMakes(true);
      try {
        const response = await fetch("https://solar-api.antsai.in/api/plant/fetchMakes", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const respData = await response.json();
          const formattedMakes = respData.map((item) => ({
            make_id: item.make_id,
            make_name: item.make_name,
          }));
          setMakeData(formattedMakes);
        } else {
          console.error("Failed to fetch make data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching makes:", error);
      } finally {
        setLoadingMakes(false);
      }
    };
    fetchMakes();
  }, [authToken]);

  // API Integration: Fetch Catalog List (active or disabled based on disableBox)
  useEffect(() => {
    const fetchCatalogList = async () => {
      setLoadingData(true);
      try {
        let url = "https://solar-api.antsai.in/api/fetchCatalog"; // Active catalogs
        if (disableBox) {
          url = "https://solar-api.antsai.in/api/fetchDisabledCatalog"; // Disabled catalogs
        }
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ entityid: entityId }),
        });
        if (response.status !== 200) {
          setData([]);
        } else if (response.ok) {
          const catalogData = await response.json();
          const filteredData = catalogData.map((item) => ({
            catalogId: item.catalogId,
            make: item.make,
            deviceType: item.deviceType,
            targetFields: item.targetFields,
            sourceFields: item.sourceFields,
            summaryFields: item.summaryFields,
            aggregates: item.aggregates,
            deviceVersion: item.deviceVersion,
            deviceModel: item.deviceModel,
            targetHeader: item.targetHeader,
            sourceHeader: item.sourceHeader,
            deprecated: item.deprecated,
          }));
          setData(filteredData);
        } else {
          console.error("Failed to fetch catalog list. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching catalog list:", error);
      } finally {
        setLoadingData(false);
      }
    };
    fetchCatalogList();
  }, [disableBox, authToken, entityId]);

  // Handle sorting
  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true;
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase());
    });
  }, [sortedData, selectedFilter, searchQuery]);

  const tablePropsChange = (updatedProps) => {
    setTableProps(updatedProps);
  };

  // Function to handle row cell clicks to open modals
  const handleCellClick = (key, row) => {
    setSelectedCatalog(row);
    if (key === "targetFields" || key === "sourceFields") {
      setTargetFields(true);
      // Pre-populate target and source modal data from the row
      const targetFieldsArray = row.targetFields ? row.targetFields.split(",") : [];
      const sourceFieldsArray = row.sourceFields ? row.sourceFields.split(",") : [];
      setTargetData({
        header: row.targetHeader || "",
        field1: targetFieldsArray[0] || "",
        field2: targetFieldsArray[1] || "",
        field3: targetFieldsArray[2] || "",
        field4: targetFieldsArray[3] || "",
        field5: targetFieldsArray[4] || "",
        field6: targetFieldsArray[5] || "",
      });
      setSourceData({
        header: row.sourceHeader || "",
        field1: sourceFieldsArray[0] || "",
        field2: sourceFieldsArray[1] || "",
        field3: sourceFieldsArray[2] || "",
        field4: sourceFieldsArray[3] || "",
        field5: sourceFieldsArray[4] || "",
        field6: sourceFieldsArray[5] || "",
      });
    } else if (key === "deviceVersion" || key === "deviceModel") {
      setDeviceVersion(true);
      setDeviceData({
        deviceVersion: row.deviceVersion || "",
        deviceModel: row.deviceModel || "",
        deprecated: row.deprecated === 1,
      });
    } else if (key === "summaryFields" || key === "aggregates") {
      setSummaryFields(true);
    }
  };

  // Handle filter selection
  const handleFilterSelect = (column) => {
    setSelectedFilter(column);
    setIsDropdownOpen(false);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 0);
  };

  // Handle enabling/disabling a catalog (confirmation modal)
  const disableRow = (e, catalog) => {
    e.stopPropagation();
    setEnable(false);
    setSelectedCatalog(catalog);
    setShowConfirmModal(true);
  };

  const enableRow = (e, catalog) => {
    e.stopPropagation();
    setEnable(true);
    setSelectedCatalog(catalog);
    setShowConfirmModal(true);
  };

  const confirmDisable = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/disableCatalog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ catalogId: selectedCatalog.catalogId }),
      });
      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "Catalog disabled successfully.",
        });
        setShowConfirmModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to disable catalog: ${errorData.message || "Unknown error"}`,
        });
        setShowConfirmModal(false);
      }
    } catch (error) {
      onMessageChange({
        type: "Warning",
        info: "An error occurred while disabling the catalog. Please try again later.",
      });
      setShowConfirmModal(false);
    }
  };

  const confirmEnable = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/enableCatalog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ catalogId: selectedCatalog.catalogId }),
      });
      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "Catalog enabled successfully.",
        });
        setShowConfirmModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to enable catalog: ${errorData.message || "Unknown error"}`,
        });
        setShowConfirmModal(false);
      }
    } catch (error) {
      onMessageChange({
        type: "Warning",
        info: "An error occurred while enabling the catalog. Please try again later.",
      });
      setShowConfirmModal(false);
    }
  };

  // NEW: Handle Save Device Data (Device Version/Model Modal)
  const handleSaveDeviceData = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/deviceData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          catalogId: selectedCatalog.catalogId,
          deviceVersion: deviceData.deviceVersion,
          deviceModel: deviceData.deviceModel,
          deprecated: deviceData.deprecated ? 1 : 0,
        }),
      });
      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "Device data saved successfully.",
        });
        setDeviceVersion(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to save device data: ${errorData.message || "Unknown error."}`,
        });
      }
    } catch (error) {
      onMessageChange({
        type: "Warning",
        info: "An error occurred while saving device data.",
      });
    }
  };

  // API Integration: Fetch header options for target header dropdown
  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const response = await fetch("https://solar-api.antsai.in/api/fetchHeaders", {
          method: "GET",
        });
        if (response.ok) {
          const headersData = await response.json();
          setHeaderOptions(headersData);
        } else {
          console.error("Failed to fetch header options.");
        }
      } catch (error) {
        console.error("Error fetching header options:", error);
      }
    };
    fetchHeaders();
  }, []);

  // API Integration: When target header is selected, fetch its fields
  useEffect(() => {
    if (targetData.header) {
      const fetchFields = async () => {
        try {
          const response = await fetch("https://solar-api.antsai.in/api/fetchFields", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ header: targetData.header }),
          });
          if (response.ok) {
            const data = await response.json();
            const fields = data.fields || [];
            setTargetData((prev) => ({
              ...prev,
              field1: fields[0] || "",
              field2: fields[1] || "",
              field3: fields[2] || "",
              field4: fields[3] || "",
              field5: fields[4] || "",
              field6: fields[5] || "",
            }));
          } else {
            console.error("Failed to fetch fields for header:", targetData.header);
          }
        } catch (error) {
          console.error("Error fetching fields:", error);
        }
      };
      fetchFields();
    }
  }, [targetData.header]);

  // Function to save both target and source fields.
  const handleSaveFields = async () => {
    if (!selectedCatalog) {
      onMessageChange({
        type: "Danger",
        info: "No catalog selected.",
      });
      return;
    }

    const targetFieldsCSV = [
      targetData.field1,
      targetData.field2,
      targetData.field3,
      targetData.field4,
      targetData.field5,
      targetData.field6,
    ]
      .filter((field) => field)
      .join(",");
    const sourceFieldsCSV = [
      sourceData.field1,
      sourceData.field2,
      sourceData.field3,
      sourceData.field4,
      sourceData.field5,
      sourceData.field6,
    ]
      .filter((field) => field)
      .join(",");

    const payload = {
      catalogId: selectedCatalog.catalogId,
      targetHeader: targetData.header,
      targetfields: targetFieldsCSV,
      sourceHeader: sourceData.header,
      sourceFields: sourceFieldsCSV,
    };

    try {
      const response = await fetch("https://solar-api.antsai.in/api/addTargetSource", {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${authToken}` },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        setTargetFields(false);
        onMessageChange({ type: "Success", info: "Fields saved successfully." });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to save fields: ${errorData.message || "Unknown error."}`,
        });
      }
    } catch (error) {
      onMessageChange({ type: "Warning", info: "Error saving fields." });
    }
  };

  // Pagination logic
  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(
        (tableProps.currentPage - 1) * tableProps.rowsPerPage,
        (tableProps.currentPage - 1) * tableProps.rowsPerPage + tableProps.rowsPerPage
      );
    }
    return [];
  }, [filteredData, tableProps]);

  // Handle new catalog changes
  const handleNewCatalogChange = (key, value) => {
    setNewCatalogData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // NEW: Save New Catalog
  const handleSaveNewCatalog = async () => {
    try {
      const bodyData = {
        catalogId: newCatalogData.catalogId,
        entityid: entityId,
        make: newCatalogData.make,
        deviceType: newCatalogData.deviceType,
        deviceVersion: newCatalogData.deviceVersion,
        deviceModel: newCatalogData.deviceModel,
      };

      const response = await fetch("https://solar-api.antsai.in/api/addNewCatalog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "New Catalog added successfully.",
        });
        setNewCatalog(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to add new catalog: ${errorData.message || "Unknown error"}`,
        });
      }
    } catch (error) {
      onMessageChange({
        type: "Warning",
        info: "An error occurred while adding the new catalog.",
      });
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">
          <Col>
            <span className="downloadLink">Catalog</span>
          </Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end">
            {/* Filter Popover */}
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              show={isDropdownOpen}
              onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li key={column} onClick={() => handleFilterSelect(column)}>
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img
                src={filterIcon}
                alt="filter"
                className="filter-icon"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              />
            </OverlayTrigger>
            <div style={{ position: "relative" }} className="w-auto">
              <input
                ref={entitySearchRef}
                id="entity_search"
                type="text"
                style={{
                  width: "341px",
                  height: "39px",
                  background: "#BBCCD9 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  opacity: "0.51",
                }}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
            <button className="add-entity-button" onClick={() => setNewCatalog(true)}>
              Add New
            </button>
          </Col>
        </Row>
        <div className="border rounded border-light-subtle">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^\w/, (c) => c.toUpperCase())}
                        <img src={updownIcon} alt="sort" style={{ cursor: "pointer" }} />
                      </th>
                    ))}
                  <th>
                    <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                      {/* Settings Popover */}
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="auto-start"
                        overlay={
                          <Popover>
                            <Popover.Body className="overlay-body">
                              <div>
                                {Object.keys(selectedColumns).map((column) => (
                                  <label key={column} style={{ display: "block" }}>
                                    <input
                                      type="checkbox"
                                      checked={selectedColumns[column]}
                                      onChange={() =>
                                        setSelectedColumns((prev) => ({
                                          ...prev,
                                          [column]: !prev[column],
                                        }))
                                      }
                                    />{" "}
                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                  </label>
                                ))}
                                {/* Disabled Catalog Checkbox */}
                                <label style={{ display: "block", marginTop: "10px" }}>
                                  <input
                                    type="checkbox"
                                    checked={disableBox}
                                    onChange={() => setDisableBox(!disableBox)}
                                  />{" "}
                                  Disabled Catalog
                                </label>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <img src={settingIcon} alt="settings" className="setting-icon" />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  Array.from({ length: tableProps.rowsPerPage }).map((_, index) => (
                    <tr key={`skeleton-${index}`}>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <td key={`skeleton-${index}-${column}`}>
                            <Skeleton height={20} />
                          </td>
                        ))}
                      <td className="delete-column">
                        <Skeleton circle={true} height={24} width={24} />
                      </td>
                    </tr>
                  ))
                ) : currentRows.length > 0 ? (
                  currentRows.map((row) => (
                    <tr key={row.catalogId}>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <td key={`${row.catalogId}-${column}`}>
                            {["targetFields", "sourceFields", "summaryFields", "aggregates"].includes(
                              column
                            ) ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${row.catalogId}-${column}`}>
                                    {row[column]}
                                  </Tooltip>
                                }
                              >
                                <span
                                  onClick={() => handleCellClick(column, row)}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontFamily:"'Roboto', sans-serif",
                                    color: "#1a0dab",
                                  }}
                                >
                                  more...
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <span onClick={() => handleCellClick(column, row)}>
                                {row[column]}
                              </span>
                            )}
                          </td>
                        ))}
                      <td className="delete-column">
                        <div className="d-flex flex-row align-items-center justify-content-end">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${row.catalogId}`}>
                                {disableBox
                                  ? "Click to Enable this catalog"
                                  : "Click to Disable this catalog"}
                              </Tooltip>
                            }
                          >
                            <NotInterestedOutlinedIcon
                              onClick={(e) =>
                                disableBox ? enableRow(e, row) : disableRow(e, row)
                              }
                              style={{ cursor: "pointer" }}
                              className={disableBox ? "enable-icon" : "disable-icon"}
                            />
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={Object.keys(selectedColumns).length + 2}
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "16px",
                        color: "#666",
                      }}
                    >
                      {disableBox
                        ? "No disabled data yet."
                        : "No active records found for this Catalog."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {filteredData.length > 0 && (
              <Paginationcontrol
                rowsCount={filteredData.length}
                tableProps={tableProps}
                tablePropsChange={tablePropsChange}
                alwaysShown={true}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <Styles />

        {/* Confirmation Modal for Enable/Disable Catalog */}
        <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{`${enable ? "Enable" : "Disable"} Catalog ?`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {`Are you sure you want to ${enable ? "Enable" : "Disable"} the Catalog ${selectedCatalog?.catalogId} ?`}
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-button" onClick={() => setShowConfirmModal(false)} autoFocus>
              No
            </button>
            <button className="modal-button" onClick={() => (enable ? confirmEnable() : confirmDisable())}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>

        {/* Target and Source Fields Modal */}
        <Modal size="lg" show={targetFields} onHide={() => setTargetFields(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modleHeader">Target and Source fields</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                {/* Target Fields Section */}
                <Col>
                  <Stack gap={2} direction="vertical">
                    <Row>
                      <Col md={5}>
                        <div className="modelTitle fw-semibold text-end pe-4">Target Fields</div>
                      </Col>
                      <Col></Col>
                    </Row>
                    {/* Target Header Dropdown */}
                    <Form.Group controlId="targetHeader">
                      <Row className="w-100 align-items-center d-flex">
                        <Col md={5} className="text-end">
                          <Form.Label className="modelTitle fw-semibold">Header :</Form.Label>
                        </Col>
                        <Col>
                          {headerOptions.length === 0 ? (
                            <Skeleton height={38} />
                          ) : (
                            <Form.Select
                              name="targetHeader"
                              value={targetData.header}
                              onChange={(e) =>
                                setTargetData((prev) => ({
                                  ...prev,
                                  header: e.target.value,
                                }))
                              }
                            >
                              <option value="">Select Header</option>
                              {headerOptions.map((headerOption) => (
                                <option key={headerOption} value={headerOption}>
                                  {headerOption}
                                </option>
                              ))}
                            </Form.Select>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100 align-items-center">
                        <Col md={5}></Col>
                        <Col>
                          <div className="modelTitle fw-semibold">Reference Variable</div>
                        </Col>
                      </Row>
                    </Form.Group>
                    {/* Target Field Name Inputs */}
                    {["field1", "field2", "field3", "field4", "field5", "field6"].map((field, index) => (
                      <Form.Group controlId={`targetField${index + 1}`} key={field}>
                        <Row className="w-100 align-items-center">
                          <Col md={5} className="text-end">
                            <Form.Label className="modelTitle fw-semibold">Field Name {index + 1}</Form.Label>
                          </Col>
                          <Col>
                            <Form.Control
                              name={`targetField${index + 1}`}
                              placeholder="Enter Details"
                              value={targetData[field]}
                              onChange={(e) =>
                                setTargetData((prev) => ({
                                  ...prev,
                                  [field]: e.target.value,
                                }))
                              }
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    ))}
                  </Stack>
                </Col>

                {/* Source Fields Section */}
                <Col>
                  <Stack gap={2} direction="vertical">
                    <Row>
                      <Col>
                        <div className="modelTitle fw-semibold">Source Fields</div>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Form.Group controlId="sourceHeader">
                      <Row className="w-100 align-items-center d-flex flex-row justify-content-left">
                        <Col md={3} className="text-end">
                          <Form.Label className="modelTitle fw-semibold">Header :</Form.Label>
                        </Col>
                        <Col md={6}>
                          <Form.Control
                            name="sourceHeader"
                            placeholder="Enter Details"
                            value={sourceData.header}
                            onChange={(e) =>
                              setSourceData((prev) => ({
                                ...prev,
                                header: e.target.value,
                              }))
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <div className="modelTitle fw-semibold">Source Field Name</div>
                        </Col>
                        <Col md={5}></Col>
                      </Row>
                    </Form.Group>
                    {["field1", "field2", "field3", "field4", "field5", "field6"].map((field, index) => (
                      <Form.Group controlId={`sourceField${index + 1}`} key={field}>
                        <Row className="w-100">
                          <Col>
                            <Form.Control
                              name={`sourceField${index + 1}`}
                              placeholder="Enter Details"
                              value={sourceData[field]}
                              onChange={(e) =>
                                setSourceData((prev) => ({
                                  ...prev,
                                  [field]: e.target.value,
                                }))
                              }
                            />
                          </Col>
                          <Col md={5}></Col>
                        </Row>
                      </Form.Group>
                    ))}
                  </Stack>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-button modal-button-catalog" onClick={handleSaveFields} autoFocus>
              SAVE
            </button>
          </Modal.Footer>
        </Modal>

        {/* Summary and Aggregate fields Modal */}
        <Modal size="lg" show={summaryFields} onHide={() => setSummaryFields(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modleHeader">Summary and Aggregate fields</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row className="d-flex flex-column flex-md-row">
                <Col md={4}>
                  <Stack gap={2} direction="vertical">
                    <div>
                      <Row>
                        <Col>
                          <div className="modelTitle fw-semibold text-end pe-4">Summary</div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                    <Form.Group>
                      <div className="gap-3 w-100 align-items-center d-flex ">
                        <div>
                          <div className="modelTitle fw-semibold">Field Name</div>
                        </div>
                        <div md={6}>
                          <div className="modelTitle fw-semibold">Formula</div>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="gap-4 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Label className="modelTitle fw-semibold">Ac Power </Form.Label>
                        </div>
                        <div className="ps-1">
                          <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="gap-5 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Label className="modelTitle fw-semibold ">Energy   </Form.Label>
                        </div>
                        <div md={6}>
                          <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="gap-4 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> DC Power   </Form.Label>
                        </div>
                        <div md={6}>
                          <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                        </div>
                      </div>
                    </Form.Group>
                  </Stack>
                </Col>
                <Col>
                  <Stack gap={2} direction="vertical">
                    <Row>
                      <Col>
                        <div className="modelTitle fw-semibold">Aggregate</div>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <div className="modelTitle fw-semibold">Aggregate Name</div>
                        </Col>
                        <Col>
                          <div className="modelTitle fw-semibold">Aggregate Type</div>
                        </Col>
                        <Col>
                          <div className="modelTitle fw-semibold">Range</div>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Ac Power   </Form.Label>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Sum">Sum </option>
                            <option value="Average">Average</option>
                            <option value="Columnative Sum">Columnative Sum</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Montly">Montly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Lifetime">Lifetime</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Energy   </Form.Label>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Sum">Sum </option>
                            <option value="Average">Average</option>
                            <option value="Columnative Sum">Columnative Sum</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Montly">Montly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Lifetime">Lifetime</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Dc Power   </Form.Label>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Sum">Sum </option>
                            <option value="Average">Average</option>
                            <option value="Columnative Sum">Columnative Sum</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Montly">Montly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Lifetime">Lifetime</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-button modal-button-catalog" onClick={() => {}} autoFocus>
              SAVE
            </button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={aggregates} onHide={() => setAggregates(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modleHeader">Summary and Aggregate fields</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row className="d-flex flex-column flex-md-row">
                <Col md={4}>
                  <Stack gap={2} direction="vertical">
                    <div>
                      <Row>
                        <Col>
                          <div className="modelTitle fw-semibold text-end pe-4">Summary</div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                    <Form.Group>
                      <div className="gap-3 w-100 align-items-center d-flex ">
                        <div>
                          <div className="modelTitle fw-semibold">Field Name</div>
                        </div>
                        <div md={6}>
                          <div className="modelTitle fw-semibold">Formula</div>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="gap-4 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Label className="modelTitle fw-semibold">Ac Power </Form.Label>
                        </div>
                        <div className="ps-1">
                          <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="gap-5 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Label className="modelTitle fw-semibold ">Energy   </Form.Label>
                        </div>
                        <div md={6}>
                          <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="gap-4 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> DC Power   </Form.Label>
                        </div>
                        <div md={6}>
                          <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                        </div>
                      </div>
                    </Form.Group>
                  </Stack>
                </Col>
                <Col>
                  <Stack gap={2} direction="vertical">
                    <Row>
                      <Col>
                        <div className="modelTitle fw-semibold">Aggregate</div>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <div className="modelTitle fw-semibold">Aggregate Name</div>
                        </Col>
                        <Col>
                          <div className="modelTitle fw-semibold">Aggregate Type</div>
                        </Col>
                        <Col>
                          <div className="modelTitle fw-semibold">Range</div>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Ac Power   </Form.Label>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Sum">Sum </option>
                            <option value="Average">Average</option>
                            <option value="Columnative Sum">Columnative Sum</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Montly">Montly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Lifetime">Lifetime</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Energy   </Form.Label>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Sum">Sum </option>
                            <option value="Average">Average</option>
                            <option value="Columnative Sum">Columnative Sum</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Montly">Montly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Lifetime">Lifetime</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="w-100">
                        <Col>
                          <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Dc Power   </Form.Label>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Sum">Sum </option>
                            <option value="Average">Average</option>
                            <option value="Columnative Sum">Columnative Sum</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Montly">Montly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Lifetime">Lifetime</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-button modal-button-catalog" onClick={() => {}} autoFocus>
              SAVE
            </button>
          </Modal.Footer>
        </Modal>

        {/* Device Version and Model Number Modal */}
        <Modal size="lg" show={deviceVersion} onHide={() => setDeviceVersion(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modleHeader">Device Version and Model Number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row className="d-flex flex-column flex-md-row">
                <Col md={3}>
                  <Stack gap={2} direction="vertical">
                    <div>
                      <Row>
                        <Col>
                          <div className="modelTitle fw-semibold text-end text-nowrap pe-4">Device Version</div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                    <Form.Group>
                      <div className="gap-4 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Control
                            className="catalog-input-control"
                            name="DeviceVersion"
                            placeholder="Enter Details"
                            value={deviceData.deviceVersion}
                            onChange={(e) =>
                              setDeviceData((prev) => ({
                                ...prev,
                                deviceVersion: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Stack>
                </Col>
                <Col md={3}>
                  <Stack gap={2} direction="vertical">
                    <div>
                      <Row>
                        <Col>
                          <div className="modelTitle fw-semibold text-end text-nowrap pe-4">Model Number</div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                    <Form.Group>
                      <div className="gap-8 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Control
                            className="catalog-input-control"
                            name="DeviceModel"
                            placeholder="Enter Details"
                            value={deviceData.deviceModel}
                            onChange={(e) =>
                              setDeviceData((prev) => ({
                                ...prev,
                                deviceModel: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Stack>
                </Col>
                <Col md={3}>
                  <Stack gap={2} direction="vertical">
                    <div>
                      <Row>
                        <Col>
                          <div className="modelTitle fw-semibold text-end pe-4">Deprecated</div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                    <Form.Group>
                      <div className="gap-4 w-100 align-items-center d-flex">
                        <div className="text-start text-nowrap">
                          <Form.Check type="checkbox">
                            <Form.Check.Input
                              type="checkbox"
                              checked={deviceData.deprecated}
                              onChange={(e) =>
                                setDeviceData((prev) => ({
                                  ...prev,
                                  deprecated: e.target.checked,
                                }))
                              }
                            />
                          </Form.Check>
                        </div>
                      </div>
                    </Form.Group>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-button modal-button-catalog" onClick={handleSaveDeviceData} autoFocus>
              SAVE
            </button>
          </Modal.Footer>
        </Modal>

        {/* New Catalog Modal */}
        <Modal size="lg" show={newCatalog} onHide={() => setNewCatalog(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modleHeader">New Catalog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="py-3"></Row>
            <div>
              <Stack gap={3} direction="vertical">
                {/* Catalog ID (read-only) */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
                        Catalog ID :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      {newCatalogData.catalogId ? (
                        <Form.Control
                          className="catalog-input-control w-100"
                          name="CatalogId"
                          placeholder="Loading..."
                          value={newCatalogData.catalogId}
                          readOnly
                        />
                      ) : (
                        <Skeleton height={38} />
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                {/* Make Dropdown */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                        Make :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      {loadingMakes ? (
                        <Skeleton height={38} />
                      ) : (
                        <Form.Select
                          value={newCatalogData.make}
                          onChange={(e) => handleNewCatalogChange("make", e.target.value)}
                        >
                          <option value="">Select Make</option>
                          {makeData.map((make) => (
                            <option key={make.make_id} value={make.make_name}>
                              {make.make_name}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                {/* Device Type Dropdown */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                        Device Type :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      {loadingDeviceTypes ? (
                        <Skeleton height={38} />
                      ) : (
                        <Form.Select
                          value={newCatalogData.deviceType}
                          onChange={(e) => handleNewCatalogChange("deviceType", e.target.value)}
                        >
                          <option value="">Select Device Type</option>
                          {devices.map((device) => (
                            <option key={device.device_id} value={device.device_name}>
                              {device.device_name}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                {/* Device Version */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
                        Device Version :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Control
                        className="catalog-input-control w-100"
                        name="DeviceVersion"
                        placeholder="Version 0.0.1"
                        value={newCatalogData.deviceVersion}
                        onChange={(e) => handleNewCatalogChange("deviceVersion", e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                {/* Device Model */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
                        Device Model :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Control
                        className="catalog-input-control w-100"
                        name="DeviceModel"
                        placeholder="Model Number"
                        value={newCatalogData.deviceModel}
                        onChange={(e) => handleNewCatalogChange("deviceModel", e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Row className="justify-content-center">
                  <Col md="2"></Col>
                  <Col md="4">
                    <div className="d-flex justify-content-end">
                      <button className="modal-button modal-button-catalog text-end" onClick={handleSaveNewCatalog} autoFocus>
                        SAVE
                      </button>
                    </div>
                  </Col>
                </Row>
              </Stack>
            </div>
            <Row className="py-5"></Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default CatalogTable;
