import { useState, useEffect } from "react";
import { Button, Col, Form, Row, Stack, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import editIcon from "../../assets/edit-icon.png"; // Import the edit icon
import deleteIcon from "../../assets/delete_icon.png"; // Import delete icon
import addnewicon from "../../assets/plus.png";

function DeviceDetails({ plantId, setDeviceMore, fetchedDevices }) {
  const [deviceList, setDeviceList] = useState(fetchedDevices || []); // Initialize with fetchedDevices
  const [devices, setDevices] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [formData, setFormData] = useState({
    Device_id: "",
    Plant_id: plantId,
    Device_type: "",
    Make: "",
    Rating: "",
    Quantity: "",
    Serial_Nos: "", // Use consistent naming
    Model: "", // Added Model
    "System_date-time": "",
  });
  const [editDevice, setEditDevice] = useState(undefined);
  const [isAdding, setIsAdding] = useState(false);
  
  function handleChange(event) {
    const { name, value } = event.currentTarget;
    if (name === "Serial_Nos") {
      const formattedValue = value.replace(/\s+/g, ";").replace(/;;+/g, ";"); // Replace spaces with semicolons and handle extra semicolons
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  useEffect(() => {
    if (fetchedDevices) {
      // Combine manually added devices with fetched devices, avoiding duplicates
      const combinedDevices = [
        ...deviceList, // Include existing devices (manually added)
        ...fetchedDevices.filter(
          (newDevice) =>
            !deviceList.some(
              (existingDevice) =>
                existingDevice.Device_id === newDevice.Device_id
            )
        ), // Add only new devices that are not already in the list
      ];

      setDeviceList(combinedDevices); // Update the deviceList with merged data
    }
  }, [fetchedDevices]);

  const columns = [
    { key: "Device_type", header: "Device Type" },
    { key: "Model", header: "Plant Serial No." }, // Add Model column here
    { key: "Make", header: "Make" },
    { key: "Rating", header: "Rating" },
    { key: "Quantity", header: "Quantity" },
    { key: "Serial_Nos", header: "Serial Nos" }, // Ensure consistent key here
  ];

  //   const [deviceList, setDeviceList] = useState([]);

  // Fetch Device Types from API
  useEffect(() => {
    const fetchDeviceTypes = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/plant/getDeviceTypes",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const formattedDevices = data.map((item) => ({
            device_id: item["Device Type ID"],
            device_name: item["Device Name"],
          }));
          setDevices(formattedDevices);
        } else {
          const errorData = await response.json();
          console.error(
            "Device Types Fetch Failed:",
            response.status,
            errorData
          );
        }
      } catch (error) {
        console.error("Error Fetching Device Types:", error.message);
      }
    };

    fetchDeviceTypes();
  }, []);

  useEffect(() => {
    const fetchMakes = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/plant/fetchMakes",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const formattedMakes = data.map((item) => ({
            make_id: item["make_id"],
            make_name: item["make_name"],
          }));
          setMakeData(formattedMakes);
        } else {
          const errorData = await response.json();
          console.error("Makes Fetch Failed:", response.status, errorData);
        }
      } catch (error) {
        console.error("Error Fetching Makes:", error.message);
      }
    };

    fetchMakes();
  }, []);

  // function handleChange(event) {
  //   const { name, value } = event.currentTarget;
  //   setFormData({ ...formData, [name]: value });

  //   // For Serial Nos field, format the value
  //   if (name === "Serial Nos") {
  //     const formattedValue = value.replace(/\s+/g, ";").replace(/;;+/g, ";"); // Replace spaces with commas and handle extra commas
  //     setFormData({ ...formData, [name]: formattedValue });
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // }

  const handleDeleteRow = (rowId) => {
    setDeviceList([...deviceList.filter((r) => r.Device_id !== rowId)]);
  };

  const onAdd = () => {
    if (editDevice !== undefined) {
      update(); // Call the update function if editing an existing device
    } else {
      const newDevice = {
        ...formData,
        Device_id: `device_${deviceList.length + 1}`, // Generate a unique ID
      };
      setDeviceList([...deviceList, newDevice]); // Add the new device to the list
      updateFormEmptyData(); // Clear the form
    }
  };

  const update = () => {
    if (editDevice) {
      const updatedDeviceList = deviceList.map(
        (device) =>
          device.Device_id === editDevice.Device_id
            ? { ...device, ...formData } // Update only the matching row
            : device // Leave all other rows unchanged
      );
      setDeviceList(updatedDeviceList); // Update the state with the modified device list
      setEditDevice(undefined); // Clear the edit state
      updateFormEmptyData(); // Reset the form
    }
  };

  function updateFormEmptyData() {
    setFormData({
      Device_id: "",
      Plant_id: plantId,
      Device_type: "",
      Make: "",
      Rating: "",
      Quantity: "",
      Model: "", // Reset Model field
      Serial_Nos: "", // Reset Serial_Nos field
      "System_date-time": "",
    });
  }

  const handleRowSelection = (rowId) => {
    const selectedDevice = deviceList.find((r) => r.Device_id === rowId);
    if (selectedDevice) {
      const deviceType = devices.find(
        (device) => device.device_name === selectedDevice.Device_type
      );
      const make = makeData.find((m) => m.make_name === selectedDevice.Make);

      setFormData({
        ...selectedDevice,
        Device_type: deviceType
          ? deviceType.device_name
          : selectedDevice.Device_type, // Safely map Device Type
        Make: make ? make.make_name : selectedDevice.Make, // Safely map Make
        Model: selectedDevice.Model || "", // Correctly map Model
        Serial_Nos: selectedDevice.Serial_Nos || "", // Correctly map Serial_Nos
      });

      setEditDevice(selectedDevice);
    } else {
      console.error("Row not found with Device_id:", rowId);
    }
    console.log("Row ID Clicked:", rowId);
    console.log("Device List on Selection:", deviceList);
  };

  const handleAddNew = () => {
    setEditDevice(undefined);
    setIsAdding(true);
    updateFormEmptyData();
  };

  const handleSave = () => {
    if (editDevice) {
      update();
    } else {
      const newDevice = {
        ...formData,
        Device_id: `device_${deviceList.length + 1}`,
      };
      setDeviceList([...deviceList, newDevice]);
      updateFormEmptyData();
    }
    setIsAdding(false);
  };

  const handleCancel = () => {
    setEditDevice(undefined);
    setIsAdding(false);
    updateFormEmptyData();
  };
  return (
    <>
      <Row className="w-100 d-flex align-items-center justify-content-between mb-3">
        <Col>
          {/* <Button variant="primary" onClick={handleAddNew}>
            Add New Device
          </Button> */}
         <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img 
            src={addnewicon} 
            alt="Add New Device"
            onClick={handleAddNew}
            style={{ cursor: 'pointer', width: '40px', height: '40px' }}
          />
          <span>Add New Device</span>
          </div>
        </Col>
        <Col className="text-end">
          <button onClick={() => setDeviceMore(false)}>
            <CloseTwoToneIcon />
          </button>
        </Col>
      </Row>
      <Stack gap={3} direction="vertical">
        <Form.Group className="w-100" controlId="other_device">
          <Row className="w-100 d-flex flex-row align-items-center justify-content-left">
            <Col md={2}>
              <Form.Label> Device Type : </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Select
                name="Device_type"
                value={formData.Device_type}
                onChange={(e) => handleChange(e)}
                size="md"
                disabled={!isAdding && !editDevice}
              >
                <option value="">Select Device</option>
                {devices.map((dev) => (
                  <option key={dev.device_id} value={dev.device_name}>
                    {dev.device_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="Model" className="w-100">
          <Row className="w-100">
            <Col md={2}>
              <Form.Label className="text-nowrap">
                Plant Serial No. <span>:</span>
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Control
                name="Model"
                value={formData.Model}
                onChange={(e) => handleChange(e)} // Handle changes to the Model field
                type="text" // Input type, change to "select" if needed
                placeholder="Enter Model"
                disabled={!isAdding && !editDevice}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="w-100" controlId="Make">
          <Row className="w-100 d-flex flex-row align-items-center justify-content-left">
            <Col md={2}>
              <Form.Label> Make : </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Select
                name="Make"
                value={formData.Make}
                onChange={(e) => handleChange(e)}
                size="md"
                disabled={!isAdding && !editDevice}
              >
                <option value="">Select Make</option>
                {makeData.map((make) => (
                  <option key={make.make_id} value={make.make_name}>
                    {make.make_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="Rating" className="w-100">
          <Row className="w-100">
            <Col md={2}>
              <Form.Label className="text-nowrap">
                {" "}
                Rating <span>:</span>{" "}
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Control
                name="Rating"
                value={formData.Rating}
                onChange={(e) => handleChange(e)}
                type="string"
                disabled={!isAdding && !editDevice}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="Quantity" className="w-100">
          <Row className="w-100">
            <Col md={2}>
              <Form.Label className="text-nowrap">
                {" "}
                Quantity <span>:</span>
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Control
                name="Quantity"
                value={formData.Quantity}
                min={0}
                max={3000}
                onChange={(e) => handleChange(e)}
                type="number"
                disabled={!isAdding && !editDevice}
              />
            </Col>
          </Row>
        </Form.Group>
        {/* <Form.Group controlId="Serial Nos" className="w-100">
          <Row className="w-100">
            <Col md={2}>
              <Form.Label className="text-nowrap">
                {" "}
                Serial Nos <span>:</span>
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Control
                name="Serial Nos"
                value={formData["Serial_Nos"]}
                maxLength={255}
                onChange={(e) => handleChange(e)} 
                type="textarea"
                disabled={!isAdding && !editDevice}
              />
            </Col>
          </Row>
        </Form.Group> */}
        <Form.Group controlId="Serial_Nos" className="w-100">
          <Row className="w-100">
            <Col md={2}>
              <Form.Label className="text-nowrap">
                Serial Nos <span>:</span>
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Control
                name="Serial_Nos"
                value={formData.Serial_Nos}
                maxLength={255}
                onChange={(e) => handleChange(e)} // Updated to handle serial number formatting
                type="textarea"
                disabled={!isAdding && !editDevice}
              />
            </Col>
          </Row>
        </Form.Group>
        {(isAdding || editDevice) && (
          <Row className="w-100 mt-4">
            <div className="w-100 d-flex flex-row align-items-center justify-content-end gap-2">
              <Button
               variant="primary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSave}
              >
                {editDevice ? "Update" : "Save"}
              </Button>
            </div>
          </Row>
        )}
      </Stack>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.header}</th>
            ))}
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {deviceList.map((row) => (
            <tr key={row.Device_id}>
              {columns.map((column) => (
                <td key={column.key}>{row[column.key]}</td>
              ))}
              <td>
                <div className="gap-2 d-flex flex-row align-items-center justify-content-center px-2">
                  {/* <img
                    src={editIcon}
                    alt="Edit"
                    style={{ width: "20px", cursor: "pointer" }}
                    onClick={() => handleRowSelection(row.Device_id)} // Call handleRowSelection on click
                  /> */}
                  <img
                    src={editIcon}
                    alt="Edit"
                    style={{ width: "20px", cursor: "pointer" }}
                    onClick={() => {
                      console.log("Clicked Device ID:", row.Device_id);
                      handleRowSelection(row.Device_id);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="gap-2 d-flex flex-row align-items-center justify-content-center px-2">
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{ width: "20px", cursor: "pointer" }}
                    onClick={() => handleDeleteRow(row.Device_id)} // Call handleDeleteRow on click
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
export default DeviceDetails;
