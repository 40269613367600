import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { PermissionsContext } from "../../context/PermissionsContext";
import "./UserTable.css"; // Importing the styles
import axios from "axios"; // Importing axios for API requests

// Importing icons from src/assets
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import editIcon from "../../assets/edit-icon.png";
import {
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Modal,
  Tooltip,
} from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import { useTranslation } from "react-i18next";
import { MessageContext } from "../../App"; // Context for displaying messages
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";

const UserTable = ({ stateChanger, onFilterChange }) => {
  const searchInputRef = useRef(null); // Create a ref for the search bar
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [selectedColumns, setSelectedColumns] = useState({
    userId: false,
    namespace: false,
    entityName: true,
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNumber: true,
    role: true,
    userType: true, // Add the new column here
  });
  const [disableBox, setDisablekBox] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
  });
  const [data, setData] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedUser, setSelectedUser] = useState(null); // Selected user for confirmation
  const [enable, setEnable] = useState(false); // To track enabling/disabling

  const onMessageChange = useContext(MessageContext); // Using context for messages
  const { t } = useTranslation(["user"]);

  // Retrieve userData from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userEmail = userData?.email || "";
  const entityId = userData?.entityId || "";
  const userId = userData?.userId || "";
  const authToken = sessionStorage.getItem("authToken");

  // Determine if plant_user or entity_user
  const userType = userData?.user_type || "";
  const isPlantUserLoggedIn = userType === "plant_user";

  //  Set default radio selection (no reassign)
  // const defaultFilter = isPlantUserLoggedIn ? "Plant User" : "Entity User";
  const defaultFilter = isPlantUserLoggedIn ? "Employee" : "Entity User";
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);

  // Access the Permissions context
  const { permissions } = useContext(PermissionsContext);
  const hasPermission = (moduleName, widgetName) => {
    if (!permissions || !permissions.modules) return false;
    const moduleArray = permissions.modules[moduleName];
    if (!moduleArray) return false;
    const widgetObj = moduleArray.find((w) => w.widget_name === widgetName);
    return widgetObj && widgetObj.permission === "yes";
  };

  // For better display of userType
  const userTypeDisplay = {
    entity_user: "Entity User",
    plant_user: "Plant User",
  };

  // Tooltips
  const iconTooltips = {
    updownIcon: "Sort columns",
    filterIcon: "Filter results",
    settingIcon: "Settings",
    searchIcon: "Search",
    editIcon: "Edit this record",
  };

  useEffect(() => {
    const fetchUsers = async () => {
      let apiUrl = "";
      let method = "GET";
      let bodyData = null;

      // 1) If user wants to see disabled users:
      if (disableBox) {
        apiUrl = `https://solar-api.antsai.in/api/user/fetchDisabledUsers?entityid=${entityId}`;
      } else {
        // 2) If not disabled, check the radio filter:
        if (selectedFilter === "Entity User") {
          apiUrl = "https://solar-api.antsai.in/api/user/entityUserList";
          method = "POST";
          bodyData = {
            entityid: entityId, // Use the correct variable entityId
            user_id: userId,
          };
        } else if (selectedFilter === "Plant User") {
          apiUrl = "https://solar-api.antsai.in/api/user/plantUserList";
          method = "POST";
          bodyData = {
            entityid: entityId, // Use the correct variable entityId
            user_id: userId,
          };
        } else if (selectedFilter === "Employee") {
          apiUrl = "https://solar-api.antsai.in/api/user/employeeList";
          method = "POST";
          bodyData = {
            entityid: entityId, // Use the correct variable entityId
            user_id: userId,
          };
        }
      }

      try {
        const response = await fetch(apiUrl, {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: bodyData ? JSON.stringify(bodyData) : null,
        });

        const responseData = await response.json();

        // Format data differently depending on the shape
        if (Array.isArray(responseData)) {
          // Case: array directly
          const formattedData = responseData.map((user) => ({
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            emailId: user.emailId,
            mobileNumber: user.mobileNo,
            role: user.role,
            userType: user.userType,
            namespace: user.namespace,
            entityName: user.entityName,
            entityid: user.entityid, // Ensure correct usage of entityid
          }));
          console.log("User Data:", formattedData);
          setData(formattedData);
        } else if (responseData.success && Array.isArray(responseData.data)) {
          // Case: { success: true, data: [...] }
          const formattedData = responseData.data.map((user) => ({
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            emailId: user.emailId,
            mobileNumber: user.mobileNo,
            role: user.role,
            userType: user.userType,
            namespace: user.namespace,
            entityName: user.entityName,
            entityid: user.entityid, // Ensure correct usage of entityid
          }));
          console.log("User Data:", formattedData);
          setData(formattedData);
        } else {
          console.error("Unexpected data format:", responseData);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setData([]);
      }
    };

    fetchUsers();
    // Re-run whenever disableBox, selectedFilter, entityId, userId, or authToken changes
  }, [disableBox, selectedFilter, entityId, userId, authToken]); // Use entityId instead of entityid

  // const handleEditUser = (user) => {
  //   // Ensure entityid is accessed correctly
  //   const entityid = user.entityid; // Correct case
  //   const userId = user.userId;

  //   // Store userId and entityid in session storage
  //   sessionStorage.setItem(
  //     "selectedUser",
  //     JSON.stringify({ userId, entityid })
  //   );

  //   console.log("Stored in sessionStorage:", { userId, entityid });

  //   // Redirect to the Edit User page
  //   stateChanger(user);
  // };

  useEffect(() => {
    // Notify parent of the initial filter when the component mounts
    if (typeof onFilterChange === "function") {
      onFilterChange(defaultFilter);
    }
  }, []); // Empty dependency array ensures this runs once on mount

  const handleEditUser = (user) => {
    const entityid = user.entityid; // Correct case
    const rowUserId = user.userId;

    if (selectedFilter === "Employee") {
      // const loginUserId = sessionStorage.getItem("userId");
      sessionStorage.setItem(
        "selectedUser",
        // JSON.stringify({ loginUserId, rowUserId: userId })
        JSON.stringify({ loginUserId: userId, rowUserId }) // Use the extracted login user ID
      );
    } else {
      sessionStorage.setItem(
        "selectedUser",
        JSON.stringify({ rowUserId, entityid })
      );
    }
    const selectedUser = JSON.parse(sessionStorage.getItem("selectedUser"));
    console.log("Selected User:", selectedUser);

    stateChanger(user); // Pass user data to the next page
  };

  //       Disable / Enable user
  const disableRow = (e, user) => {
    e.stopPropagation();
    setEnable(false); // For disabling
    setSelectedUser(user);
    setShowConfirmModal(true);
  };

  const enableRow = (e, user) => {
    e.stopPropagation();
    setEnable(true); // For enabling
    setSelectedUser(user);
    setShowConfirmModal(true);
  };

  const confirmDisable = async () => {
    if (!selectedUser?.userId) {
      onMessageChange({
        type: "Warning",
        info: "User ID is missing. Please select a valid user.",
      });
      return;
    }

    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/user/disable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ userId: selectedUser.userId }),
        }
      );

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "User disabled successfully.",
        });
        setShowConfirmModal(false);
        setEnable(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to disable user: ${
            errorData.message || "Unknown error"
          }`,
        });
        setShowConfirmModal(false);
      }
    } catch (error) {
      console.error("Error disabling user:", error);
      onMessageChange({
        type: "Warning",
        info: "An error occurred while disabling the user. Please try again later.",
      });
      setShowConfirmModal(false);
    }
  };

  const confirmEnable = async () => {
    if (!selectedUser?.userId) {
      onMessageChange({
        type: "Warning",
        info: "User ID is missing. Please select a valid user.",
      });
      return;
    }

    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/user/enableUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ userId: selectedUser.userId }),
        }
      );

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: `User ${selectedUser.firstName} ${selectedUser.lastName} restored successfully.`,
        });
        setShowConfirmModal(false);
        setEnable(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to enable user: ${
            errorData.message || "Unknown error"
          }`,
        });
        setShowConfirmModal(false);
      }
    } catch (error) {
      console.error("Error enabling user:", error);
      onMessageChange({
        type: "Warning",
        info: "An error occurred while enabling the user. Please try again later.",
      });
      setShowConfirmModal(false);
    }
  };

  //         CSV Download
  const downloadCSV = () => {
    if (!data || data.length === 0) return;

    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + (row[header] ?? "")).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "UserList.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  //        Sorting & Searching
  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  // Sort data
  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if ((a[sortConfig.key] ?? "") < (b[sortConfig.key] ?? "")) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if ((a[sortConfig.key] ?? "") > (b[sortConfig.key] ?? "")) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  // Filter by typed search in the selected column
  const filteredData = useMemo(() => {
    if (!selectedFilter || !searchQuery) return sortedData;
    return sortedData.filter((row) =>
      (row[selectedFilter] ?? "")
        .toString()
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase())
    );
  }, [searchQuery, sortedData, selectedFilter]);

  // Pagination
  const currentRows = useMemo(() => {
    if (filteredData.length === 0) return [];
    const startIndex = (tableProps.currentPage - 1) * tableProps.rowsPerPage;
    const endIndex = startIndex + tableProps.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, tableProps]);

  const tablePropsChange = (newTableProps) => setTableProps(newTableProps);

  // Handle filter (dropdown) selection -> sets which column to search
  const handleFilterSelect = (column) => {
    setSelectedFilter(column);
    setIsDropdownOpen(false);
    // Focus the search input
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 0);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">
          {/* Radio Buttons Group */}
          <Col className="d-flex align-items-center justify-content-start">
            {isPlantUserLoggedIn ? (
              // =========== Only "Plant User" if plant_user is logged in ===========
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: "#333",
                    cursor: "pointer",
                    // gap: "5px",
                  }}
                >
                  {/* <input
                    type="radio"
                    name="userType"
                    value="Plant User"
                    onChange={() => setSelectedFilter("Plant User")}
                    checked={selectedFilter === "Plant User"}
                    className="custom-radio"
                  />
                  <span style={{ fontWeight: "500" }}>Plant User</span>
                </label> */}
                <input
        type="radio"
        name="userType"
        value="Employee"
        onChange={() => {
          setSelectedFilter("Employee");
          if (typeof onFilterChange === "function") {
            onFilterChange("Employee");
          }
        }}
        checked={selectedFilter === "Employee"}
        className="custom-radio"
      />
      <span style={{ fontWeight: "500" }}>Employee</span>
    </label>
              </div>
            ) : (
              // =========== "Entity User", "Plant User", "Employee" if entity_user ===========
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: "#333",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    name="userType"
                    value="Entity User"
                    onChange={() => {
                      setSelectedFilter("Entity User");
                      if (typeof onFilterChange === "function") {
                        onFilterChange("Entity User");
                      }
                    }}
                    checked={selectedFilter === "Entity User"}
                    className="custom-radio"
                  />
                  <span style={{ fontWeight: "500" }}>Entity User</span>
                </label>

                <label
                  style={{
                    display: "flex",
                    gap: "0.1rem",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    name="userType"
                    value="Plant User"
                    onChange={() => {
                      setSelectedFilter("Plant User");
                      if (typeof onFilterChange === "function") {
                        onFilterChange("Plant User");
                      }
                    }}
                    checked={selectedFilter === "Plant User"}
                    className="custom-radio"
                  />
                  <span style={{ fontWeight: "500" }}>Plant User</span>
                </label>

                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: "#333",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    name="userType"
                    value="Employee"
                    onChange={() => {
                      setSelectedFilter("Employee");
                      // Notify the parent of the change
                      if (typeof onFilterChange === "function") {
                        onFilterChange("Employee");
                      }
                    }}
                    checked={selectedFilter === "Employee"}
                    className="custom-radio"
                  />
                  <span style={{ fontWeight: "500" }}>Employee</span>
                </label>
              </div>
            )}
          </Col>
          <Col></Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end">
            <span className="downloadLink" onClick={downloadCSV}>
              {t("user:download")}
            </span>

            {/* Filter Dropdown (column-based search) */}
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              show={isDropdownOpen}
              onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {[
                        "userId",
                        "firstName",
                        "lastName",
                        "emailId",
                        "mobileNumber",
                        "role",
                      ].map((column) => (
                        <li
                          key={column}
                          onClick={() => handleFilterSelect(column)}
                        >
                          {column.charAt(0).toUpperCase() + column.slice(1)}
                        </li>
                      ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img
                src={filterIcon}
                alt="filter"
                className="filter-icon"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              />
            </OverlayTrigger>

            {/* Search Bar */}
            <div style={{ position: "relative" }} className="w-auto">
              <input
                ref={searchInputRef}
                type="text"
                style={{
                  width: "341px",
                  height: "39px",
                  background: "#BBCCD9 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  opacity: "0.51",
                }}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
          </Col>
        </Row>

        {/* Custom radio styling */}
        <style>
          {`
    .custom-radio {
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #333;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      outline: none;
      background-color: #fff;
      transition: all 0.2s ease-in-out;
      margin-right: 0.3rem;
    }

    .custom-radio:checked {
      border-color: #007bff;
      background-color: #fff;
    }

    .custom-radio:checked::after {
      content: '✔';
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #007bff;
      font-size: 12px;
      font-weight: bold;
    }

    .custom-radio:focus {
      outline: none;
      border-color: #007bff;
    }
  `}
        </style>

        <div className="border rounded border-light-subtle">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column
                          .replace(/([A-Z])/g, " $1")
                          .charAt(0)
                          .toUpperCase() + column.slice(1)}
                        <img
                          src={updownIcon}
                          alt="sort"
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ))}
                  <th>
                    {/* Settings / show-hide columns / disabled */}
                    <div className=" d-flex flex-row align-items-center justify-content-end">
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="auto-start"
                        overlay={
                          <Popover>
                            <Popover.Body className="overlay-body">
                              {Object.keys(selectedColumns).map((column) => (
                                <label key={column}>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns[column]}
                                    onChange={() =>
                                      setSelectedColumns((prev) => ({
                                        ...prev,
                                        [column]: !prev[column],
                                      }))
                                    }
                                  />{" "}
                                  {column.charAt(0).toUpperCase() +
                                    column.slice(1)}
                                </label>
                              ))}
                              <label>
                                <input
                                  type="checkbox"
                                  checked={disableBox}
                                  onChange={() => setDisablekBox(!disableBox)}
                                />{" "}
                                Disabled
                              </label>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <img
                            src={settingIcon}
                            alt="settings"
                            className="setting-icon"
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRows.length > 0 ? (
                  currentRows.map((row) => (
                    <tr key={row.userId}>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <td key={`${row.userId}-${column}`}>
                            {column === "userType"
                              ? userTypeDisplay[row[column]] || row[column]
                              : row[column]}
                          </td>
                        ))}
                      <td className="delete-column">
                        <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                          {hasPermission("Admin", "Edit User") && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-edit">
                                  {iconTooltips.editIcon}
                                </Tooltip>
                              }
                            >
                              <img
                                src={editIcon}
                                alt="edit"
                                className="edit-icon"
                                onClick={() => handleEditUser(row)}
                                style={{ cursor: "pointer" }}
                              />
                            </OverlayTrigger>
                          )}

                          {/* Disable/Enable Icon */}
                          {row.emailId !== userEmail ? (
                            <NotInterestedOutlinedIcon
                              onClick={(e) =>
                                disableBox
                                  ? enableRow(e, row)
                                  : disableRow(e, row)
                              }
                              style={{ cursor: "pointer" }}
                              className={
                                disableBox ? "enable-icon" : "disable-icon"
                              }
                            />
                          ) : (
                            <span style={{ width: "24px" }} />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={Object.keys(selectedColumns).length + 1}
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        color: "#666",
                        fontSize: "16px",
                      }}
                    >
                      {disableBox
                        ? "No disabled records found."
                        : "No active records found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {filteredData.length > 0 && (
              <Paginationcontrol
                rowsCount={filteredData.length}
                tableProps={tableProps}
                tablePropsChange={tablePropsChange}
                alwaysShown={true}
              />
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{enable ? "Enable User" : "Disable User"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {enable
            ? `Are you sure you want to enable ${selectedUser?.firstName} ${selectedUser?.lastName}?`
            : `Are you sure you want to disable ${selectedUser?.firstName} ${selectedUser?.lastName}?`}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-button"
            onClick={() => setShowConfirmModal(false)}
            autoFocus
          >
            No
          </button>
          <button
            className="modal-button"
            onClick={() => (enable ? confirmEnable() : confirmDisable())}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserTable;
