import React, { useState, useMemo, useEffect, useContext } from "react";
import { PermissionsContext } from "../../context/PermissionsContext";
import "./../plant/PlantTable"; // Importing the styles
import axios from "axios";
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import { MessageContext } from "../../App";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import { useTranslation } from "react-i18next";

const PlantTable = ({ stateChanger, onSelectPlant, selectedPlantIds = [] }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    plantId: true,
    plantName: true,
    plantType: true,
    plantCategory: false,
    capacity: true,
    capacityUnit: false,
    country: true,
    region: false,
    state: false,
    district: false,
    pincode: false,
    longitude: false,
    latitude: false,
    installDate: false,
    azimuthAngle: false,
    tiltAngle: false,
    ownerFirstName: false,
    ownerLastName: false,
    ownerEmail: false,
    assignmentStatus: false,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });

  // 1. Access the Permissions context
  const { permissions } = useContext(PermissionsContext);

  // 2. Define a utility function to check permission
  const hasPermission = (moduleName, widgetName) => {
    if (!permissions || !permissions.modules) return false;
    const moduleArray = permissions.modules[moduleName];
    if (!moduleArray) return false;
    const widgetObj = moduleArray.find((w) => w.widget_name === widgetName);
    return widgetObj && widgetObj.permission === "yes";
  };

  const iconTooltips = {
    updownIcon: "Sort columns",
    filterIcon: "Filter results",
    settingIcon: "Settings",
    searchIcon: "Search",
    editIcon: "Edit this record",
  };

  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const onMessageChange = useContext(MessageContext);

  const plants = ["Selected", "Un-Selected", "Un-Assigned", "All"];
  const [plantSelect, setPlantSelect] = useState({
    Selected: false,
    "Un-Selected": false,
    "Un-Assigned": false,
    Both: false,
  });

  const navigate = useNavigate();
  const { t } = useTranslation(["plant"]);

  const [selectedRows, setSelectedRows] = useState(
    data.filter((row) => selectedPlantIds.includes(row.plantId))
  );

  // Initialize selected rows based on selectedPlantIds only once after data loads
  useEffect(() => {
    if (data.length > 0 && selectedPlantIds.length > 0) {
      setSelectedRows(
        data.filter((row) => selectedPlantIds.includes(row.plantId))
      );
    }
  }, [data, selectedPlantIds]);

  // Toggle checkbox selection
  const handleRowSelection = (rowData) => {
    const isSelected = selectedRows.some(
      (row) => row.plantId === rowData.plantId
    );
    const updatedSelectedRows = isSelected
      ? selectedRows.filter((row) => row.plantId !== rowData.plantId)
      : [...selectedRows, rowData];

    setSelectedRows(updatedSelectedRows);

    // Notify parent component of updated selection
    if (onSelectPlant) {
      onSelectPlant(updatedSelectedRows.map((row) => row.plantId));
    }
  };

  const fetchData = async () => {
    try {
      // Get the userData from session storage and extract userId.
      // If no userId is found, fallback to the provided default.
      const userData = JSON.parse(sessionStorage.getItem("userData")) || {};
      const userId = userData.userId || {};
      const authToken = sessionStorage.getItem("authToken");

      const response = await axios.post(
        "https://solar-api.antsai.in/api/user/fetchUserPlantList",
        { userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          plantId: item["Plant ID"],
          plantName: item["Plant Name"],
          plantType: item["Plant Type"],
          plantCategory: item["Plant Category"],
          capacity: item["Capacity"],
          capacityUnit: item["Capacity Unit"],
          country: item["Country"],
          region: item["Region"],
          state: item["State"],
          district: item["District"],
          pincode: item["Pincode"],
          longitude: item["Longitude"],
          latitude: item["Latitude"],
          installDate: item["Install Date"],
          azimuthAngle: item["Azimuth Angle"],
          tiltAngle: item["Tilt Angle"],
          ownerFirstName: item["Owner First Name"],
          ownerLastName: item["Owner Last Name"],
          ownerEmail: item["Owner Email"],
          assignmentStatus: item["Assignment Status"],
        }));
        setData(formattedData);
      } else {
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsFilterVisible(false);
  };

  const handlePlantSelect = (option) => {
    setPlantSelect((prev) => ({
      ...prev,
      Selected: false,
      "Un-Selected": false,
      "Un-Assigned": false,
      Both: false,
      [option]: !prev[option],
    }));
  };

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig, searchQuery]);

  const filteredData = useMemo(() => {
    const sort = sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true;
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase());
    });

    return sort.filter((s) => {
      if (plantSelect.Both === true) {
        return true;
      } else if (plantSelect.Selected) {
        return selectedRows.findIndex((sr) => sr.plantId === s.plantId) !== -1;
      } else if (plantSelect["Un-Selected"]) {
        return selectedRows.findIndex((sr) => sr.plantId === s.plantId) === -1;
      } else {
        return true;
      }
    });
  }, [sortedData, selectedFilter, searchQuery, plantSelect, selectedRows]);

  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(
        (tableProps.currentPage - 1) * tableProps.rowsPerPage,
        tableProps.currentPage * tableProps.rowsPerPage
      );
    }
    return [];
  }, [filteredData, tableProps]);

  const tablePropsChange = (tableProps) => {
    setTableProps(tableProps);
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center px-0"
      style={{ fontFamily: "Open Sans", fontWeight: "bold" }}
    >
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between">
          <Col>
            <span style={{ fontSize: "24px", color: "#343544" }}>
              Plant List
            </span>
          </Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end">
            <div
              className="search-bar-container"
              style={{ position: "relative" }}
            >
              <input
                type="text"
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li
                            key={column}
                            onClick={() => handleFilterSelect(column)}
                          >
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={filterIcon} alt="filter" className="filter-icon" />
            </OverlayTrigger>

            <OverlayTrigger
              rootClose
              trigger="click"
              placement="auto-start"
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <Stack>
                      {plants.map((s) => {
                        return (
                          <label key={s}>
                            <input
                              type="checkbox"
                              checked={plantSelect[s]}
                              onChange={() => handlePlantSelect(s)}
                            />{" "}
                            {s.charAt(0).toUpperCase() + s.slice(1)} Plants
                          </label>
                        );
                      })}
                    </Stack>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={settingIcon} alt="settings" className="setting-icon" />
            </OverlayTrigger>
          </Col>
        </Row>

        <div className="border rounded border-light-subtle">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^\w/, (c) => c.toUpperCase())}
                        <img
                          src={updownIcon}
                          alt="sort"
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                    ))}
                  <th>
                    <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="auto-start"
                        overlay={
                          <Popover>
                            <Popover.Body className="overlay-body">
                              <div>
                                {Object.keys(selectedColumns).map((column) => (
                                  <label key={column}>
                                    <input
                                      type="checkbox"
                                      checked={selectedColumns[column]}
                                      onChange={() => handleColumnSelect(column)}
                                    />{" "}
                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                  </label>
                                ))}
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <img
                            src={settingIcon}
                            alt="settings"
                            className="setting-icon"
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRows.map((row) => (
                  <tr key={row.plantId}>
                    {Object.keys(selectedColumns)
                      .filter((key) => selectedColumns[key])
                      .map((column) => (
                        <td key={`${row.plantId}-${column}`}>{row[column]}</td>
                      ))}
                    <td className="checkbox-column">
                      <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
                        <Form.Check
                          type="checkbox"
                          checked={selectedRows.some(
                            (selectedRow) => selectedRow.plantId === row.plantId
                          )}
                          onChange={() => handleRowSelection(row)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredData.length > 0 ? (
              <Paginationcontrol
                rowsCount={filteredData.length}
                tableProps={tableProps}
                tablePropsChange={tablePropsChange}
                alwaysShown={true}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantTable;
