

////old//

import React, { useState, useRef, useMemo } from "react";
import "./PerformanceAlerts.css";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Label } from "recharts";
import { IconButton, Menu, MenuItem, Checkbox, ListItemText, Dialog, DialogContent } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import dragHandleDots from "../../assets/drag_handle_dots.svg";
import dropDownArrow from "../../assets/drop_down_arrow.svg";
import expandAltIcon from "../../assets/expand_alt_icon.svg";
import informationIcon from "../../assets/information_icon.svg";
import settingsIcon from "../../assets/settings_icon.svg";
import CloseBlue from "../../assets/closeBtnBlue.svg";
import sampleData from "./SampleData"; // Import your sample data

import CloseIcon from '@mui/icons-material/Close';

const PerformanceAlerts = () => {

  // Sample data representing multiple plants
const plantsData = [
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 0, critical_alerts_closed: 0, major_alerts_open: 0, major_alerts_closed: 0, plant_status: 'online' },
  { critical_alerts_open: 1, critical_alerts_closed: 1, major_alerts_open: 1, major_alerts_closed: 0, plant_status: 'offline' },
  // Add more plants if needed
];

// Constants for colors
const COLORS = {
  critical_open: '#FF4032',
  critical_closed: '#FF4032',
  major_open: '#FF9500',
  major_closed: '#FF9500',
  normal: '#88BFFF',
};


  const [selectedOption, setSelectedOption] = useState('open'); // Default selection
  const [anchorEl, setAnchorEl] = useState(null); // For handling the dropdown menu

  // Calculate aggregated data based on selected filters
  const aggregatedData = useMemo(() => {
    let criticalOpen = 0;
    let criticalClosed = 0;
    let majorOpen = 0;
    let majorClosed = 0;
    let normalCount = 0;

    plantsData.forEach((plant) => {
      if (plant.critical_alerts_open === 0 && plant.critical_alerts_closed === 0 && plant.major_alerts_open === 0 && plant.major_alerts_closed === 0 && plant.plant_status === 'online') {
        normalCount++;
      }

      criticalOpen += plant.critical_alerts_open;
      criticalClosed += plant.critical_alerts_closed;
      majorOpen += plant.major_alerts_open;
      majorClosed += plant.major_alerts_closed;
    });

    const result = [
      ...(selectedOption === 'open' ? [
        { name: 'Plants with Critical Alerts', value: criticalOpen, color: COLORS.critical_open },
        { name: 'Plants with Major Alerts', value: majorOpen, color: COLORS.major_open },
      ] : []),
      ...(selectedOption === 'closed'  ? [
        { name: 'Plants with Critical Alerts', value: criticalClosed, color: COLORS.critical_closed },
        { name: 'Plants with Major Alerts', value: majorClosed, color: COLORS.major_closed },
      ] : []),
      ...( selectedOption === 'both' ? [
        { name: 'Plants with Critical Alerts Open', value: criticalOpen, color: COLORS.critical_open },
        { name: 'Plants with Major Alerts Open', value: majorOpen, color: COLORS.major_open },
        { name: 'Plants with Critical Alerts Closed', value: criticalClosed, color: COLORS.critical_closed },
        { name: 'Plants with Major Alerts Closed', value: majorClosed, color: COLORS.major_closed },
      ] : []),
      { name: 'Plants Running Normal', value: normalCount, color: COLORS.normal },
    ];

    return result;
  }, [selectedOption]);

  // Handle dropdown selection
  const handleChange = (option) => {
    setSelectedOption(option);
    setAnchorEl(null); // Close the menu after selection
  };

  // Handle opening/closing the menu
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="PA-performance-alerts-card">
      <div className="PA-card-header">
        <div className="PA-header-left">
          <img
            src={dragHandleDots}
            alt="Drag Handle"
            className="PA-drag-handle"
          />
          <h2 className="PA-card-title">Performance Alerts</h2>
          <img
            src={informationIcon}
            alt="Information Icon"
            className="PA-info-icon"
          />
        </div>
        <div className="PA-header-right">
          {/* <div className="PA--icon">
            <span className="PA-setting-label">
              {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)} Alerts
            </span>
          </div>
          <div
            className="PA-icon"
            
          > */}
           {/* Gear icon triggering the dropdown */}
              {/* <IconButton onClick={handleOpenMenu} style={{padding:0}}> */}
                {/* <SettingsIcon /> */}
                {/* <img
                  src={settingsIcon}
                  alt="Gear Icon"
                  className="PA-setting-handle"
                />
              </IconButton> */}

              {/* Custom dropdown menu */}
              {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
              <MenuItem onClick={() => handleChange('open')}>
                <Checkbox checked={selectedOption === 'open'} />
                <ListItemText primary="Open" />
              </MenuItem>
              <MenuItem onClick={() => handleChange('closed')}>
                <Checkbox checked={selectedOption === 'closed'} />
                <ListItemText primary="Closed" />
              </MenuItem>
              <MenuItem onClick={() => handleChange('both')}>
                <Checkbox checked={selectedOption === 'both'} />
                <ListItemText primary="Both" />
              </MenuItem>
            </Menu>
          </div> */}
          {/* <div className="PA-divider"></div> */}
          <div className="PA-icon" onClick={handleOpenModal}>
            <img
              src={expandAltIcon}
              alt="Expand Icon"
              className="PA-expand-icon"
            />
          </div>
        </div>
      </div>

      <div className="PA-card-content">
        <div className="PA-pie-chart-container">
          <ResponsiveContainer width={'63%'} height={300} >
          <PieChart width={300} height={300} >
        <Pie
          data={aggregatedData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="45%"
          textAnchor='start'
          innerRadius={65}
          outerRadius={105}
          fill="#8884d8"
          label
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
        >
          {aggregatedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        
      </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="PA-alerts-details">
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {aggregatedData.map((entry, index) => (
            <li key={index} style={{ color: entry.color }}>
              {/* {entry.name}: {entry.value} */}
              <div className="PA-detail">
                <div className="PA-value" style={{ color: entry.color }}><span>{entry.value}</span></div>
                <div className="PA-label">{entry.name}</div>
              </div>
            </li>
          ))}
        </ul>
          
        </div>
      </div>
      {/* Modal Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth={'md'} fullWidth  PaperProps={{
    sx: {
      width: "50%",
      minHeight: "45%"
    }
  }}>
        <DialogContent>
        <div className="PA-card-header">
        <div className="PA-header-left">
          <img
            src={dragHandleDots}
            alt="Drag Handle"
            className="PA-drag-handle"
          />
          <h2 className="PA-card-title">Performance Alerts</h2>
          <img
            src={informationIcon}
            alt="Information Icon"
            className="PA-info-icon"
          />
        </div>
        <div className="PA-header-right">
          {/* <div className="PA--icon">
            <span className="PA-setting-label">
              {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)} Alerts
            </span>
          </div> */}
          {/* <div
            className="PA-icon"
            
          > */}
           {/* Gear icon triggering the dropdown */}
              {/* <IconButton onClick={handleOpenMenu} style={{padding:0}}> */}
                {/* <SettingsIcon /> */}
                {/* <img
                  src={settingsIcon}
                  alt="Gear Icon"
                  className="PA-setting-handle"
                />
              </IconButton> */}

              {/* Custom dropdown menu */}
              {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
              <MenuItem onClick={() => handleChange('open')}>
                <Checkbox checked={selectedOption === 'open'} />
                <ListItemText primary="Open" />
              </MenuItem>
              <MenuItem onClick={() => handleChange('closed')}>
                <Checkbox checked={selectedOption === 'closed'} />
                <ListItemText primary="Closed" />
              </MenuItem>
              <MenuItem onClick={() => handleChange('both')}>
                <Checkbox checked={selectedOption === 'both'} />
                <ListItemText primary="Both" />
              </MenuItem>
            </Menu>
          </div>
          <div className="PA-divider"></div> */}
          <div className="PA-icon" onClick={handleCloseModal}>
            <img
              src={CloseBlue}
              alt="Close Icon"
              className="PA-close-icon"
            />
          </div>
        </div>
      </div>

      <div className="PA-card-content">
        <div className="PA-pie-chart-container">
          <ResponsiveContainer width={'63%'} height={300} >
          <PieChart width={300} height={300} >
        <Pie
          data={aggregatedData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="45%"
          textAnchor='start'
          innerRadius={65}
          outerRadius={105}
          fill="#8884d8"
          label
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
        >
          {aggregatedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        <Label angle={270} position='insideLeft' offset={10}
    value=" TA / Pouls"
    style={{ textAnchor: 'middle', fontSize: '80%', fill: 'rgba(0, 0, 0, 0.87)' }}></Label>
      </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="PA-alerts-details">
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {aggregatedData.map((entry, index) => (
            <li key={index} style={{ color: entry.color }}>
              {/* {entry.name}: {entry.value} */}
              <div className="PA-detail">
                <div className="PA-value" style={{ color: entry.color }}><span>{entry.value}</span></div>
                <div className="PA-label">{entry.name}</div>
              </div>
            </li>
          ))}
        </ul>
          
        </div>
      </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PerformanceAlerts;
