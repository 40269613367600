import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import PlantBulkImportTable from "./PlantBulkImportTable";

function PlantBulkImportTwo() {
    return <>
        <Container className="mw-100 ">

            <Row className="mt-2 mb-2 ">

                <Col className="d-flex flex-row align-items-center justify-content-start">
                    <span className="fw-bold text-decoration-none text-black">Step 2 - Import Data</span>
                </Col>

                <Col className="d-flex flex-row align-items-center justify-content-end">

                </Col>
            </Row>
        </Container>
        <Container className="w-100 p-0 m-3">
            <div className="d-flex flex-column justify-content-center" >
                <div className="mt-2 mb-3"><span className="fw-bold text-decoration-none text-black">Plants Bulk Import</span></div>
                <Row>
                    <Col>
                        <Stack as="row" gap={2} direction="vertical" >
                            <Col >
                                <Form.Group controlId="make">
                                    <Row className="w-100">
                                        <Col md={3}>
                                            <Form.Label aria-colspan={2} className="text-nowrap text-black fw-semibold">Make <span>:</span></Form.Label>
                                        </Col>
                                        <Col md={8} >
                                            <Form.Control name="make" type="string" onChange={(e) => handleChange(e)} placeholder="Make" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="userId">
                                    <Row className="w-100">
                                        <Col md={3}>
                                            <Form.Label className="text-nowrap text-black fw-semibold">User Id <span>:</span></Form.Label>
                                        </Col>
                                        <Col md={8} >
                                            <Form.Control name="userId" type="string" onChange={(e) => handleChange(e)} placeholder="User Id" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="password">
                                    <Row className="w-100">
                                        <Col md={3}><Form.Label className="text-nowrap  text-black fw-semibold">Password  <span>:</span></Form.Label>
                                        </Col><Col md={8} >

                                            <Form.Control name="capacity" onChange={(e) => handleChange(e)} type="password" placeholder="Password" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <div className="w-100 d-flex flex-row align-item-center justify-content-center">
                                    <Form.Group className="w-100" controlId="plantSerial">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap text-black fw-semibold">Master File <span>:</span></Form.Label>
                                            </Col>
                                            <Col md={6} >

                                                <Form.Control name="capacity" onChange={(e) => handleChange(e)} placeholder="File Name" />
                                            </Col>
                                            <Col md={2}>

                                                <Button onClick={() => onDialApi()} className="m-1 ml-4 form-button form-button-sp text-nowrap">Daial API</Button>
                                            </Col>
                                        </Row>
                                        <div className="w-100 d-flex align-items-center justify-content-around"><h5 className="cell-status">Cell Status : Waiting</h5></div>
                                    </Form.Group>
                                </div>

                            </Col>

                        </Stack>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <PlantBulkImportTable/>
                </Row>
            </div>
        </Container>

    </>

}

export default PlantBulkImportTwo;