// import React from "react";

// const GaugeChart = ({ value, width = 267, height = 238 }) => {
//   // Calculate the rotation angle for the needle based on the value
//   const angle = (value / 100) * 285 - 142.5; // Convert value to angle (-90 to 90 degrees)
//   const strokeFill = value+' 100'; // Convert value to stroke fill percentage

//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="248.5" height="220.31" viewBox="0 0 248.5 220.31">
//   <defs>
//     <clipPath id="clip-path">
//       <rect id="Rectangle_5504" data-name="Rectangle 5504" width="76.302" height="31.834" fill="none"/>
//     </clipPath>
//     <clipPath id="clip-path-2">
//       <rect id="Rectangle_5507" data-name="Rectangle 5507" width="248.5" height="220.31" fill="none"/>
//     </clipPath>
//   </defs>
//   <g id="Group_13705" data-name="Group 13705" transform="translate(-92.012 -288.591)">
//     <g id="Group_13661" data-name="Group 13661" transform="translate(179.526 468.522)">
//       <g id="Group_13660" data-name="Group 13660" clip-path="url(#clip-path)">
//         <path id="Rectangle_5503" data-name="Rectangle 5503" d="M2.683,0H70.429a2.683,2.683,0,0,1,2.683,2.683V28.212a2.682,2.682,0,0,1-2.682,2.682H2.682A2.682,2.682,0,0,1,0,28.212V2.683A2.683,2.683,0,0,1,2.683,0Z" transform="translate(0 0.941)" fill="#fff"/>
//         <path id="Path_21024" data-name="Path 21024" d="M67.191.943a4.085,4.085,0,0,1,4,4.155V23.11a4.085,4.085,0,0,1-4,4.155H4.913a4.085,4.085,0,0,1-4-4.155V5.1a4.085,4.085,0,0,1,4-4.155Zm0-.943H4.913A5.008,5.008,0,0,0,0,5.1V23.11a5.008,5.008,0,0,0,4.913,5.1H67.191a5.008,5.008,0,0,0,4.913-5.1V5.1A5.008,5.008,0,0,0,67.191,0" transform="translate(1.553 1.882)" fill="#708496"/>
//         <text id="_55.75_" data-name="55.75 %" transform="translate(7.225 22.303)" fill="#2897ff" font-size="14" text-align="center" font-family="Lato, Open Sans" font-weight="900">
//           <tspan x="4" y="0">{(value > 100)||(value < 0) ? 'Invalid' : value != 0 ? value.toFixed(2)+' %' : "00.00 %"}</tspan></text>
//       </g>
//     </g>
//     <g id="Group_13681" data-name="Group 13681" transform="translate(92.012 288.591)">
//       <g id="Group_13681-2" data-name="Group 13681" clip-path="url(#clip-path-2)">
//         <path id="Path_21052" data-name="Path 21052" d="M23758,11402.387a108.611,108.611,0,1,1,180.234,81.409l-20.383-23.214a76.877,76.877,0,0,0,21.422-85.971,77.909,77.909,0,0,0-146.047,2.721,76.91,76.91,0,0,0,24.652,85.112l-19.5,23.95A107.54,107.54,0,0,1,23758,11402.387Z" transform="translate(-23742.801 -11279.396)" fill="#ccc" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
//         <path 
//           id="Path_21051" 
//           data-name="Path 21051" 
//           d="M23760.682,11390.317c-1.24-43.084,29.016-87.28,79.748-94.9s122.268,37.539,102.107,117.461c-14.414,57.145-70.039,71.491-104.307,66.649" 
//           transform="matrix(0.656, -0.755, 0.755, 0.656, -24121.695, 10661.611)" 
//           fill="none" 
//           stroke="#4caf50" 
//           stroke-miterlimit="10" 
//           stroke-width="31"
//           pathLength="100"
//           strokeDasharray= {strokeFill}/>
//         <path id="Path_21047" data-name="Path 21047" d="M78.815,358.34l-.467-.385" transform="translate(-30.878 -141.074)" fill="none" stroke="#d1d1d1" stroke-width="13"/>
//         <path id="Path_21048" data-name="Path 21048" d="M45.9,215.853a120.312,120.312,0,1,1,161.122.632" transform="translate(-2.562 -2.561)" fill="none" stroke="#d1d1d1" stroke-width="13" stroke-dasharray="2.008 9.036"/>
//         <path id="Path_21049" data-name="Path 21049" d="M333.751,356.053l-.461.393" transform="translate(-131.353 -140.324)" fill="none" stroke="#d1d1d1" stroke-width="13"/>
//         <path id="Path_21052-2" data-name="Path 21052" d="M190.276,192.514a8.964,8.964,0,1,1,8.964,8.964,8.964,8.964,0,0,1-8.964-8.964" transform="translate(-74.99 -72.339)" fill="#2897ff"/>
//         <line id="Line_129" data-name="Line 129"
//           x1="0" 
//           y1="-90" 
//           x2="0"
//           y2="30" 
//           transform={`translate(124 120) rotate(${angle})`}  fill="none" stroke="#2897ff" stroke-width="3"/>
//       </g>
//     </g>
//   </g>
// </svg>

//   );
// };

// export default GaugeChart;


import React, { useState, useEffect } from "react";

const GaugeChart = ({ value, width = 267, height = 238 }) => {
  // Local state for the animated value. This will start at 0 and animate to `value`.
  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    // Reset to 0 whenever the `value` prop changes
    setAnimatedValue(0);

    let start = null;
    const duration = 2000; // Duration of the animation in milliseconds (2 seconds)

    // The animation step function
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      // Calculate the current value based on progress. Clamp to `value` to avoid overshooting.
      const currentValue = Math.min(value * (progress / duration), value);
      setAnimatedValue(currentValue);
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    // Start the animation
    window.requestAnimationFrame(step);
  }, [value]);

  // Calculate the rotation angle for the needle based on the animated value.
  // The formula maps the 0-100 range to the SVG's angle range.
  const angle = (animatedValue / 100) * 283 - 142.5;
  // Create the strokeDasharray string for the gauge fill path.
  const strokeFill = animatedValue + " 100";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="248.5"
      height="220.31"
      viewBox="0 0 248.5 220.31"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_5504"
            data-name="Rectangle 5504"
            width="76.302"
            height="31.834"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_5507"
            data-name="Rectangle 5507"
            width="248.5"
            height="220.31"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_13705" data-name="Group 13705" transform="translate(-92.012 -288.591)">
        <g id="Group_13661" data-name="Group 13661" transform="translate(179.526 468.522)">
          <g id="Group_13660" data-name="Group_13660" clipPath="url(#clip-path)">
            <path
              id="Rectangle_5503"
              data-name="Rectangle 5503"
              d="M2.683,0H70.429a2.683,2.683,0,0,1,2.683,2.683V28.212a2.682,2.682,0,0,1-2.682,2.682H2.682A2.682,2.682,0,0,1,0,28.212V2.683A2.683,2.683,0,0,1,2.683,0Z"
              transform="translate(0 0.941)"
              fill="#fff"
            />
            <path
              id="Path_21024"
              data-name="Path 21024"
              d="M67.191.943a4.085,4.085,0,0,1,4,4.155V23.11a4.085,4.085,0,0,1-4,4.155H4.913a4.085,4.085,0,0,1-4-4.155V5.1a4.085,4.085,0,0,1,4-4.155Zm0-.943H4.913A5.008,5.008,0,0,0,0,5.1V23.11a5.008,5.008,0,0,0,4.913,5.1H67.191a5.008,5.008,0,0,0,4.913-5.1V5.1A5.008,5.008,0,0,0,67.191,0"
              transform="translate(1.553 1.882)"
              fill="#708496"
            />
<text
  id="_55.75_"
  data-name="55.75 %"
  transform="translate(10 22.303)"  // increased the x-value from 7.225 to 10
  fill="#2897ff"
  fontSize="14"
  textAnchor="middle"
  fontFamily="Lato, Open Sans"
  fontWeight="900"
>
  <tspan x="30" y="0">
    {(animatedValue > 100) || (animatedValue < 0)
      ? "Invalid"
      : animatedValue !== 0
      ? animatedValue.toFixed(2) + " %"
      : "00.00 %"}
  </tspan>
</text>
          </g>
        </g>
        <g id="Group_13681" data-name="Group_13681" transform="translate(92.012 288.591)">
          <g id="Group_13681-2" data-name="Group 13681" clipPath="url(#clip-path-2)">
            <path
              id="Path_21052"
              data-name="Path 21052"
              d="M23758,11402.387a108.611,108.611,0,1,1,180.234,81.409l-20.383-23.214a76.877,76.877,0,0,0,21.422-85.971,77.909,77.909,0,0,0-146.047,2.721,76.91,76.91,0,0,0,24.652,85.112l-19.5,23.95A107.54,107.54,0,0,1,23758,11402.387Z"
              transform="translate(-23742.801 -11279.396)"
              fill="#ccc"
              stroke="rgba(0,0,0,0)"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_21051"
              data-name="Path 21051"
              d="M23760.682,11390.317c-1.24-43.084,29.016-87.28,79.748-94.9s122.268,37.539,102.107,117.461c-14.414,57.145-70.039,71.491-104.307,66.649"
              transform="matrix(0.656, -0.755, 0.755, 0.656, -24121.695, 10661.611)"
              fill="none"
              stroke="#4caf50"
              strokeMiterlimit="10"
              strokeWidth="31"
              pathLength="100"
              strokeDasharray={strokeFill}
            />
            <path
              id="Path_21047"
              data-name="Path 21047"
              d="M78.815,358.34l-.467-.385"
              transform="translate(-30.878 -141.074)"
              fill="none"
              stroke="#d1d1d1"
              strokeWidth="13"
            />
            <path
              id="Path_21048"
              data-name="Path 21048"
              d="M45.9,215.853a120.312,120.312,0,1,1,161.122.632"
              transform="translate(-2.562 -2.561)"
              fill="none"
              stroke="#d1d1d1"
              strokeWidth="13"
              strokeDasharray="2.008 9.036"
            />
            <path
              id="Path_21049"
              data-name="Path 21049"
              d="M333.751,356.053l-.461.393"
              transform="translate(-131.353 -140.324)"
              fill="none"
              stroke="#d1d1d1"
              strokeWidth="13"
            />
            <path
              id="Path_21052-2"
              data-name="Path 21052"
              d="M190.276,192.514a8.964,8.964,0,1,1,8.964,8.964,8.964,8.964,0,0,1-8.964-8.964"
              transform="translate(-74.99 -72.339)"
              fill="#2897ff"
            />
            <line
              id="Line_129"
              data-name="Line 129"
              x1="0"
              y1="-90"
              x2="0"
              y2="30"
              transform={`translate(124 120) rotate(${angle})`}
              fill="none"
              stroke="#2897ff"
              strokeWidth="3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GaugeChart;
