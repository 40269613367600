import React, { useState, useEffect } from 'react';
import { Container, Row, Accordion, Stack, Col, Form, Modal, Button } from "react-bootstrap";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// =========== 1) Define submodules by accordion "sectionKey" ===========
const adminModule = [
  "Entity", "Entity Table", "Add Entity", "Edit Entity", "Plant",
  "Plant Table", "Add Plant", "Edit Plant", "Role", "User",
  "User Table", "Add User", "Edit User", "Catalog", "Report Templates", "Settings"
];
const dashboardModule = [
  "Production Overview", "Performance Alerts", "Power Normalisation Ranks",
  "Plant Status", "Peak hour Ranking", "Environmental Benefits",
  "Historical Production", "Predictive Analytics", "Anomaly detection", "Weather"
];
const plantOverviewModule = ["Peak Power", "Alerts", "Production", "Add Plant"];
const plantModule = ["Plant Details", "Devices", "Plant Analytics", "Edit plant", "New Ticket"];
const maintenanceModule = ["FTR edit", "Edit Tickets"]; // Updated to match API
const analyticModule = ["Analytics", "Analytics Widget 1", "Analytics Widget 2"];
const reportModule = ["Master", "Operations", "Maintenance"]; // Updated to match API

// Map "sectionKey" => array of submodules
const sectionModules = {
  "1": adminModule,
  "2": dashboardModule,
  "3": plantOverviewModule,
  "4": plantModule,
  "5": maintenanceModule,
  "6": analyticModule,
  "7": reportModule
};

// =========== 2) Build initial `permissions` ===========
function generateInitialPermissions(roles) {
  const initial = {};
  roles.forEach((role) => {
    initial[role] = {};
    Object.entries(sectionModules).forEach(([sectionKey, modArray]) => {
      initial[role][sectionKey] = {
        all: false,
        modules: modArray.reduce((acc, cur) => {
          acc[cur] = false;
          return acc;
        }, {})
      };
    });
  });
  return initial;
}

// =========== 3) Map API role name => local column title ===========
function mapApiRoleNameToLocalTitle(apiRoleName) {
  switch (apiRoleName.toLowerCase()) {
    case "sys admin":        return "System Administrator";
    case "plant manager":    return "Plant Manager";
    case "field executive":  return "Field Executive";
    case "individual":       return "Individual";
    default:
      console.warn(`Unrecognized role name: ${apiRoleName}`);
      return null; 
  }
}

// =========== 4) Map module_name => accordion sectionKey ===========
function getSectionKeyFromModuleName(moduleName) {
  switch (moduleName) {
    case "Admin":          return "1";
    case "Dashboard":      return "2";
    case "Plant Overview": return "3";
    case "Plant":          return "4";
    case "Maintenance":    return "5";
    case "Analytics":      return "6";
    case "Report":         return "7";
    default:
      console.warn(`Unrecognized module name: ${moduleName}`);
      return null;
  }
}

function Roles() {

  // Modal state for Reset and Save actions
  const [showResetModal, setShowResetModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  // ===== A) Define local UI columns for roles =====
  // Initialize all roles as disabled. We'll later update them based on the logged-in user's role.
  const [module, setModule] = useState([
    { title: "System Administrator", status: false, disabled: true },
    { title: "Plant Manager",        status: false, disabled: true },
    { title: "Field Executive",      status: false, disabled: true },
    { title: "Individual",           status: false, disabled: true },
  ]);

  // ===== B) Accordion open/close state =====
  const [activeKeys, setActiveKeys] = useState([]); // e.g. ["1","2"]

  // ===== C) Permissions =====
  const rolesList = module.map((r) => r.title);
  const [permissions, setPermissions] = useState(
    generateInitialPermissions(rolesList)
  );

  // ===== D) Retrieve userData from sessionStorage and update role columns =====
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (userData) {
      const { user_role, user_type, userValue } = userData;
      // Apply custom conditions based on the API-provided data:
      if (user_role.toLowerCase() === "sys admin") {
        if (userValue === "L0" && user_type === "entity_user") {
          // Condition 2:
          // For sys admin with L0 & entity_user, only allow the System Administrator column.
          setModule((prev) =>
            prev.map((r) =>
              r.title === "System Administrator"
                ? { ...r, disabled: false }
                : { ...r, disabled: true }
            )
          );
        } else if (user_type === "plant_user") {
          // Condition 3:
          // For sys admin with plant_user, disable only the Individual column.
          setModule((prev) =>
            prev.map((r) =>
              r.title === "Individual"
                ? { ...r, disabled: true }
                : { ...r, disabled: false }
            )
          );
        } else {
          // Default for sys admin (non‑special case):
          // Here, for example, we disable the System Administrator column (to avoid self‑modification)
          setModule((prev) =>
            prev.map((r) =>
              r.title === "System Administrator"
                ? { ...r, disabled: true }
                : { ...r, disabled: false }
            )
          );
        }
      } else if (user_role.toLowerCase() === "plant manager") {
        // For plant manager, disable System Administrator and Plant Manager columns.
        setModule((prev) =>
          prev.map((r) =>
            (r.title === "System Administrator" || r.title === "Plant Manager")
              ? { ...r, disabled: true }
              : { ...r, disabled: false }
          )
        );
      } else {
        // For all other roles, disable every column.
        setModule((prev) => prev.map((r) => ({ ...r, disabled: true })));
      }
      
    } else {
      console.warn("No userData found in sessionStorage.");
    }
  }, []);

  // ===== E) Fetch roles permissions if needed =====
  useEffect(() => {
    fetchAllRolesPermissions();
  }, []);

  const fetchAllRolesPermissions = async () => {
    try {
      // Retrieve authToken and userData from sessionStorage
      const authToken = sessionStorage.getItem("authToken") || "";
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log("User Data for API:", userData); // Debug: userData

      // Extract necessary fields from userData
      const userRole = userData?.user_role || "";
      const userType = userData?.user_type || "";
      const userValue = userData?.userValue || ""; // Corrected from user_value to userValue

      console.log("Extracted Values:");
      console.log("userRole:", userRole);
      console.log("userType:", userType);
      console.log("userValue:", userValue);

      // Construct the request body with correct mapping
      const requestBody = JSON.stringify({
        user_type: userType,
        user_role: userRole,
        user_value: userValue, // Mapping camelCase userValue to snake_case user_value
      });

      console.log("Request Body:", requestBody);

      // Make the API call
      const response = await fetch(
        "https://solar-api.antsai.in/api/role/factoryroles/allroles",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
          },
          body: requestBody
        }
      );

      console.log("API Response Status:", response.status);

      if (!response.ok) {
        const text = await response.text();
        console.error(`Error! status: ${response.status}`, text);
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Fetched data from API =>", data);

      if (!data.roles) {
        console.warn("No 'roles' key in the response", data);
        return;
      }

      // Build updated permissions
      setPermissions((prev) => {
        const updated = JSON.parse(JSON.stringify(prev)); // deep clone

        // data.roles => { "sys admin": {Admin:[...], ...}, "plant manager": {...}, ... }
        Object.entries(data.roles).forEach(([apiRoleName, modulesObj]) => {
          const localRoleTitle = mapApiRoleNameToLocalTitle(apiRoleName);
          if (!localRoleTitle) return; // skip if mismatch

          // modulesObj => { "Admin":[...], "Dashboard":[...], ...}
          Object.entries(modulesObj).forEach(([moduleName, widgetsArray]) => {
            const sectionKey = getSectionKeyFromModuleName(moduleName);
            if (!sectionKey) return; // skip unknown module

            // Deduplicate widgets by widget_name
            const uniqueWidgetsMap = new Map();
            widgetsArray.forEach((w) => {
              const widgetName = w.widget_name;
              const permission = w.permission ? w.permission.toLowerCase() : "no";
              uniqueWidgetsMap.set(widgetName, permission === "yes");
            });

            // Assign permissions based on unique widgets
            uniqueWidgetsMap.forEach((hasPermission, widgetName) => {
              if (
                updated[localRoleTitle] &&
                updated[localRoleTitle][sectionKey] &&
                updated[localRoleTitle][sectionKey].modules.hasOwnProperty(widgetName)
              ) {
                updated[localRoleTitle][sectionKey].modules[widgetName] = hasPermission;
              } else {
                if (updated[localRoleTitle] && updated[localRoleTitle][sectionKey]) {
                  updated[localRoleTitle][sectionKey].modules[widgetName] = hasPermission;
                } else {
                  console.warn(`Widget "${widgetName}" not found for role "${localRoleTitle}" in section "${moduleName}"`);
                }
              }
            });

            // Then check if "All Modules" is fully checked
            const allValues = Object.values(updated[localRoleTitle][sectionKey].modules);
            updated[localRoleTitle][sectionKey].all = allValues.every(Boolean);
          });
        });

        console.log("Updated Permissions after API:", updated);
        return updated;
      });

    } catch (error) {
      console.error("Error fetching roles data:", error);
    }
  };

    // Reset modal handler
    const handleResetConfirm = async () => {
      // Retrieve necessary values from sessionStorage
      const authToken = sessionStorage.getItem('authToken') || '';
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      const userRole = userData?.user_role || "";
      const userType = userData?.user_type || "";
      const userValue = userData?.userValue || "";
  
      // Prepare request body (mapping camelCase to snake_case if needed)
      const requestBody = JSON.stringify({
        user_type: userType,
        user_role: userRole,
        user_value: userValue,
      });
  
      try {
        // Call the API to fetch default permissions
        const response = await fetch(
          'https://solar-api.antsai.in/api/role/factoryroles/allroles',
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: requestBody,
          }
        );
  
        if (response.ok) {
          // Optionally update your permissions state here or simply refresh the page
          // For this example, we'll reload the page
          window.location.reload();
        } else {
          console.error('Failed to reset permissions');
        }
      } catch (error) {
        console.error('Error resetting permissions:', error);
      }
    };
  
    // Save modal handler (simulate a save operation)
    const handleSave = () => {
      // Perform your save operation here.
      // After the operation completes, show the Save modal.
      setShowSaveModal(true);
    };
  

  // ===== F) "All Modules" toggle per row & role =====
  const handleAllModulesChange = (roleTitle, sectionKey) => {
    const wasAllChecked = permissions[roleTitle][sectionKey].all;
    const newAllValue = !wasAllChecked;

    const newModules = { ...permissions[roleTitle][sectionKey].modules };
    Object.keys(newModules).forEach((m) => {
      newModules[m] = newAllValue;
    });

    setPermissions((prev) => ({
      ...prev,
      [roleTitle]: {
        ...prev[roleTitle],
        [sectionKey]: {
          all: newAllValue,
          modules: newModules
        }
      }
    }));
  };

  // ===== G) Individual submodule toggle =====
  const handleSubModuleChange = (roleTitle, sectionKey, subModule) => {
    const currentVal = permissions[roleTitle][sectionKey].modules[subModule];
    const newVal = !currentVal;

    const newModules = {
      ...permissions[roleTitle][sectionKey].modules,
      [subModule]: newVal
    };
    const allAreChecked = Object.values(newModules).every(Boolean);

    setPermissions((prev) => ({
      ...prev,
      [roleTitle]: {
        ...prev[roleTitle],
        [sectionKey]: {
          all: allAreChecked,
          modules: newModules
        }
      }
    }));
  };

  // ===== H) Accordion expand/collapse logic =====
  const toggleAll = () => {
    const allKeys = Object.keys(sectionModules);
    if (activeKeys.length === allKeys.length) setActiveKeys([]);
    else setActiveKeys(allKeys);
  };

  const toggleItem = (key) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  // ===== I) Only one role active at a time (optional) =====
  const handleModuleSelection = (selectedRole) => {
    const updated = module.map((r) => ({
      ...r,
      status: r.title === selectedRole.title
    }));
    setModule(updated);
    // console.log(`[handleModuleSelection] Selected Role: ${selectedRole.title}`);
  };

  // ===== J) Styles =====
  const Styles = () => (
    <style>{`
      .role-title {
        font-family: 'Open Sans', sans-serif; 
        font-weight: 700; 
        color: #000;
        font-size: 24px; 
        margin: 10px 0; 
        padding: 5px 10px;
      }
      .toggle-icon {
        font-size: 15px; 
        font-weight: bold; 
        border: 5px black;
        box-shadow: 0px 0px 0px 1px grey; 
        border-radius: 2px;
      }
      .module-row { 
        background-color: #f2f5f7; 
        height: 100px; 
        display: flex; 
        align-items: center; 
      }
      .admin-row, .plantoverview-row, .maintenance-row, .report-row {
        height: 50px; 
        background-color: white; 
        display: flex; 
        align-items: center;
      }
      .dashboard-row, .plant-row, .analytic-row {
        height: 50px; 
        background-color: #f2f5f7; 
        display: flex; 
        align-items: center;
      }
      .dashboard-body, .plant-body, .analytic-body { 
        background-color: #f2f5f7; 
      }
      .role-item { 
        border-left:2px; 
        border-right:2px; 
        border-top:0; 
        border-bottom:0; 
      }
      .accordion-role {
        padding: 0; 
        border: 0; 
        box-shadow: 0px 0px 1px 1px lightgrey; 
        border-radius: 2px;
        font-size: 18px; 
        font-weight: 400; 
        color: #0F0D0D;
      }
      .text-extra,
      .checkbox-text,
      .module-item {
        font-family: 'Open Sans', sans-serif; 
        font-weight: 700; 
        color: #000;
      }
      .text-extra { 
        font-size: 18px; 
      }
      .body-extra { 
        padding-bottom: 20px; 
      }
      .module-item {
        width: 95%; 
        height: 70px; 
        box-shadow: 1px 2px 3px #EFEFEF90;
        border: 1px solid grey; 
        border-radius: 9px; 
        background: white;
        display: flex; 
        justify-content: center; 
        align-items: center; 
        text-align: center;
      }
      .module-item:hover { 
        cursor: pointer; 
      }
      .role-button {
        padding: 2px 10px; 
        background-color:rgb(3, 3, 3); 
        color: #fff; 
        border: none; 
        border-radius:5px;
        box-shadow: 1px 2px 3px #3435442F; 
        font-size: 18px; 
        cursor: pointer;
      }
      .role-input {
        padding:2px 5px; 
        border: 1px solid #C2CAD2; 
        border-radius:9px;
        box-shadow: 1px 2px 3px #EFEFEF90; 
        font-size: 20px;
      }
      .module-select { 
        border-color:#167DB0; 
        border-width:5px; 
      }
      /* Update the disabled column style */
      .disabled-column {
        opacity: 0.5;
        cursor: not-allowed;
      }
      /* New class to force disabled styling on a field even when its column is enabled */
      .disabled-field {
        font-weight: 400 !important;
        color: #aaa !important;
        cursor: not-allowed !important;
      }
      .module-row .col,
      .role-item .col {
        border-width: 0px 0px 0px 0px;
        box-shadow: -1px 0px 0px lightgray;
        border-style: solid;
        border-color: grey;
        height: inherit;
        display: flex;
        align-items: center;
      }
      .clickable-row {
        cursor: pointer;
      }
    `}</style>
  );

  // ===== K) Icons for toggles =====
  const iconForItem = (key) =>
    activeKeys.includes(key)
      ? (
        <RemoveIcon
          className="toggle-icon"
          style={{ color: "black", background: "white" }}
        />
      ) : (
        <AddIcon
          className="toggle-icon"
          style={{ color: "black", background: "lightgrey" }}
        />
      );

  const iconForAll = () =>
    activeKeys.length === 0 ? (
      <AddIcon
        className="toggle-icon"
        style={{ color: "black", background: "lightgrey" }}
      />
    ) : (
      <RemoveIcon
        className="toggle-icon"
        style={{ color: "black", background: "white" }}
      />
    );

    
  // ===== L) Render =====
  return (
    <Container fluid className="mw-100">
      <Styles />

      <Row className="mt-2 ml-1 pt-2">
        <div className="d-flex justify-content-left align-items-center role-title">
          {/* <span>Role Based Access</span> */}
        </div>
      </Row>

      <Row>
        <Accordion activeKey={activeKeys} className="accordion-role">
          {/* TOP ROW: Roles (columns) */}
          <Row className="module-row px-0 mx-0">
            <Col>
              <div
                className="d-flex flex-row align-items-center gap-2 clickable-row"
                onClick={toggleAll}
              >
                {iconForAll()}
                <div className="text-extra"> Module</div>
              </div>
            </Col>
            {module.map((m) => (
              <Col className="module-col" key={m.title}>
                <div
                  className={`module-item text-extra ${m.status ? "" : "disabled-column"}`}
                  // Only trigger column selection if the column is not disabled
                  onClick={() => { if (!m.disabled) handleModuleSelection(m); }}
                >
                  {m.title}
                </div>
              </Col>
            ))}
          </Row>

          {/* Each ACCORDION ITEM: Admin=1, Dashboard=2, etc. */}
          {Object.keys(sectionModules).map((sectionKey) => {
            let label = "";
            switch (sectionKey) {
              case "1":
                label = "Admin";
                break;
              case "2":
                label = "Dashboard";
                break;
              case "3":
                label = "Plant Overview";
                break;
              case "4":
                label = "Plant";
                break;
              case "5":
                label = "Maintenance";
                break;
              case "6":
                label = "Analytics";
                break;
              case "7":
                label = "Report";
                break;
              default:
                label = "Section";
            }

            // Classes for row & body
            let rowClass = "";
            let bodyClass = "";
            switch (sectionKey) {
              case "1":
                rowClass = "admin-row";
                bodyClass = "admin-body";
                break;
              case "2":
                rowClass = "dashboard-row";
                bodyClass = "dashboard-body";
                break;
              case "3":
                rowClass = "plantoverview-row";
                bodyClass = "plantoverview-body";
                break;
              case "4":
                rowClass = "plant-row";
                bodyClass = "plant-body";
                break;
              case "5":
                rowClass = "maintenance-row";
                bodyClass = "maintenance-body";
                break;
              case "6":
                rowClass = "analytic-row";
                bodyClass = "analytic-body";
                break;
              case "7":
                rowClass = "report-row";
                bodyClass = "report-body";
                break;
              default:
                rowClass = "";
                bodyClass = "";
            }

            // Determine if this section (module) should show disabled styling.
            // For admin modules (sectionKey "1"), we want to add the "disabled-field" class.
            const isAdminModule = sectionKey === "1";

            return (
              <Accordion.Item
                key={sectionKey}
                eventKey={sectionKey}
                className="role-item"
              >
                {/* HEADER ROW: module label + "All Modules" checkboxes */}
                <Row className={`${rowClass} px-0 mx-0`}>
                  <Col>
                    <div
                      className="d-flex flex-row align-items-center gap-2 clickable-row"
                      onClick={() => toggleItem(sectionKey)}
                    >
                      {iconForItem(sectionKey)}
                      <div className="text-extra">{label}</div>
                    </div>
                  </Col>

                  {module.map((r) => {
                    if (!permissions[r.title] || !permissions[r.title][sectionKey]) {
                      console.warn(`Permissions not found for role "${r.title}" in section "${sectionKey}"`);
                      return null;
                    }
                    const allChecked = permissions[r.title][sectionKey].all;
                    return (
                      <Col
                        key={r.title}
                        className={`${r.status ? "" : "disabled-column"} ${isAdminModule ? "disabled-field" : ""}`}
                      >
                        <div className={`d-flex flex-row gap-2 checkbox-text ${isAdminModule ? "disabled-field" : ""}`}>
                          <Form.Check
                            // Disable the "All Modules" checkbox if the role column is disabled
                            // or if this is the Admin module (sectionKey "1")
                            disabled={r.disabled || isAdminModule}
                            className="table-column-checkbox"
                            checked={allChecked}
                            onChange={() =>
                              handleAllModulesChange(r.title, sectionKey)
                            }
                          />
                          All Modules
                        </div>
                      </Col>
                    );
                  })}
                </Row>

                {/* BODY: submodules => checkboxes for each role */}
                <Accordion.Body className={`py-0 px-0 ${bodyClass}`}>
                  <Row className="px-0 mx-0">
                    <Col />
                    {module.map((r) => {
                      if (!permissions[r.title] || !permissions[r.title][sectionKey]) {
                        console.warn(`Permissions not found for role "${r.title}" in section "${sectionKey}"`);
                        return null;
                      }
                      return (
                        <Col
                          key={r.title}
                          className={`${r.status ? "" : "disabled-column"} ${isAdminModule ? "disabled-field" : ""}`}
                        >
                          <Stack direction="vertical" gap={2}>
                            {sectionModules[sectionKey].map((subModule, idx) => {
                              const checkedVal =
                                permissions[r.title][sectionKey].modules[subModule] || false;
                              return (
                                <div
                                  className={`d-flex flex-row gap-2 checkbox-text ${isAdminModule ? "disabled-field" : ""}`}
                                  key={idx}
                                >
                                  <Form.Check
                                    // Disable individual module checkboxes for the Admin module (sectionKey "1")
                                    // or if the role column is disabled.
                                    disabled={r.disabled || isAdminModule}
                                    className="table-column-checkbox"
                                    checked={checkedVal}
                                    onChange={() =>
                                      handleSubModuleChange(
                                        r.title,
                                        sectionKey,
                                        subModule
                                      )
                                    }
                                  />
                                  {subModule}
                                </div>
                              );
                            })}
                            <div className="body-extra"></div>
                          </Stack>
                        </Col>
                      );
                    })}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Row>

      {/* Footer: role name input & buttons */}
      <Row className="my-3">
        <Col className="p-0 m-0">
          {/* Example: You might want to include an input and a button here */}
          {/* <div className="d-flex justify-content-left align-items-center gap-2">
            <input className="role-input" type="text" placeholder="Role Name" />
            <button className="role-button">Save as New Role</button>
          </div> */}
        </Col>
        <Col className="p-0 m-0">
          <div className="d-flex justify-content-end align-items-center gap-2">
          <Button className="role-button px-4" variant="secondary" onClick={() => setShowResetModal(true)} style={{ backgroundColor: 'black', borderColor: 'black' }}>
          Reset
          </Button>{' '}
          <Button className="role-button px-4" variant="primary" onClick={handleSave} style={{ backgroundColor: 'black', borderColor: 'black' }}>
          Save
          </Button>
              {/* Reset Confirmation Modal */}
      <Modal
        show={showResetModal}
        onHide={() => setShowResetModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Permissions?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          All permissions will be reset to the default roles. Are you sure you want to
          proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowResetModal(false)} style={{ backgroundColor: 'black', borderColor: 'black' }}>
            No
          </Button>
          <Button variant="primary" onClick={handleResetConfirm} style={{ backgroundColor: 'black', borderColor: 'black' }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Save Confirmation Modal */}
      <Modal
        show={showSaveModal}
        onHide={() => setShowSaveModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Roles permission set successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSaveModal(false)} style={{ backgroundColor: 'black', borderColor: 'black' }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Roles;
