import React, { useState, useEffect, useContext } from "react";
import { PermissionsContext } from "../../context/PermissionsContext";  // Adjust path if needed
import "./Entity.css";
import { Col, Container, Nav, NavItem, NavLink, Row, Stack, Tab } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Divider } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components / Pages
import AdminBottomHeaderBar from "../../dashboard/navbar/AdminBottomHeaderBar";
import EntityTable from "./EntityTable";
import EntityAddPage from "./EntityAdd";
import EntityEditPage from "./EntityEdit";
import PlantTable from "../plant/PlantTable";
import AdminPlantAdd from "../plant/PlantAdd";
import AdminPlantEdit from "../plant/AdminPlantEdit";
import UserTable from "../user/UserTable";
import UserAddPage from "../user/UserAdd";
import UserEditPage from "../user/UserEdit";
import Roles from "../role/Roles";
import AdminReportTable from "../report/AdminReportTable";
import AdminReportAdd from "../report/AdminReportAdd";
import CatalogTable from "../catalog/CatalogTable";
import SettingsForm from "../settings/Settings";

const Entity = () => {
     // Create a state to track the selected filter for users
  const [userFilter, setUserFilter] = useState(""); // Initially empty or a default value
    // Get user type from session data
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const isPlantUser = userData?.user_type === "plant_user";
    const navigate = useNavigate();
    const { permissions } = useContext(PermissionsContext);
    const { t } = useTranslation(["entity", "plant", "menu", "user"]);

    // === Permissions Utility Function ===
    const hasPermission = (moduleName, widgetName) => {
        if (!permissions || !permissions.modules) return false;
        const moduleArray = permissions.modules[moduleName];
        if (!moduleArray) return false;
        const widgetObj = moduleArray.find((w) => w.widget_name === widgetName);
        return widgetObj && widgetObj.permission === "yes";
    };

    // === State Management ===
    const [active, setActive] = React.useState(1);
    const [rowid, setRowid] = React.useState(1);
    const [rowData, setRowData] = React.useState();
    const [tab, setTab] = React.useState("first");

    // === View Control ===
const SetView = (activeValue, tabValue) => {
    sessionStorage.setItem(
        "screens",
        JSON.stringify({
            active: activeValue,
            screen: tabValue === undefined ? tab : tabValue
        })
    );
    setActive(activeValue);
    if (tabValue) setTab(tabValue);
};

    const setEditRow = (rowData) => {
        setRowData(rowData);
        SetView(9); // Switch to User Edit
    };

    const SetRowIdInView = (rowIdParam) => {
        setRowid(rowIdParam);
    };

// --- Load Last Active State From Session (run only once) ---
useEffect(() => {
    const activeScren = JSON.parse(sessionStorage.getItem("screens"));
    if (activeScren !== null) {
        setTab(activeScren.screen !== "" ? activeScren.screen : "first");
        setActive(activeScren.active !== "" ? parseInt(activeScren.active) : 1);
    }
}, []);

        // === Adjust Default View Based on Permissions ===
        useEffect(() => {
            if (permissions) {
                // Check if the current default view (Entity page with active = 1) is allowed.
                // If not, choose an alternative default view.
                if (!hasPermission("Admin", "Entity")) {
                    if (hasPermission("Admin", "Plant")) {
                        SetView(4, "second"); // Default to Plant page
                    } else if (hasPermission("Admin", "Role")) {
                        SetView(13, "third"); // Default to Role page
                    } else if (hasPermission("Admin", "User")) {
                        SetView(7, "fourth"); // Default to User page
                    } else if (hasPermission("Admin", "Report Templates")) {
                        SetView(10, "fifth"); // Default to Report Templates
                    } else if (hasPermission("Admin", "Catalog")) {
                        SetView(12, "sixth"); // Default to Catalog
                    } else if (hasPermission("Admin", "Settings")) {
                        SetView(20, "seventh"); // Default to Settings
                    } else {
                        // If none of the above permissions are granted,
                        // redirect to a “Not Authorized” or error page.
                        navigate("/not-authorized");
                    }
                }
            }
        }, [permissions, navigate]);
    
        // === Decide Which Component to Render Based on "active" ===
        const ActiveView = () => {
            switch (active) {
                // ---------- Entity -----------            
                case 1:
                    return <EntityTable stateChanger={SetView} rowId={SetRowIdInView} />;
                case 2:
                    return <EntityAddPage stateChanger={SetView} />;
                case 3:
                    return <EntityEditPage stateChanger={SetView} rowId={rowid} />;
    
                // ---------- Plant -----------            
                case 4:
                    return <PlantTable stateChanger={SetView} rowId={SetRowIdInView} />;
                case 5:
                    return <AdminPlantAdd stateChanger={SetView} />;
                case 6:
                    return <AdminPlantEdit stateChanger={SetView} rowId={rowid} />;
    
                // ---------- User ------------            
                case 7:
                    return <UserTable stateChanger={setEditRow} rowId={SetRowIdInView}  onFilterChange={setUserFilter} />;
                case 8:
                    return <UserAddPage stateChanger={SetView} />;
                case 9:
                    return <UserEditPage stateChanger={SetView} rowData={rowData} rowId={rowid} />;
    
                // --------- Reports ----------            
                case 10:
                    return <AdminReportTable stateChanger={SetView} />;
                case 11:
                    return <AdminReportAdd stateChanger={SetView} />;
    
                // --------- Catalog ----------            
                case 12:
                    return <CatalogTable stateChanger={SetView} />;
    
                // --------- Settings ---------            
                case 20:
                    return <SettingsForm />;
    
                // --------- Roles ------------            
                case 13: // <- NEW: Roles            
                    return <Roles />;
    
                // --------- Default ----------            
                default:
                    return <EntityTable stateChanger={SetView} rowId={SetRowIdInView} />;
            }
        };

    // === Decide Which Component to Render Based on "active" ===
    // const ActiveView = () => {
    //     switch (active) {
    //         // ---------- Entity -----------
    //         case 1:
    //             return <EntityTable stateChanger={SetView} rowId={SetRowIdInView} />;
    //         case 2:
    //             return <EntityAddPage stateChanger={SetView} />;
    //         case 3:
    //             return <EntityEditPage stateChanger={SetView} rowId={rowid} />;

    //         // ---------- Plant -----------
    //         case 4:
    //             return <PlantTable stateChanger={SetView} rowId={SetRowIdInView} />;
    //         case 5:
    //             return <AdminPlantAdd stateChanger={SetView} />;
    //         case 6:
    //             return <AdminPlantEdit stateChanger={SetView} rowId={rowid} />;

    //         // ---------- User ------------
    //         case 7:
    //             return <UserTable stateChanger={setEditRow} rowId={SetRowIdInView} />;
    //         case 8:
    //             return <UserAddPage stateChanger={SetView} />;
    //         case 9:
    //             return <UserEditPage stateChanger={SetView} rowData={rowData} rowId={rowid} />;

    //         // --------- Reports ----------
    //         case 10:
    //             return <AdminReportTable stateChanger={SetView} />;
    //         case 11:
    //             return <AdminReportAdd stateChanger={SetView} />;

    //         // --------- Catalog ----------
    //         case 12:
    //             return <CatalogTable stateChanger={SetView} />;

    //         // --------- Settings ---------
    //         case 20:
    //             return <SettingsForm />;

    //         // --------- Roles ------------
    //         case 13: // <- NEW: Roles
    //             return <Roles />;

    //         // --------- Default ----------
    //         default:
    //             return <EntityTable stateChanger={SetView} rowId={SetRowIdInView} />;
    //     }
    // };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <AdminBottomHeaderBar />
            <Container className="mw-100">
                <Row>
                    {/* 
                        Tie the tab state to "activeKey". 
                        We do NOT rely on defaultActiveKey because we manage it ourselves. 
                    */}
                    <Tab.Container id="left-tabs-example" activeKey={tab}>
                        <Container className="mw-100">
                            <Stack gap={0} direction="vertical">
                                {/* ================================================
                                    Navigation Tabs
                                ================================================ */}
                                <Nav as="div" variant="underline" className="flex-row tab-nav">
                                    {/* =============== ENTITY =============== */}
                                    {hasPermission("Admin", "Entity") && (
                                        <NavItem>
                                            <NavLink
                                                eventKey="first"
                                                onClick={() => SetView(1, "first")}
                                            >
                                                {t("menu:entity")}
                                            </NavLink>
                                        </NavItem>
                                    )}

                                    {/* =============== PLANT ================ */}
                                    {hasPermission("Admin", "Plant") && (
                                        <NavItem>
                                            <NavLink
                                                eventKey="second"
                                                onClick={() => SetView(4, "second")}
                                            >
                                                {t("menu:plant")}
                                            </NavLink>
                                        </NavItem>
                                    )}

                                    {/* ================ ROLE ================ */}
                                    {hasPermission("Admin", "Role") && (
                                        <NavItem>
                                            <NavLink
                                                eventKey="third"
                                                onClick={() => SetView(13, "third")}
                                            >
                                                {t("menu:role")}
                                            </NavLink>
                                        </NavItem>
                                    )}

                                    {/* ================ USER ================ */}
                                    {hasPermission("Admin", "User") && (
                                        <NavItem>
                                            <NavLink
                                                eventKey="fourth"
                                                onClick={() => SetView(7, "fourth")}
                                            >
                                                {t("menu:user")}
                                            </NavLink>
                                        </NavItem>
                                    )}

                                    {/* ========= REPORT TEMPLATES =========== */}
                                    {hasPermission("Admin", "Report Templates") && (
                                        <NavItem>
                                            <NavLink
                                                eventKey="fifth"
                                                onClick={() => SetView(10, "fifth")}
                                            >
                                                {t("menu:report.template")}
                                            </NavLink>
                                        </NavItem>
                                    )}

                                    {/* ================ CATALOG ============= */}
                                    {hasPermission("Admin", "Catalog") && (
                                        <NavItem>
                                            <NavLink
                                                eventKey="sixth"
                                                onClick={() => SetView(12, "sixth")}
                                            >
                                                Catalog
                                            </NavLink>
                                        </NavItem>
                                    )}

                                    {/* =============== SETTINGS ============= */}
                                    {hasPermission("Admin", "Settings") && (
                                        <NavItem>
                                            <NavLink
                                                eventKey="seventh"
                                                onClick={() => SetView(20, "seventh")}
                                            >
                                                {t("menu:setting")}
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </Nav>

                                <Divider className="bg-black" />

                                {/* ================================================
                                    Tab Content Area
                                ================================================ */}
                                <Tab.Content className="mt-2 pt-2">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        {/* ============ ENTITY TAB ============ */}
                                        <Tab.Pane eventKey="first">
                                            {active === 1 && (
                                                <Container fluid className="mw-100">
                                                    <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                        <Col className="px-0">
                                                            <h1 className="header-title">
                                                                {t("entity:entity.list")}
                                                            </h1>
                                                        </Col>
                                                        <Col className="px-0 d-flex justify-content-end">
                                                            {/* Add New Entity Button */}
                                                            {hasPermission("Admin", "Add Entity") && (
                                                                <button
                                                                    className="add-entity-button"
                                                                    onClick={() => SetView(2)}
                                                                >
                                                                    {t("entity:entity.addnew")}
                                                                </button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )}
                                            {ActiveView()}
                                        </Tab.Pane>

                                        {/* ============= PLANT TAB ============ */}
                                        <Tab.Pane eventKey="second">
                                            {active === 4 && (
                                                <Container fluid className="mw-100">
                                                    <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                        <Col className="px-0">
                                                            <h1 className="header-title">
                                                                {t("plant:plantList")}
                                                            </h1>
                                                        </Col>
                                                        <Col className="px-0 d-flex justify-content-end">
                                                            {/* Plant Add New Button */}
                                                            {hasPermission("Admin", "Add Plant") && (
                                                                <button
                                                                    className="add-plant-button"
                                                                    onClick={() => SetView(5)}
                                                                >
                                                                    {t("plant:addNewPlant")}
                                                                </button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )}
                                            {ActiveView()}
                                        </Tab.Pane>

                                        {/* ============= ROLE TAB ============= */}
                                        <Tab.Pane eventKey="third">
                                            {active === 13 && (
                                                <Container fluid className="mw-100">
                                                    <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                        <Col className="px-0">
                                                            <h1 className="header-title">
                                                                {t("menu:role")}
                                                            </h1>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )}
                                            {ActiveView()}
                                        </Tab.Pane>

                                        {/* ============= USER TAB ============= */}
                                        <Tab.Pane eventKey="fourth">
                                            {active === 7 && (
                                                <Container fluid className="mw-100">
                                                    <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                        <Col className="px-0">
                                                            <h1 className="header-title">
                                                                {t("user:user.list")}
                                                            </h1>
                                                        </Col>
                                                        <Col className="px-0 d-flex justify-content-end">
                                                            {/* User Add New Button */}
                                                            {hasPermission("Admin", "Add User") && 
                                                            (userFilter === "Employee" || isPlantUser) && (
                                                            <button
                                                                className="add-entity-button"
                                                                onClick={() => SetView(8)}
                                                            >
                                                                {t("user:user.addnew")}
                                                            </button>
                                                            )}
                                                           {/* {hasPermission("Admin", "Add User") && userFilter === "Employee" && (
                                                            <button
                                                                className="add-entity-button"
                                                                onClick={() => SetView(8)}
                                                            >
                                                                {t("user:user.addnew")}
                                                            </button>
                                                            )}                 */}
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )}
                                            {ActiveView()}
                                        </Tab.Pane>

                                        {/* ========= REPORT TEMPLATE TAB ====== */}
                                        <Tab.Pane eventKey="fifth">
                                            {ActiveView()}
                                        </Tab.Pane>

                                        {/* ============= CATALOG TAB ========== */}
                                        <Tab.Pane eventKey="sixth">
                                            {ActiveView()}
                                        </Tab.Pane>

                                        {/* ============= SETTINGS TAB ========= */}
                                        <Tab.Pane eventKey="seventh">
                                            {ActiveView()}
                                        </Tab.Pane>

                                    </LocalizationProvider>
                                </Tab.Content>
                            </Stack>
                        </Container>
                    </Tab.Container>
                </Row>
            </Container>
        </div>
    );
};

export default Entity;
