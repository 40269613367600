import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup
} from "react-bootstrap";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MessageContext } from "../../App"; // Adjust path as needed

export default function Profile() {
  // 1) PROFILE HEADER STATE
  const [profilePic, setProfilePic] = useState("");

  // 2) PERSONAL INFORMATION
  const [isEditingPersonal, setIsEditingPersonal] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    entityName: ""
  });

  const userFullName =
    `${personalInfo.firstName} ${personalInfo.lastName}`.trim() || "No Name";
  const userRole = personalInfo.role || "No Role";
  const userEntity = personalInfo.entityName || "No Entity";

  // 3) TEMPERATURE SETTINGS
  const [isEditingTemp, setIsEditingTemp] = useState(false);
  const [temperatureUnit, setTemperatureUnit] = useState("C");

  // 4) CHANGE PASSWORD
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  // Access your global message/snackbar context
  const onMessageChange = useContext(MessageContext);

  // Retrieve userData from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userEmail = userData?.email || "";
  const authToken = userData?.token || "";
  const userId = userData?.userId || "";

  // -- FETCH BASIC PROFILE INFO --
  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await fetch(
        `https://solar-api.antsai.in/profile?email=${encodeURIComponent(
          userEmail
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
          }
        }
      );

      const data = await response.json();
      console.log("Response data:", data);

      // Map data to local state
      setPersonalInfo({
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        email: data.email || "",
        phone: data.mobileNumber || "",
        role: data.role || "",
        entityName: data.entityName || ""
      });

      // Parse temperature
      if (data.temperature?.toLowerCase().includes("celsius")) {
        setTemperatureUnit("C");
      } else if (data.temperature?.toLowerCase().includes("fahrenheit")) {
        setTemperatureUnit("F");
      }

      // IMPORTANT: We do NOT set profilePic here. 
      // We'll handle that in a separate effect below.
      
    } catch (err) {
      console.error("Error fetching profile data:", err);
      onMessageChange({
        type: "Danger",
        info: "Failed to fetch profile data. Please try again."
      });
    }
  };

  // -- SET PROFILE PIC DIRECTLY FROM userId --
  useEffect(() => {
    if (userId) {
      // Build the direct URL to the user's profile pic
      setProfilePic(`https://solar-api.antsai.in/api/profilePic/${userId}`);
    }
  }, [userId]);

  /**
   * Handle selecting AND uploading a new profile picture.
   * We'll still POST to /api/uploadProfilePic, then show the new image.
   */
  const handleProfilePicChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // 1) Validate file
    const validTypes = ["image/jpeg", "image/png"];
    if (!validTypes.includes(file.type)) {
      onMessageChange({
        type: "Warning",
        info: "Please select a valid JPG or PNG image."
      });
      return;
    }

    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      onMessageChange({
        type: "Warning",
        info: "File size exceeds 5 MB limit."
      });
      return;
    }

    // 2) Optional local preview
    try {
      const reader = new FileReader();
      reader.onload = (evt) => {
        setProfilePic(evt.target.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error reading image:", error);
      onMessageChange({
        type: "Danger",
        info: "Failed to process image. Please try again."
      });
    }

    // 3) Upload to server
    try {
        const formData = new FormData();
        formData.append("email", userEmail); 
        formData.append("user_id", userId);  // <-- Must be user_id, not userId
        formData.append("profilePic", file);

        console.log("email =>", userEmail);
        console.log("userId =>", userId);
      const uploadResponse = await fetch(
        "https://solar-api.antsai.in/api/uploadProfilePic",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          body: formData
        }
      );

      const uploadResult = await uploadResponse.json();
      console.log("Upload result:", uploadResult);

      if (uploadResponse.ok) {
        onMessageChange({
          type: "Success",
          info: "Profile picture uploaded successfully!"
        });
        // Force the <img> tag to load the new pic from the server.
        setProfilePic(
          `https://solar-api.antsai.in/api/profilePic/${userId}?t=${Date.now()}`
        );
      } else {
        onMessageChange({
          type: "Danger",
          info: uploadResult?.message || "Failed to upload profile picture."
        });
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      onMessageChange({
        type: "Danger",
        info: "Error uploading profile picture. Please try again."
      });
    }
  };

  // PERSONAL INFO Save Handler
  const handlePersonalSave = () => {
    setIsEditingPersonal(false);
    // (Call your API to update personal info if needed)
    onMessageChange({
      type: "Success",
      info: "Personal information saved successfully!"
    });
  };

  // TEMPERATURE SETTINGS Save Handler
  const handleTempSave = async () => {
    setIsEditingTemp(false);
    const payloadUnit = temperatureUnit === "C" ? "Centigrade" : "Fahrenheit";

    try {
      const response = await fetch("https://solar-api.antsai.in/updateTempUnit", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({
          email: userEmail,
          temp_unit: payloadUnit
        })
      });

      const data = await response.json();
      console.log("updateTempUnit result:", data);

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: `Temperature unit updated to ${payloadUnit}.`
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: data?.message || "Failed to update temperature unit."
        });
      }
    } catch (err) {
      console.error("Error updating temperature unit:", err);
      onMessageChange({
        type: "Danger",
        info: "Failed to update temperature. Please try again."
      });
    }
  };

// CHANGE PASSWORD Save Handler
const handlePasswordSave = async () => {
    // 1) Check strong password requirements
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&()_+{}\[\]:;<>,.?~\-=/\\]).{8,15}$/;
    
    if (!passwordRegex.test(newPassword)) {
      onMessageChange({
        type: "Warning",
        info: "Password must be 8–15 characters long, include at least 1 uppercase letter, 1 digit, and 1 special character."
      });
      return;
    }
  
    // 2) Check if passwords match
    if (newPassword !== confirmPassword) {
      onMessageChange({
        type: "Warning",
        info: "Passwords do not match!"
      });
      return;
    }
  
    // 3) If all validations pass, POST to your backend
    try {
      const response = await fetch("https://solar-api.antsai.in/forgot-pass", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userEmail,      // from your session
          newPassword: newPassword
        })
      });
  
      const data = await response.json();
      console.log("Change password response:", data);
  
      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: data?.message || "Password changed successfully!"
        });
      } else {
        onMessageChange({
          type: "Danger",
          info: data?.message || "Error changing password."
        });
      }
    } catch (error) {
      console.error("Error changing password:", error);
      onMessageChange({
        type: "Danger",
        info: "An error occurred while updating your password."
      });
    }
  
    // 4) Reset state and close editing form
    setIsEditingPassword(false);
    setNewPassword("");
    setConfirmPassword("");
  };
  

  // -- RENDER --
  return (
    <Container className="py-4">
      <h4 className="mb-4">My Profile</h4>

      {/* 1) PROFILE HEADER CARD */}
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex align-items-start">
            {/* Profile Picture Container */}
            <div className="profile-pic-container me-3">
              {profilePic ? (
                <img
                  src={profilePic}
                  alt="Profile"
                  className="profile-pic-img"
                />
              ) : (
                <div className="profile-pic-placeholder">No Image</div>
              )}

              {/* Hidden file input */}
              <input
                type="file"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                id="profilePicInput"
                onChange={handleProfilePicChange}
              />

              {/* Overlaid Edit Button */}
              <label
                htmlFor="profilePicInput"
                className="profile-edit-overlay btn btn-light btn-sm"
              >
                Edit <EditOutlinedIcon fontSize="small" />
              </label>
            </div>

            <div>
              <h6 className="mb-1">{userFullName}</h6>
              <div>{userRole}</div>
              <div style={{ fontSize: "0.9rem" }}>{userEntity}</div>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* 2) PERSONAL INFORMATION CARD */}
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="mb-0">Personal Information</h6>
            {/* {!isEditingPersonal ? (
              <Button
                variant="outline-secondary"
                onClick={() => setIsEditingPersonal(true)}
              >
                Edit <EditOutlinedIcon fontSize="small" />
              </Button>
            ) : (
              <Button variant="dark" onClick={handlePersonalSave}>
                Save
              </Button>
            )} */}
          </div>

          {!isEditingPersonal ? (
            <>
              <Row className="mb-3">
                <Col md={6}>
                  <small className="text-muted">First Name</small>
                  <div>{personalInfo.firstName}</div>
                </Col>
                <Col md={6}>
                  <small className="text-muted">Last Name</small>
                  <div>{personalInfo.lastName}</div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <small className="text-muted">Email address</small>
                  <div>{personalInfo.email}</div>
                </Col>
                <Col md={6}>
                  <small className="text-muted">Phone</small>
                  <div>{personalInfo.phone}</div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <small className="text-muted">Role</small>
                  <div>{personalInfo.role}</div>
                </Col>
                <Col md={6}>
                  <small className="text-muted">Entity Name</small>
                  <div>{personalInfo.entityName}</div>
                </Col>
              </Row>
            </>
          ) : (
            <Form>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={personalInfo.firstName}
                    onChange={(e) =>
                      setPersonalInfo((prev) => ({
                        ...prev,
                        firstName: e.target.value
                      }))
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={personalInfo.lastName}
                    onChange={(e) =>
                      setPersonalInfo((prev) => ({
                        ...prev,
                        lastName: e.target.value
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    value={personalInfo.email}
                    onChange={(e) =>
                      setPersonalInfo((prev) => ({
                        ...prev,
                        email: e.target.value
                      }))
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    value={personalInfo.phone}
                    onChange={(e) =>
                      setPersonalInfo((prev) => ({
                        ...prev,
                        phone: e.target.value
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    type="text"
                    value={personalInfo.role}
                    onChange={(e) =>
                      setPersonalInfo((prev) => ({
                        ...prev,
                        role: e.target.value
                      }))
                    }
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Entity Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={personalInfo.entityName}
                    onChange={(e) =>
                      setPersonalInfo((prev) => ({
                        ...prev,
                        entityName: e.target.value
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Card.Body>
      </Card>

      {/* 3) TEMPERATURE SETTINGS CARD */}
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="mb-0">Temperature Settings</h6>
            {!isEditingTemp ? (
              <Button
                variant="outline-secondary"
                onClick={() => setIsEditingTemp(true)}
              >
                Edit <EditOutlinedIcon fontSize="small" />
              </Button>
            ) : (
              <Button variant="dark" onClick={handleTempSave}>
                Save
              </Button>
            )}
          </div>

          {!isEditingTemp ? (
            <Row>
              <Col md={12}>
                <small className="text-muted">Temperature</small>
                <div>
                  {temperatureUnit === "F"
                    ? "Fahrenheit (°F)"
                    : "Celsius (°C)"}
                </div>
              </Col>
            </Row>
          ) : (
            <Form>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Temperature</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="°C (Celsius)"
                      name="temp"
                      type="radio"
                      id="tempC"
                      value="C"
                      checked={temperatureUnit === "C"}
                      onChange={(e) => setTemperatureUnit(e.target.value)}
                    />
                    <Form.Check
                      inline
                      label="°F (Fahrenheit)"
                      name="temp"
                      type="radio"
                      id="tempF"
                      value="F"
                      checked={temperatureUnit === "F"}
                      onChange={(e) => setTemperatureUnit(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Card.Body>
      </Card>

      {/* 4) CHANGE PASSWORD CARD */}
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="mb-0">Change Password</h6>
            {!isEditingPassword ? (
              <Button
                variant="outline-secondary"
                onClick={() => setIsEditingPassword(true)}
              >
                Edit <EditOutlinedIcon fontSize="small" />
              </Button>
            ) : (
              <Button variant="dark" onClick={handlePasswordSave}>
                Save
              </Button>
            )}
          </div>

          {!isEditingPassword ? (
            <p className="text-muted">
              Set a new password for your account (currently disabled).
            </p>
          ) : (
            <Form>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>New Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showNewPass ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowNewPass(!showNewPass)}
                    >
                      {showNewPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </Button>
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <Form.Label>Confirm New Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showConfirmPass ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    >
                      {showConfirmPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          )}
        </Card.Body>
      </Card>

      {/* Inline CSS for the Profile Picture Overlay */}
      <style>{`
        .profile-pic-container {
          position: relative;
          width: 70px;
          height: 70px;
          flex-shrink: 0;
          border-radius: 50%;
          overflow: hidden;
        }
        .profile-pic-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
        .profile-pic-placeholder {
          width: 100%;
          height: 100%;
          background: #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8rem;
          color: #666;
          border-radius: 50%;
        }
        .profile-edit-overlay {
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translate(20%, 20%);
          display: none;
          cursor: pointer;
          padding: 0.2rem 0.5rem;
          font-size: 0.7rem;
          border-radius: 0.75rem;
          background: #fff;
          border: 1px solid #ccc;
        }
        .profile-pic-container:hover .profile-edit-overlay {
          display: inline-flex;
          align-items: center;
        }
      `}</style>
    </Container>
  );
}
