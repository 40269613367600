import { Button, Col, Container, Form, Image, Row, Stack } from "react-bootstrap";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { green } from "@mui/material/colors";
import GMap from "./../utilities/AddMapFromLibrary";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import BottomHeaderBar from "./../../dashboard/navbar/BottomHeaderBar";
// import SolarNavbar from "./../../dashboard/navbar/SolarNavbar";


function Styles() {
    return (
        <style>{`
               .row-card{
                 background-color:#f2f5f7;
                 text-align: left;
                 align-items: center;
                 justify-content-center;
                 box-shadow: 0px 0px 3px 1px #d7dadb;
                 border-radius: 5px;
               }    

               .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px
                }
            
                .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

                .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }

              .card-height{
               height: 88% !important
              }
              .email-text{
               text-wrap:noWrap;
               font-size:16px !important;
               color:#4773a9 !important;
               }
               .browse-image-text{
               color:#F75A34;
               font-size:12px;
               }
               
               
        `}

        </style>
    );
}


function EditPlant() {
    const [latlng, setLatLng] = useState({ lat: '', lng: '' })
    const [address, setAddress] = useState({
        country: '',
        region: '',
        state: '',
        district: '',
        addressOne: '',
        addressTwo: '',
        pincode: ''
    })
    const [country, setCountry] = useState(['india','soudi arabia', 'newyork', ])
    const [regions, setRegions] = useState([{"country":"india","regions":["Uttar Pradesh","punjab","karnataka"]}])
    const [states, setStates] = useState([{"region":"karnataka","states":["Uttar Pradesh","punjab","karnataka"]},{"region":"tamilnadu","states":["Uttar Pradesh","punjab","karnataka"]}])
    const [districts, setDistricts] = useState([{"state":"karnataka","district":["banalore","mysore","tumakur"]}])
    const [plantTypes, setPlantTypes] = useState(['type 1', 'type 2', 'type 3'])
    const { t } = useTranslation('plantform')
    const location = useLocation();
    const navigate = useNavigate();
    const [imageFile,setImageFile]=useState();
    const [plant, setFormPlant] = useState({
        plantId: "",
        installationDate: "",
        plantName: "",
        capacity: "",
        capacityUnit: "",
        azimuthAngle: "",
        azimuthUnit: "",
        titlAngle: "",
        titlGauge: "",
        plantType: "",
        plantCategory: "",
        plantImage: [],
        country: "",
        region: "",
        state: "",
        district: "",
        addressOne: "",
        addressTwo: "",
        pincode: "",
        latlng: "",
        ownerPlantId: "",
        ownerIDate: "",
        ownerPlantName: "",
        ownerEmailId: "",
        entityName: "",
        entityId: "",
        basicEmailId: "",
        basicCategory: "",
        datalogger: "",
        plantSerial: ""
    });

    useEffect(() => {
        if (location.state.plant !== null) {
            setFormPlant(location.state.plant)
        }
    }, [plant])

    const [validated, setValidated] = useState(false);
    const [addressUpdate,setAddressUpdate]=useState(false);
    const [ltUpdate, setltUpdate] = useState(false);
    useEffect(() => {
        if(addressUpdate){
            if(addressUpdate){
            setAddressUpdate(false);
            }
        }
    }, [address, latlng])


    const handleLatLngUpdate=()=>{
        if(latlng.lat === '' || latlng.lng ==='') return ;
        setltUpdate(true);
    }

  function handleChange(event) {
        const target = event.currentTarget;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'file' ? target.files : target.value;
        const name = target.name;
       setFormData({ ...plant, [name]: value });
    }

    function handleAddressChange(event) {
        const target = event.currentTarget;
        const value = parseFloat(target.value);
        const name = target.name;
        if(name === 'country' && address[name] !== value){
            setAddress({ country: value, region: '',state: '',district: '', addressOne: '', addressTwo: '', pincode: '' })
        }else if(name==='region' && address[name] !== value){
            setAddress({...address,state:'',district:'',addressOne:'',addressTwo:'',pincode:'',[name]:value})
        } else if(name==='state' && address[name] !== value){
            setAddress({...address,district:'',addressOne:'',addressTwo:'',pincode:'',[name]:value})
        }else if(name==='district' && address[name] !== value){
            setAddress({...address,addressOne:'',addressTwo:'',pincode:'',[name]:value})
        }else if(address[name] !== value){
        setAddress({ ...address, [name]: value });
        }
      
    }

    function handleCorordinateChange(event) {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;
        setLatLng({ ...latlng, [name]: value });
    }

   const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };

    const fileChanged = (e) => {
        setImageFile(URL.createObjectURL(e.target.files[0]));
    
    }

    const onVerify = () => {

    }
    const oncancel = () => {

    }  

    function handleBackArrow() {
        navigate("/dashboard/plants/plant/plantStatus", { replace: true, state: { "plant": plant } })
    }

   return (<div className="d-flex flex-column align-items-center justify-content-center">
        {/* <SolarNavbar /> */}
        <BottomHeaderBar />
        <Container className="mw-100 px-3">
            <Styles />
            <Row className="mt-2 mb-2 p-0">

                <Col className="d-flex flex-row align-items-center justify-content-start">
                          <ArrowCircleLeftRoundedIcon onClick={() => handleBackArrow()} sx={{ color: green[500], marginRight: "10px" }} />
                    <span className="fw-bold text-decoration-none text-black">Edit Plant : {plant.plantId}</span>


                </Col>

                <Col className="d-flex flex-row align-items-center justify-content-end">

                </Col>
            </Row>
        </Container>
        <Container className="mw-100 px-3 ">
            <Form className="w-100 p-0 m-0 plant-add-form" noValidate validated={validated} onSubmit={(e)=>handleSubmit(e)}>
                <Container className=" d-flex flex-column align-items-center justify-content-center mw-100 p-0">
                    <div className="w-100 text-left"><h2>{t('title')}</h2></div>
                    <Row className="w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">

                        <Col>
                            <Stack as="row" gap={2} direction="vertical" >
                                <Col >
                                    <Form.Group controlId="plantId">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">{t('plant-id')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} /> <span>:</span></Form.Label>
                                            </Col><Col md={9}>
                                                <Form.Label aria-colspan={2} defaultValue={plant.plantId} className="text-nowrap">Greentek-VPH-SUB-1003</Form.Label>
                                            </Col>
                                                </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="installationDate">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label aria-colspan={2} className="text-nowrap">{t('i-date')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} />  <span>:</span></Form.Label>
                                            </Col><Col md={8} >
                                                <DatePicker
                                                    name="installationDate"
                                                         onChange={(e) => { handleChange(e) }}
                                                           slotProps={{
                                                        field: <CalendarMonthOutlinedIcon style={{ fontSize: 27 }} />,
                                                        textField: { size: 'small' }
                                                    }}
                                                    defaultValue={plant.installationDate !==''?dayjs(plant.installationDate):dayjs()}
                                                    sx={{
                                                        width: '100%', backgroundColor: 'white'
                                                    }}
                                                />
                                                      </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="plantName">
                                        <Row className="w-100">
                                            <Col md={3}>
                                                <Form.Label className="text-nowrap">{t('plant-name')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} />  <span>:</span></Form.Label>
                                            </Col><Col md={8} >
                                                <Form.Control name="plantName" defaultValue={plant.plantName} required type="string" onChange={(e) => handleChange(e)} placeholder="Plant Name" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="capacity">
                                        <Row className="w-100">
                                            <Col md={3}><Form.Label className="text-nowrap">{t('capacity')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} /> <span>:</span></Form.Label>
                                            </Col><Col md={5} >

                                                <Form.Control name="capacity" defaultValue={plant.capacity} onChange={(e) => handleChange(e)} required type="number" placeholder="" />
                                            </Col><Col md={3}>
                                                <Form.Select defaultValue={plant.capacityUnit} onChange={(e) => handleChange(e)} name="capacityUnit" size="md">
                                                    <option value="kw">kw</option>
                                                    <option value="kwh">Kwh</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>

                            </Stack>
                        </Col>
                        <Col>
                            <Stack gap={3} direction="vertical">
                                <Form.Group controlId="azimuthAngle">
                                    <Row className="w-100">
                                        <Col md={3}>
                                            <Form.Label className="text-nowrap">{t('azimuth-angle')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} />  <span>:</span></Form.Label>
                                        </Col><Col md={5} >
                                            <Form.Control name="azimuthAngle" defaultValue={plant.azimuthAngle} onChange={(e) => handleChange(e)} required type="number" placeholder="" />
                                        </Col><Col md={3} >
                                            <Form.Select name="azimuthUnit" defaultValue={plant.azimuthUnit} onChange={(e) => handleChange(e)} size="md">
                                                <option value="deg">deg</option>
                                                <option value="angle">angle</option>
                                            </Form.Select>

                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="tiltAngle">
                                    <Row gap={2} className="w-100">
                                        <Col md={3}>
                                            <Form.Label aria-colspan={3} className="text-nowrap">{t('tilt-angle')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} /> <span>:</span></Form.Label>
                                        </Col><Col md={5}>
                                            <Form.Control name="tiltAngle" defaultValue={plant.titlAngle} onChange={(e) => handleChange(e)} required aria-colspan={3} type="number" placeholder="" />
                                        </Col><Col md={3}>
                                            <Form.Select name="tiltGuage" onChange={(e) => handleChange(e)} required aria-colspan={3} size="md">
                                                <option value="deg">deg</option>
                                                <option value="angle">angle</option>
                                            </Form.Select>
                                        </Col></Row>
                                </Form.Group>
                                <Form.Group controlId="plantType">
                                    <Row className="w-100">
                                        <Col md={3}> <Form.Label aria-colspan={3} className="text-nowrap">{t('plant-type')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} /> <span>:</span></Form.Label>
                                        </Col><Col md={8}>
                                            <Form.Select name="plantType" defaultValue={plant.plantType} onChange={(e) => handleChange(e)} required aria-colspan={3} placeholder="Plant Type" size="md">
                                                <option value={''}>Plant Type</option>
                                                <option value="Type1">Type 1</option>
                                            </Form.Select>
                                        </Col></Row>
                                </Form.Group>
                                <Form.Group controlId="plantCategory">
                                    <Row className="w-100"><Col md={3}>
                                        <Form.Label className="text-nowrap">{t('plant-category')} <StarOutlinedIcon style={{ color: 'red', fontSize: 'xx-small' }} /> <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                            <Form.Select name="plantCategory" defaultValue={plant.plantCategory} onChange={(e) => handleChange(e)} required placeholder=" Plant Category" size="md">
                                                <option value="">Plant Category</option>
                                                {plantTypes.map((v) => {
                                                    return <option value={v}>{v.toString()}</option>
                                                })}
                                            </Form.Select>
                                        </Col></Row>
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                      <Row className="w-100 row-card p-4 m-2">
                        <Form.Group controlId="plantImage">
                            <Stack as="div" gap={4} className="d-flex flex-md-row flex-column align-items-center justify-content-md-left mx-auto">
                                <Form.Label className="text-nowrap m-2">{t('plant-image')} <span>:</span></Form.Label>
                                {plant.plantImage !== undefined ? <Image src={plant.plantImage} width={"200px"} height={"100px"} /> : <Image src={imageFile} width={"200px"} height={"100px"} />}
                                <div className="d-flex flex-column justify-content-center m-2">
                                    <Stack gap={3} direction="horizontal"> <label class="btn btn-default form-button">
                                        Browse <input name="plantImage" accept="image/png, image/gif, image/jpeg" onChange={(v) => fileChanged(v) } type="file" hidden />
                                    </label> <label class="btn btn-default form-button">
                                            Upload <button onClick={(v) => uploadImage()} type="file" hidden />
                                        </label></Stack>
                                    <div><p className="browse-image-text">Only jpg,png,gif are allowed. Maximum Size 300kb</p></div>
                                </div>
                            </Stack>
                        </Form.Group>
                    </Row>
                    <div className="w-100 text-left"><h3>Location</h3></div>
                    <Row className="w-100 row-card p-4 m-2 d-flex flex-column flex-lg-row">

                        <Col>
                            <Stack gap={3} direction="vertical">
                                <Form.Group controlId="country">
                                    <Row className="w-100"> <Col md={3}>
                                        <Form.Label className="text-nowrap">Country <StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /> <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                    <Form.Select name="country" placeholder={address.country.toLocaleLowerCase()} value={address.country} onBlur={()=>setAddressUpdate(true)}  onChange={(e) => handleAddressChange(e)} required size="md">
                                                <option key='' >{address.country !== ''?address.country:"Select Country"}</option>
                                                {country.map((v) => {
                                                    return <option key={v.toLocaleLowerCase()} value={v.toLocaleLowerCase()}>{v}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="region">

                                    <Row className="w-100"> <Col md={3}>
                                        <Form.Label className="text-nowrap">Region <StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /> <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                    <Form.Select placeholder={address.region.toLocaleLowerCase()} value={address.region.toLocaleLowerCase()} disabled={address.country === ""} name="region" onBlur={()=>setAddressUpdate(true)}  onChange={(e) => handleAddressChange(e)} required size="md">
                                            <option key='' >{address.region !== ''?address.region:"Select Region"}</option>
                                                   {regions.find((r => r.country === address.country))?.regions.map((v) => {
                                                    return <option key={v.toLocaleLowerCase()} value={v.toLocaleLowerCase()}>{v}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="state">
                                    <Row className="w-100"> <Col md={3}>
                                        <Form.Label className="text-nowrap"> State<StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /> <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                    <Form.Select placeholder={address.state.toLocaleLowerCase()} value={address.state.toLocaleLowerCase()} disabled={address.region === ""} name="state" onBlur={()=>setAddressUpdate(true)} onChange={(e) => handleAddressChange(e)} required  size="md">
                                            <option key='' >{address.state !== ''?address.state:"Select State"}</option>
                                                   {states.find(s => s.region === address.region)?.states.map((v) => {
                                                    return <option key={v.toLocaleLowerCase()} value={v.toLocaleLowerCase()}>{v}</option>
                                                })}
                                            </Form.Select>
                                        </Col></Row>
                                </Form.Group>
                                <Form.Group controlId="district">
                                    <Row className="w-100"> <Col md={3}>
                                        <Form.Label className="text-nowrap">District <StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /> <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                    <Form.Select placeholder={address.district.toLocaleLowerCase()} value={address.district.toLocaleLowerCase()} disabled={address.state === ""} name="district" onBlur={()=>setAddressUpdate(true)} onChange={(e)=>handleAddressChange(e)}  required  size="md">
                                            <option key='' >{address.district !== ''?address.district:"Select District"}</option>
                                                            {districts.find(d => d.state === address.state)?.district.map((v) => {
                                                    return <option key={v.toLocaleLowerCase()} value={v.toLocaleLowerCase()}>{v}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="addressOne">
                                    <Row className="w-100"> <Col md={3}>
                                        <Form.Label className="text-nowrap">Address Line 1</Form.Label>
                                    </Col><Col md={8}>
                                    <Form.Control placeholder={address.addressOne} value={address.addressOne} name="addressOne" onBlur={()=>setAddressUpdate(true)} onChange={(e) => handleAddressChange(e)} as="textarea" rows={3} />

                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="addressTwo">
                                    <Row className="w-100"> <Col md={3}> <Form.Label className="text-nowrap">Address Line 2</Form.Label>
                                    </Col><Col md={8}>
                                    <Form.Control name="addressTwo" placeholder={address.addressTwo} value={address.addressTwo} onBlur={()=>setAddressUpdate(true)} onChange={(e) => handleAddressChange(e)} as="textarea" rows={3} />
                                    </Col></Row>
                                </Form.Group>
                                <Form.Group controlId="pincode">
                                    <Row className="w-100"> <Col md={3}>
                                        <Form.Label className="text-nowrap">Pincode <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                    <Form.Control name="pincode" placeholder={address.pincode} value={address.pincode} onBlur={()=>setAddressUpdate(true)} onChange={(e) => handleAddressChange(e)} type="string"  />
                                    </Col>
                                    </Row>
                                </Form.Group>
                                <Row className="w-100">
                                    <Col className="p-0 m-0">
                                        <Form.Group controlId="longitude">
                                            <Row className="w-100  align-items-center"><Col md={7} >
                                                <Form.Label className="text-nowrap">Longitude <StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /><spa> :</spa></Form.Label>
                                            </Col><Col className="w-100 p-0">
                                            <Form.Control cal name="lng" onBlur={()=>handleLatLngUpdate()} onChange={(e) => handleCorordinateChange(e)} value={latlng.lng} placeholder={plant.lng} type="number" />
                                            </Col></Row>
                                        </Form.Group>
                                    </Col>
                                    <Col className="p-0 m-0">
                                        <Form.Group controlId="latitude">
                                            <Row className="w-100 align-items-center"><Col md={4}>
                                                <Form.Label className="text-nowrap">Latitude <StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /><spa> :</spa></Form.Label>
                                            </Col><Col md={6} className="p-0">
                                            <Form.Control name="lat" onBlur={()=>handleLatLngUpdate()} onChange={(e) => handleCorordinateChange(e)} value={latlng.lat} placeholder={plant.lat} type="number" />
                                            </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Stack>
                        </Col>
                        <Col className="d-content">
                       <div >
                             <GMap ltUpdate={ltUpdate} setltUpdate={setltUpdate} addressUpdate={addressUpdate} setAddressUpdate={setAddressUpdate} setLatLng={setLatLng} latlng={latlng} address={address} setAddress={setAddress} />
                           </div>
                        </Col>
                    </Row>
                    <div className="w-100 text-left"><h3>Owner :</h3></div>
                    <Row className="w-100 d-flex flex-column flex-md-row row-card">
                        <Col className="pl-0 p-1">
                        <Stack className="row-card card-height p-4 background-none border-none shadow-none" gap={3} direction="vertical" >
                                <Form.Group className="w-100" controlId="ownerFirstName">
                                    <Row className="w-100"><Col md={3}>
                                        <Form.Label className="text-nowrap">First Name <spa> :</spa></Form.Label>
                                    </Col><Col md={8}>
                                            <Form.Control name="firstName"  onChange={(e) => handleChange(e)}  type="string" placeholder="First Name" />
                                        </Col></Row>
                                </Form.Group>
                                <Form.Group className="w-100" controlId="ownerLastName">
                                    <Row className="w-100"><Col md={3}>
                                        <Form.Label className="text-nowrap">Last Name <spa> :</spa></Form.Label>
                                    </Col><Col md={8}>
                                            <Form.Control name="lastName"   onChange={(e) => handleChange(e)} type="string" placeholder=" Last Name" />
                                        </Col></Row>
                                </Form.Group>
                                <div className="w-100">
                                    <Form.Group className="w-100 mb-3 d-flex flex-row" controlId="ownerEmailId">
                                        <Row className="w-100">
                                        <Col md={3}>
                                            <Form.Label className="text-nowrap">Email Id <StarOutlinedIcon style={{ color: 'red', fontSize: ' xx-small' }} /><spa> :</spa></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                                <Form.Control name="ownerEmailId"  onChange={(e) => handleChange(e)} type="mail" placeholder="username@email.com" />
                                                <div className="mt-3 m-1"><h6 className="email-text">Email is sent to user for verification.</h6></div>
                                        </Col> 
                                        <Col md={1}>
                                                <Button onClick={() => onVerify()} className="form-button rounded p-1 form-button-sp m-2" >Verify</Button>
                                            </Col>                                           
                                        </Row>
                                    </Form.Group>
                                    
                                </div>
                            </Stack>
                          </Col>
                        <Col></Col>
                    </Row>
                    <div className="text-left w-100"><h3>Entity :</h3></div>
                    <Row className="w-100 d-flex flex-column flex-md-row row-card">
                        <Col className="pr-0 p-1">
                           
                            <Stack className="row-card card-height p-4 background-none border-none shadow-none" gap={3} direction="vertical">
                                <Form.Group className="w-100" controlId="entityName">
                                    <Row className="w-100"><Col md={3}>
                                        <Form.Label className="text-nowrap">Entity Name <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                            <Form.Select name="entityName" defaultValue={plant.entityName} onChange={(e) => handleChange(e)} placeholder="Entity Name" size="md">
                                                <option value={''}>Entity name</option>
                                                <option value="Type1">Green tec india</option>
                                            </Form.Select>
                                        </Col></Row>
                                </Form.Group>
                                <Form.Group className="w-100" controlId="entityId">
                                    <Row className="w-100"><Col md={3}>
                                        <Form.Label className="text-nowrap">Entity Id <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                            <Form.Label className="text-nowrap" defaultValue={plant.entityId}>As0231w</Form.Label>
                                        </Col></Row>
                                </Form.Group>
                                <Form.Group className="w-100" controlId="basicCategory">
                                    <Row className="w-100"><Col md={3}>
                                        <Form.Label className="text-nowrap">Category <span>:</span></Form.Label>
                                    </Col><Col>
                                            <Form.Label className="text-nowrap" defaultValue={plant.basicCategory}>Private Limited</Form.Label>
                                        </Col></Row>
                                </Form.Group>
                                <Form.Group className="w-100" controlId="basicEmailId">
                                    <Row className="w-100"><Col md={3}>
                                        <Form.Label className="text-nowrap" defaultValue={plant.basicEmailId}>Email Id <span>:</span></Form.Label>
                                    </Col><Col md={8}>
                                            <Form.Label className="text-nowrap">Username@email.com</Form.Label>
                                        </Col></Row>

                                </Form.Group>
                            </Stack>
                        </Col>
                        <Col className=""></Col>
                    </Row>

                    <Row className="w-100">
                        <div className="w-100 d-flex flex-row align-items-center justify-content-end"> <Button onClick={() => oncancel()} className="mr-3 m-1 form-button">Cancel</Button> <Button type="submit" className="ml-3 m-1 form-button">Update Plant</Button></div>
                    </Row>
                </Container>
            </Form>
        </Container>
    </div>)
}

export default EditPlant;