import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import Modal from "react-modal"; // Import react-modal
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PermissionsProvider } from "./context/PermissionsContext";
import App from "./App";
import { TailSpin } from "react-loader-spinner"; // Import TailSpin loader

// Set the app element to your root element for react-modal accessibility
Modal.setAppElement("#root");

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <TailSpin
        height="80"
        width="80"
        color="hsl(0, 0.00%, 0.00%)"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible={true}
      />
    </div>
  );
}

root.render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PermissionsProvider>
          <App />
        </PermissionsProvider>
      </LocalizationProvider>
    </Suspense>
  </StrictMode>
);
